import { useState } from "react";
import LawInput from "../../ui/LawInput";
import { lawCountries } from "../../lawConstants";
import { lawFetchPost } from "../../functions/lawFetch";
import LawCheckbox from "../../ui/LawCheckbox";
import { wgfIsNotNull } from "../../functions/lawFunctions";
import LawCitiesSelect from "../../ui/LawCitiesSelect";
import LawSelect from "../../ui/LawSelect";
import LawCreateDataModal from "../../ui/LawCreateDataModal";
import { Grid2 } from "@mui/material";
import UserBaseElements from "../admin/users/UserBaseElements";
import LawInputPassword from "../../ui/LawInputPassword";
const CustomerCreate = ({ callback }) => {
    const [customer, setCustomer] = useState({});
    const create = () => {
        if (wgfIsNotNull([customer['name'], customer['surname'], customer['email'], customer['phone']], true) === true) {
            lawFetchPost('customer/create', customer, null, null, null, () => {
                setCustomer({});
                if (callback) {
                    callback();
                }
            }, null, true, null);
        }
    }
    return (
        <>
            <LawCreateDataModal createEvent={create} style={{maxWidth:'900px'}}>
                <Grid2 container={true} spacing={2}>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <UserBaseElements user={customer} setUser={setCustomer} />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <LawInput label={"Postcode"} propstate={[customer, setCustomer, 'postcode']} />
                        <LawInput label={"BuildingNumber"} propstate={[customer, setCustomer, 'buildingNumber']} />
                        <LawInput label={"BuildingSuffix"} propstate={[customer, setCustomer, 'buildingSuffix']} />
                        <LawInput label={"Addresss"} propstate={[customer, setCustomer, 'addresss']} />
                        <LawSelect items={lawCountries} label={"Country"} state={customer} setState={setCustomer} fieldName={'country'} />
                        <LawCitiesSelect state={customer} setState={setCustomer} fieldName={'cityId'} />
                        <LawCheckbox propstate={[customer, setCustomer, 'isBusiness']} label={"Business"} />
                        <LawInput label={"Daily ticket limit"} propstate={[customer, setCustomer, 'dailyTicketLimit']} />
                        <LawInputPassword state={customer} setState={setCustomer} fieldName={'newPassword'} isWithReEnter={true}/>
                    </Grid2>
                </Grid2>
            </LawCreateDataModal>
        </>
    )
}
export default CustomerCreate;