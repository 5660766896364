import { useEffect, useState } from "react"
import LawInput from "../../ui/LawInput"
import "./InvoiceTemplateStyle.css"
import BoxCenter from "../../ui/BoxCenter"
import { Button, Grid2, IconButton, MenuItem, Select, TextField } from "@mui/material"
import { Add, Close } from "@mui/icons-material"
import { wgfDateTimeFormat, wgfGetQueryStringValue, wgfObjectClone, wgfSumArrayItemsInObject, wgfValueToPriceText } from "../../functions/lawFunctions"
import { wgfDiscountTypes, wgInvoiceTypes, wgVatTypes } from "../../lawConstants"
import { NumericFormat } from "react-number-format"
import LawSelect from "../../ui/LawSelect"
import Seller from "./Seller"
import LawDatePicker from "../../ui/LawDatePicker"
import { lawFetchGet, lawFetchPost } from "../../functions/lawFetch"
/*
Discount: İskonto/İndirim (DiscountPrice= indirim uygulanacak ücret / DiscountRate=indirim uygulanacak oran %)
discountType = indirim türü Rate mi Amount mu?
*/

const _compValues = {
    invoiceItemInitial: {
        detailItemName: '',
        unitId: 1, //Birim ölçüsü (kg,litre,adet)
        quantity: 0, //adet
        discountType: 1, // 1  = % | 2 = €
        discountValue: 0, //iskonto değeri (rate or amount)
        discountTotal: 0,
        unitPrice: 0, // Birim fiyati
        vat: 0, //KDV oran 
        totalAmountWithoutVat: 0,
        vatAmount: 0,
        totalAmount: 0
    }
}
const CreateInvoice = ({ postInvoiceId, callback,companyId }) => {
    const [companyIdNo]=useState(companyId??wgfGetQueryStringValue('companyId'))
    const [invoiceGroup, setInvoiceGroup] = useState(null);
    const [unitTypes, setUnitTypes] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [customerCompanies, setCustomerCompanies] = useState([]);
    const [customerCompany, setCustomerCompany] = useState(null);
    const [sellerCompany, setSellerCompany] = useState(null);
    const [invoice, setInvoice] = useState({ invoiceDate: wgfDateTimeFormat(new Date(), 'yyyy-MM-dd') });
    const [invoiceDetails, setInvoiceDetails] = useState([]);

    useEffect(() => {
        lawFetchGet('invoice/unit-types-for-select', null, setUnitTypes, [], null, null, false, null);
    }, [])
    useEffect(() => {
        if (customer) {
            lawFetchGet('customerCompany/get-for-select?customerId=' + customer, null, setCustomerCompanies, [], null, null, false, null);
        }
    }, [customer])

    const handleDeleteItem = (index) => {
        let _list = wgfObjectClone(invoiceDetails);
        _list.splice(index, 1);
        setInvoiceDetails(_list);
    }
    const handleChange = (e, index, fieldName, isForPrice, isForRate, isNumber) => {
        let _invoice = wgfObjectClone(invoice);
        let _list = wgfObjectClone(invoiceDetails);
        let _value = e.target.value;
        if (isNumber) {
            _value = Number(_value ?? 0);
        }
        if (isForPrice) {
            _list[index][fieldName] = _value ? parseFloat(_value.replace(/[€$,]/g, "")) : 0; //NumericFormat nesnesinde €123,50 formatında değer alınıyordu
        }
        else if (isForRate) {
            _list[index][fieldName] = _value ? parseFloat(_value.replace(/[%]/g, "")) : 0; //NumericFormat nesnesinde %12 formatında değer alınıyordu
        }
        else {
            _list[index][fieldName] = _value;
        }
        if (fieldName === 'discountType') {
            _list[index]['discountValue'] = 0;
        }
        _list[index]['totalAmount'] = (_list[index].quantity * _list[index].unitPrice);
        _list[index]['vatAmount'] = (_list[index].vat > 0 ? ((_list[index].quantity * _list[index].unitPrice * _list[index].vat) / 100) : 0)
        let _total = _list[index]['totalAmount'] + _list[index]['vatAmount'];
        if (_list[index]['discountValue'] > 0) {
            if (_list[index]['discountType'] === 1) {
                _list[index]['discountTotal'] = (_total * _list[index]['discountValue']) / 100;
            }
            else {
                _list[index]['discountTotal'] = _list[index]['discountValue'];
            }
        }
        _list[index]['totalAmountWithoutVat'] = _total - _list[index]['discountTotal'];
        _invoice['totalPrice'] = wgfSumArrayItemsInObject(_list, 'totalAmount');
        _invoice['totalVat'] = wgfSumArrayItemsInObject(_list, 'vatAmount');
        _invoice['totalWithVat'] = wgfSumArrayItemsInObject(_list, 'totalAmountWithoutVat');
        _invoice['totalDiscount'] = wgfSumArrayItemsInObject(_list, 'discountTotal');
        setInvoice(_invoice);
        setInvoiceDetails(_list);
    }

    const handleCreate = () => {
        if (invoiceDetails && invoiceDetails.length > 0 && (companyIdNo || customerCompany)) {
            let _invoice = wgfObjectClone(invoice);
            _invoice['invoiceGroupId'] = invoiceGroup.value;
            _invoice['invoiceDetails'] = invoiceDetails;
            _invoice['customerCompanyId'] = companyIdNo ?? customerCompany;
            _invoice['customerPostInvoiceId'] = postInvoiceId;
            _invoice['sellerId'] = sellerCompany?.id;
            lawFetchPost('invoice/create-invoice', _invoice, null, null, null, () => {
                setCustomer(null);
                setCustomerCompany(null);
                setInvoiceGroup(null);
                setInvoiceDetails([]);
                setInvoice({ invoiceDate: wgfDateTimeFormat(new Date(), 'yyyy-MM-dd') });
                if (callback) {
                    callback();
                }
            }, null, true, null)
        }
    }
    const returnText = (obj, field) => {
        return (obj ? obj[field] ?? '---' : '---');
    }
    return (
        <>
            <div className="m-auto mb-5" style={{ maxWidth: '860px' }}>
                <Grid2 container={true} spacing={2}>
                    <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                        {
                            (!companyIdNo) && (
                                <LawSelect itemsUrl={'customer/all-for-select'} label={"Customer"} state={customer} setState={setCustomer} />
                            )
                        }
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                        {
                            (!companyIdNo) && (
                                <LawSelect items={customerCompanies} disabled={(!customer)} label={"Customer Company"} state={customerCompany} setState={setCustomerCompany} />
                            )
                        }
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 2 }}>
                        <LawSelect items={wgInvoiceTypes} label={"Invoice Type"} state={invoice} setState={setInvoice} fieldName={'invoiceType'} />
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 2 }}>
                        <LawSelect itemsUrl={'invoice/get-invoice-types'} label={"Invoice Types"} state={invoiceGroup} setState={setInvoiceGroup} isResponseFullData={true} />
                    </Grid2>
                </Grid2>
            </div>
            <div className="invoice-template-container">
                <div className="invoice-header">
                    <div className="invoice-logo">
                        <h2>FACTUUR</h2>
                    </div>
                    <div className="invoice-company-info">
                        <Seller selectedSetState={setSellerCompany} />
                        <h4>{returnText(sellerCompany, 'companyName')}</h4>
                        <span className="d-block">Email:{returnText(sellerCompany, 'email')}</span>
                        <span className="d-block">Phone:{returnText(sellerCompany, 'phone')}</span>
                        <span className="d-block">Fax:{returnText(sellerCompany, 'fax')}</span>
                        <span className="d-block">Address:{returnText(sellerCompany, 'address')}</span>
                        <span className="d-block">Company Id:{returnText(sellerCompany, 'id')}</span>
                    </div>
                </div>
                <div className="invoice-section">
                    <h3>Invoice Details</h3>
                    <div style={{ width: '200px' }}>
                        <LawInput propstate={[invoice, setInvoice, 'invoiceNumber']} label={"Invoice No:"} style={{ display: 'initial' }} />
                        <LawDatePicker state={invoice} setState={setInvoice} fieldName={'invoiceDate'} isSetAutoDate={true} />
                    </div>
                </div>
                <div className="invoice-section">
                    <h3>Billing To</h3>
                    <div style={{ width: '320px' }}>
                        <LawInput propstate={[invoice, setInvoice, 'clientName']} label={"Client Name:"} style={{ display: 'initial' }} />
                        <LawInput propstate={[invoice, setInvoice, 'clientAddress']} label={"Address:"} style={{ display: 'initial' }} />
                    </div>
                </div>
                <div className="invoice-section">
                    <table className="invoice-table">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th style={{ width: '180px' }}>Quantity</th>
                                <th style={{ width: '80px' }}>VAT</th>
                                <th style={{ width: '110px' }}>Unit Price</th>
                                <th style={{ width: '160px' }}>Discount</th>
                                <th style={{ width: '110px' }}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                invoiceDetails.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>
                                                <TextField value={item.detailItemName} onChange={(e) => handleChange(e, index, 'detailItemName')} size="small" variant="outlined" style={{ width: '100%' }} />
                                            </td>
                                            <td>
                                                <div className="d-flex">
                                                    <TextField style={{ width: '90px' }} value={item.quantity} min="0" type="number" onChange={(e) => handleChange(e, index, 'quantity', false, false, true)} size="small" variant="outlined" />
                                                    <Select
                                                        style={{ width: '90px' }}
                                                        size="small"
                                                        defaultValue="piece"
                                                        value={item.unitId}
                                                        onChange={(e) => handleChange(e, index, 'unitId')}
                                                    >
                                                        {
                                                            unitTypes.map((itemV, indexV) => {
                                                                return <MenuItem key={`unitm-${index}-${indexV}`} value={itemV.value}>{itemV.text}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </div>
                                            </td>
                                            <td>
                                                <Select
                                                    size="small"
                                                    defaultValue={21}
                                                    value={item.vat ?? invoiceGroup.vat}
                                                    style={{ width: '80px' }}
                                                    onChange={(e) => handleChange(e, index, 'vat')}
                                                >
                                                    {
                                                        wgVatTypes.map((itemV, indexV) => {
                                                            return <MenuItem key={`vat-${index}-${indexV}`} value={itemV.value}>{itemV.text}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </td>
                                            <td>
                                                <NumericFormat value={item.unitPrice} onChange={(e) => handleChange(e, index, 'unitPrice', true, false)} customInput={TextField} thousandSeparator valueIsNumericString prefix="€" size="small" />
                                            </td>
                                            <td>
                                                <div className="d-flex">
                                                    <Select
                                                        size="small"
                                                        defaultValue={0}
                                                        value={item.discountType}
                                                        style={{ width: '65px' }}
                                                        onChange={(e) => handleChange(e, index, 'discountType')}
                                                    >
                                                        {
                                                            wgfDiscountTypes.map((itemV, indexV) => {
                                                                return <MenuItem key={`is-rate-${index}-${indexV}`} value={itemV.value}>{itemV.text}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                    <div style={{width:'95px'}}>
                                                        {
                                                            (item.discountType === 1) ? (
                                                                <NumericFormat value={item.discountValue} onChange={(e) => handleChange(e, index, 'discountValue', false, true)} max={100} min={0} customInput={TextField} thousandSeparator valueIsNumericString prefix="%" size="small" />
                                                            ) : (
                                                                <NumericFormat value={item.discountValue} onChange={(e) => handleChange(e, index, 'discountValue', true, false)} min={0} max={999999} customInput={TextField} thousandSeparator valueIsNumericString prefix="€" size="small" />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{ position: 'relative' }}>
                                                <strong>€{item.totalAmount}</strong>
                                                <IconButton onClick={() => handleDeleteItem(index)} style={{ position: 'absolute', display: 'block', top: '4px', right: '0px' }} variant="contained" color="error" size="small">
                                                    <Close size="small" />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colSpan="6">
                                    <BoxCenter>
                                        <Button variant="outlined" onClick={() => setInvoiceDetails([...invoiceDetails, _compValues.invoiceItemInitial])} ><Add /></Button>
                                    </BoxCenter>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="5" className="invoice-totals"><strong>Subtotal</strong></td>
                                <td>
                                    <strong>{wgfValueToPriceText(invoice['totalPrice'])}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5" className="invoice-totals">
                                    <strong>VAT</strong>
                                </td>
                                <td>
                                    <strong>{wgfValueToPriceText(invoice['totalVat'])}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5" className="invoice-totals">
                                    <strong>Discount</strong>
                                </td>
                                <td>
                                    <strong>{wgfValueToPriceText(invoice['totalDiscount'])}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5" className="invoice-totals">
                                    <strong>Total</strong>
                                </td>
                                <td>
                                    <strong>{wgfValueToPriceText(invoice['totalWithVat'])}</strong>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className="jf-center mb-5 mt-3">
                    <Button onClick={handleCreate} variant="contained">Save</Button>
                </div>
                <div className="invoice-footer">

                </div>
            </div>
        </>
    )
}
export default CreateInvoice;