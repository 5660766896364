import { CalendarMonth } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import LawModal from "../../../ui/LawModal";
import LawCalendar from "./LawCalendar";

const LawNavbarCalendar = () => {
    const [show,setShow]=useState(false)
    return (
        <>
            <Tooltip title="Calendar">
                <IconButton
                    onClick={() => setShow(true)}
                    sx={{ color: '#0b0b55' }}
                    size="small"
                >
                    <CalendarMonth/>
                </IconButton>
            </Tooltip>
            {
                (show) &&(
                    <LawModal state={show} setState={setShow} style={{maxWidth:'1100px'}}>
                        <LawCalendar/>
                    </LawModal>
                )
            }
        </>
    )
}
export default LawNavbarCalendar;
