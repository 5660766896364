import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { wgfOnChangeEvent } from "../functions/lawFunctions";

const LawCheckbox = ({ propstate, label }) => {
    const handleChange = (e) => {
        wgfOnChangeEvent({propstate}, null, e.target.checked, null, null)
    }
    let isChecked=(propstate ?(propstate.length==3? propstate[0][propstate[2]]: propstate[0]):false);
    return (
        <>
            <FormGroup>
                <FormControlLabel control={<Checkbox onChange={handleChange} checked={isChecked} />} label={label ?? ''} />
            </FormGroup>
        </>
    )
}
export default LawCheckbox;