import Card from "../../components/ui/Card";
import TableTickets from "../../components/data/app/ticket/TableTickets";

const TicketsView = () => {
    return (
        <>
            <Card>
                <TableTickets />
            </Card>
        </>
    )
}
export default TicketsView;