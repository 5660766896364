import { Button, IconButton } from "@mui/material";
import { Save } from "@mui/icons-material";
import { wgfDateTimeFormat, wgfGetQueryStringValue } from "../../functions/lawFunctions";
import LawDataTable from "../../ui/LawDataTable";
import { useEffect, useState } from "react";
import LawModal from "../../ui/LawModal";
import CreateInvoice from "./CreateInvoice";
import LawButtonGroup from "../../ui/LawButtonGroup";
import { lawFetchGet } from "../../functions/lawFetch";
const CustomerOpenInvoicesTable = ({ companyId }) => {
    const [companyIdNo] = useState(companyId ?? wgfGetQueryStringValue('companyId'))
    const [createModalShow, setCreateModalShow] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const getAll = () => {
        lawFetchGet("customerPostInvoices/all?companyId=" + companyIdNo, null, setInvoices, [], null, null, false, null);
    }
    useEffect(getAll, []);
    const handleCreateOpenInvoiceCallback = () => {
        getAll();
        setCreateModalShow(false);
    }
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Priority',
            selector: (row) => row.priority,
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: (row) => row.customerName,
            sortable: true,
        },
        {
            name: 'Company Name',
            selector: (row) => row.companyName,
            sortable: true,
        },
        {
            name: 'Create',
            selector: (row) => wgfDateTimeFormat(row.createDate, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <IconButton onClick={() => { }}>
                        <Save />
                    </IconButton>
                </div>
            )
        }
    ];
    return (
        <>
            <LawButtonGroup>
                <Button onClick={() => setCreateModalShow(true)}>Nieuw</Button>
            </LawButtonGroup>
            <LawDataTable dataState={invoices} setDataState={setInvoices} filterController={'CustomerPostInvoices'} columns={columns} searchFields={['id', 'customerName', 'companyName']} />
            {
                (createModalShow) && (
                    <LawModal state={createModalShow} setState={setCreateModalShow}>
                        <CreateInvoice callback={handleCreateOpenInvoiceCallback} />
                    </LawModal>
                )
            }
        </>
    )
}
export default CustomerOpenInvoicesTable;