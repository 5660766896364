import Card from "../../ui/Card";
import TableTickets from "../app/ticket/TableTickets";
import ReportDaily from "./ReportDaily";

const CustomerViewForIndex = () => {
    return (
        <>
            <Card>
                <h3>Welcome</h3>
                <h6>Customer Name Surname</h6>
                <ReportDaily />
                <TableTickets />
            </Card>
        </>
    )
}
export default CustomerViewForIndex;