import { getYearsArray } from "../../../functions/lawFunctions";
import LawInput from "../../../ui/LawInput";
import LawInputNumber from "../../../ui/LawInputNumber";
import LawSelect from "../../../ui/LawSelect";

const CompanyVehicleFormBase = ({ vehicleState, setVehicleState }) => {
    return (
        <>
            <LawInput propstate={[vehicleState, setVehicleState, 'brandName']} label={'BrandName'} />
            <LawInput propstate={[vehicleState, setVehicleState, 'modelName']} label={'ModelName'} />
            <LawSelect state={vehicleState} setState={setVehicleState} fieldName={'modelYear'} items={getYearsArray(-50, 0)} label={'Model Year'} />
            <LawInput propstate={[vehicleState, setVehicleState, 'code']} label={'Code*'} />
            <LawInputNumber state={vehicleState} setState={setVehicleState} fieldName={'firstHour'} label={'First Hour'} />
            <LawInput propstate={[vehicleState, setVehicleState, 'firstKm']} label={'FirstKm'} type={'number'} />
            <LawInput propstate={[vehicleState, setVehicleState, 'priceHour']} label={'Price Hour'} type={'number'} />
            <LawInput propstate={[vehicleState, setVehicleState, 'priceKm']} label={'PriceKm'} type={'number'} />
        </>
    )
}
export default CompanyVehicleFormBase;