import { useEffect, useState } from "react";
import CompanyCreate from "./CompanyCreate";
import { lawFetchGet } from "../../../functions/lawFetch";
import { IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { appObjects } from "../../../../App";
import LawStatus from "../../../ui/LawStatus";
import LawDataTable from "../../../ui/LawDataTable";
import { wdfDynamicImportUrlParameter } from "../../../functions/lawFunctions";

const CompaniesTable = ({ customerId }) => {
    const [companies, setCompanies] = useState([]);
    const getAll = () => {
        let url = wdfDynamicImportUrlParameter(
            'CustomerCompany/all',
            [
                { default: customerId, key: 'customerId' }
            ]);
        lawFetchGet(url, null, setCompanies, [], null, null, false, null);
    }
    useEffect(() => {
        getAll();
    }, []);
    let columns = [
        {
            name: 'Company Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: (row) => row.phone,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: 'Tax Number',
            selector: (row) => row.taxNumber,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'ActivePassiveDeleted'} />
            )
        },
        {
            name: 'Action',
            width: '120px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <IconButton onClick={() => appObjects.goToWithViewIdentity('CompanyView', '?companyId=' + row.id)}>
                        <ChevronRight />
                    </IconButton>
                </div>
            ),
        }
    ];
    return (
        <>
            <h5>Companies</h5>
            <CompanyCreate customerId={customerId} callback={getAll} />
            <LawDataTable dataState={companies} setDataState={setCompanies} columns={columns} searchFields={['id', 'name', 'phone', 'email', 'taxNumber']} />
        </>
    )
}
export default CompaniesTable;