import { appObjects } from "../../App";
import { lawRoles } from "../lawConstants";
import { lawFetchGet } from "./lawFetch";

export const wgfSetLocalStorage = (key, value) => {
    return localStorage.setItem(key, value);
}
export const wgfGetLocalStorage = (key, callback) => {
    let _ldt = localStorage.getItem(key) ?? "";
    if (callback) {
        callback(_ldt);
    }
    return _ldt;
}
/**
 * 
 * @param {*} newUrl 
 * @param {*} urlParameters  [ {default:null,key:''}]
 * @returns 
 */
export const wdfDynamicImportUrlParameter = (newUrl, urlParameters) => {
    if (urlParameters) {
        let newParams = [];
        urlParameters = urlParameters.filter(param => {
            if (param.default && param.key) {
                newParams.push(`${param.key}=${param.default}`);
                return false;
            }
            else {
                return true;
            }
        })
        let queryString = window.location.href.split('?')[1];
        if (queryString) {
            queryString.split('&').forEach((param) => {
                const [key, value] = param.split('=');
                if (urlParameters.find(f => f.key === decodeURIComponent(key))) {
                    newParams.push(`${key}=${value}`);
                }
            })
        }
        if (newParams.length > 0) {
            return newUrl + '?' + newParams.join('&');
        }
    }
    return newUrl;
}
export const wgfGetCityName = (id) => {
    if (appObjects && appObjects.values && appObjects.values.appDatas) {
        let resp = appObjects.values.appDatas.cities.find(f => f.value === id);
        return resp ? resp.text : '';
    }
    return '';

}
export const wgfGetQueryStringValue = (paramKey) => {
    let resp = null;
    if (window.location.search) {
        let queryString = window.location.href.split('?')[1];

        queryString.split('&').forEach((param) => {
            const [key, value] = param.split('=');
            if (paramKey === decodeURIComponent(key)) {
                resp = value;
            }
        })
    }
    return resp;
}

export const wgfValueToPriceText = (value) => {
    return '€' + (value ? value.toFixed(2) : 0);
}
export const getDaysInMonth = (year, month) => {
    // Ayları sıfırdan başlayan indekslerle belirtiyoruz: 0 = Ocak, 1 = Şubat, ..., 11 = Aralık
    return new Date(year, month + 1, 0).getDate();
}
export const getHourArray = () => {
    return Array.from({ length: 24 }, (_, i) => { return ({ value: i, text: (i).toString().padStart(2, '0') }) });
}
export const getDayArray = (year, month) => {
    return Array.from({ length: (getDaysInMonth(year, month)) + 1 }, (_, i) => { return ({ value: i, text: (i).toString().padStart(2, '0') }) });
}
export const getMinutesArray = () => {
    return Array.from({ length: 60 }, (_, i) => { return ({ value: i, text: (i).toString().padStart(2, '0') }) });
}
export const getMonthsArray = () => {
    return Array.from({ length: 12 }, (_, i) => { return ({ value: (i + 1), text: (i + 1).toString() }) });
}
export const getYearsArray = (prevCount = -1, nextCount = 1) => {
    if (prevCount > 0) {
        prevCount = 0;
    }
    if (nextCount < 0) {
        nextCount = 0;
    }
    let year = (new Date).getFullYear() + prevCount;
    return Array.from({ length: (nextCount + 1) - prevCount }, (_, i) => { return ({ value: (year + i), text: (year + i).toString() }) })
}


export const wgfVersionControl = () => {
    if (appObjects.values.version) {
        wgfGetLocalStorage('app.version', (resp) => {
            if (resp) {
                if (resp !== appObjects.values.version) {
                    wgfSetLocalStorage('app.version', appObjects.values.version);
                    window.location.href = window.location.href;
                }
                else {
                    wgfSetLocalStorage('app.version', appObjects.values.version);
                }
            }
            else {
                wgfSetLocalStorage('app.version', appObjects.values.version);
            }
        })
    }
}
export const wgfRemoveLocalStorage = (key) => {
    localStorage.removeItem(key);
}
export const myfGetLoginToken = (callback) => {
    let _token = wgfGetLocalStorage('auth-token');
    if (callback) {
        callback(_token);
    }
    return _token;
}

/*State Change */
export const wgfRemoveStateItemById = (state_, setState_, id_) => {
    //Buraya Confirm ekle
    setState_(prev => [...prev.filter(item => item.id !== id_)]);
}
export const wgfGuid = (isLong = false) => {
    if (isLong === true) {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    else {
        return ([1e7] + -1e3).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
}
export const wgfCreateRandomID = () => {
    return `lw-${Math.floor(Math.random() * (1000000 - 1 + 1)) + 1}`; //+ (new Date()).getMilliseconds();
}
/**
  * Select nesnei değiştiğinde state'e uygular.
 * Eğer state'e id verisi ile beraber text verisi de isteniyorsa seçilen optionun text bilgisi de uygulanır.
 * @param {*} e : ilgili nesne
 * @param {*} state_ :
 * @param {*} setState_ :
 * @param {*} propval_ : value değerinin yazılacağı property 
 * @param {*} proptext_ : text değerinin yazılacağı property
 */
export const wgfChangeSelect = (e, state_, setState_, prop_, proptext_ = null, valueIsNumber_ = false) => {
    let _value = e.target.value;
    if (proptext_) {
        _value = e.target.options[e.target.selectedIndex].text;
    }
    else if (valueIsNumber_ === true) {
        _value = Number(_value);
    }
    wgfChangeState(_value, prop_, state_, setState_);
}
export const openNewTab = (url) => {
    window.open(url, '_blank').focus();
}


/**
 * 
 * @param {*} e 
 * @param {*} prop_ : değişen verinin property adı,string tipte
 * @param {*} state_ : verinin tutulduğu state
 * @param {*} setState_ : 
 * @param {*} valueIsNumber_ : value type'ı sayi ise Number'a çevir
 */
export const wgfChangeInput = (e, prop_, state_, setState_, valueIsNumber_ = false) => {
    let _value = e.target.value;
    if (valueIsNumber_ === true) {
        _value = Number(_value);
    }
    wgfChangeState(_value, prop_, state_, setState_);
}
/**
 * 
 * @param {*} value_ : yeni değer
 * @param {*} prop_  : değeri değişecek proprty adı
 * @param {*} state_ : değeri tutan state
 * @param {*} setState_ : state'e değer atayan fonksiyon
 */
export const wgfChangeState = (value_, prop_, state_, setState_) => {
    try {
        if (prop_) {
            setState_({ ...state_, [prop_]: value_ });
        }
        else {
            setState_(value_);
        }
    }
    catch (e) {
        console.error('wgfChangeState', e);
    }
}

/**
 * Aynı Nesnede field'leri peşpeşe güncellerken sadece en sonununcusunun değişikliğini uygulamaktaydı. 
 * Çozum olarak tum fieldleri ve değerlerini ayrı ayrı Array ile gonder ve map() metodu ile nesneye yazıp state'i gunceller.
 * NOT: fieldadı ve değeri farklı array içerinde aynı Index'te yer almalıdır.
 * @param {*} valueArray_ 
 * @param {*} propArray_ 
 * @param {*} state_ 
 * @param {*} setState_ 
 */
export const wgfChangeStateMultipleField = (valueArray_, propArray_, state_, setState_) => {
    try {

        propArray_.map((item, index) => {
            state_[item.toString()] = valueArray_[index];
        });
        setState_(state_);
    }
    catch (e) {
        console.error('wgfChangeState', e);
    }
}
/**
 * Array icerisinde filtreleme yapıp ilgili alanın degerini degistirir.
 * @param {*} filterField 
 * @param {*} filterValue 
 * @param {*} changeField 
 * @param {*} changeValue 
 * @param {*} state 
 * @param {*} setState 
 */
export const wgfChangeStateArrayItems = (filterField, filterValue, changeField, changeValue, state, setState) => {
    setState(state.map(f => {
        if (f[filterField] === filterValue) {
            f[changeField] = changeValue;
        }
        return f;
    }))
}
export const wgfRandomNumber = (min = 111, max = 9999999) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const wgfSumArrayItems = (arry) => {
    if (arry) {
        return arry.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }
    return 0;
}
export const wgfSumArrayItemsInObject = (arry, fieldName) => {
    if (arry) {
        return arry.reduce((accumulator, currentItem) => accumulator + currentItem[fieldName], 0);
    }
    return 0;
}


/**
 * [A-Za-z\s] = bu regex Türkçe karakterleri destekelemez.Sadece ingilizce karakter içermesi gerekenlerde kullan.
 * [a-zA-ZçÇğĞıİöÖşŞüÜ\\s]   = Türkçe karakterleri de destekelemesi için bunu kullan.
 */
export const wgfValidation = {
    phone: (number) => (/^5\d{9}$/).test(number),
    email: (email) => (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(email),
    letter: (text) => (/^[a-zA-ZçÇğĞıİöÖşŞüÜ\\s ]+$/).test(text),//sadece harf ve boşluk
    password: () => { }
}

export const wgfDateToText = (date) => {
    const _dt = new Date(date);
    if (_dt instanceof Date) {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        if (isSameDay(_dt, today)) {
            return 'bugün';
        } else if (isSameDay(_dt, yesterday)) {
            return 'dün';
        } else if (isSameDayOrEarlier(_dt, yesterday)) {
            const diffInDays = Math.floor((yesterday - _dt) / (1000 * 60 * 60 * 24));
            return `${diffInDays} gün önce`;
        } else if (isSameWeek(_dt, today)) {
            const diffInWeeks = Math.floor((today - _dt) / (1000 * 60 * 60 * 24 * 7));
            return `${diffInWeeks} haf. önce`;
        } else if (isSameMonth(_dt, today)) {
            const diffInMonths = Math.floor((today - _dt) / (1000 * 60 * 60 * 24 * 30));
            return `${diffInMonths} ay önce`;
        } else {
            return _dt.toDateString(); // Özel bir durum yoksa tarihi normal formatta döndür
        }
    }

}
function isSameDay(date1, date2) {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

function isSameDayOrEarlier(date1, date2) {
    return date1 <= date2;
}

function isSameWeek(date1, date2) {
    const diffInDays = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
    return diffInDays <= 7;
}

function isSameMonth(date1, date2) {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth()
    );
}
export const wgfCreateDate = (year, month, day) => {
    return new Date(year, month, day);
}
/**
 * Object listesinden ilgili nesneyi bulup gunceller, yoksa ekler
 * @param {*} object_ : guncel veri
 * @param {*} setSate_ : guncellenecek veriler listesi
 */
export const wgfChangeOrAppendObjectsItemById = (object_, setSate_) => {
    /*filtre ileguncellenecek olanı ele ve yeni ekle*/
    setSate_(prev => [...prev.filter(item => item.id !== object_.id), object_]);
}
/**
 * JS'te nesne kopyalandığında kaynak nesnedeki değişiklik yeni kopyayada etki etmekte.
 * Bu bağlantıyı kırmak için Json.stringfy kullanılır.
 * @param {*} obj 
 */
export const wgfObjectClone = (obj) => {
    if (obj) {
        return JSON.parse(JSON.stringify(obj));
    }
    return null;
}
/**
 * Nesnenin type'ı tarih mi
 * @param {*} date 
 * @returns 
 */
export const wgfDateIsValid = (date) => {
    return date instanceof Date && !isNaN(date);
}

export const wgfDateTimeFormat = (date_, format_) => {
    if (date_) {
        if (wgfDateIsValid(date_) === false) {
            date_ = new Date(date_);
        }
        if (wgfDateIsValid(date_)) {
            let _langs = {
                en: {
                    months: [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December"
                    ],
                    months_short: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec"
                    ],
                    days: [
                        "Sunday",
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday"
                    ],
                    days_short: [
                        'Sun',
                        'Mon',
                        'Tue',
                        'Wed',
                        'Thu',
                        'Fri',
                        'Sat'
                    ]
                },
                nl: {
                    months: [
                        "Januari",
                        "Februari",
                        "Maart",
                        "April",
                        "Mei",
                        "Juni",
                        "Juli",
                        "Augustus",
                        "September",
                        "Oktober",
                        "November",
                        "December"
                    ],
                    months_short: [
                        "Jan",
                        "Feb",
                        "Mrt",
                        "Apr",
                        "Mei",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Okt",
                        "Nov",
                        "Dec"
                    ],
                    days: [
                        "Zondag",
                        "Maandag",
                        "Dinsdag",
                        "Woensdag",
                        "Donderdag",
                        "Vrijdag",
                        "Zaterdag"
                    ],
                    days_short: [
                        "Zo",
                        "Ma",
                        "Di",
                        "Wo",
                        "Do",
                        "Vr",
                        "Za"
                    ]
                }
            }
            /*
            getMounth() ile donen tarih verisi 0-11 aralığında olur. Yani 1'den değil 0'dan başlamaktadır.
            */
            let _dtObj = {
                year: date_.getFullYear(),
                mounth: date_.getMonth() + 1,
                date: date_.getDate(),
                hour: date_.getHours(),
                minute: date_.getMinutes(),
                second: date_.getSeconds(),
                day_number: date_.getDay(),
                milisecond: date_.getMilliseconds()
            }
            let _clientLang = window.navigator.language.toString().toLocaleLowerCase().split('-')[0];
            if (!_langs[_clientLang]) {
                _clientLang = 'nl';
            }
            let format_Obj = {
                convertPM_AM: is12H_ => {
                    let _newDt = date_.toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: is12H_
                    }).toString().split(':')[0];
                    return _newDt;
                },
                isPM: () => {
                    let _newDt = date_.toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false
                    }).toString().split(':')[0];
                    return Number(_newDt) > 12;
                },
                y: () => Number(_dtObj.year.toString().substr(2, 2)),
                yy: () => _dtObj.year.toString().substr(1, 3),
                yyy: () => _dtObj.year,
                yyyy: () => _dtObj.year,
                M: () => _dtObj.mounth,
                MM: () => (_dtObj.mounth < 10 ? '0' + _dtObj.mounth : _dtObj.mounth),
                MMM: () => _langs[_clientLang].months_short[_dtObj.mounth - 1],
                MMMM: () => _langs[_clientLang].months[_dtObj.mounth - 1],
                d: () => _dtObj.date,
                dd: () => (_dtObj.date < 10 ? '0' + _dtObj.date : _dtObj.date),
                ddd: () => _langs[_clientLang].days_short[_dtObj.day_number],
                dddd: () => _langs[_clientLang].days[_dtObj.day_number],
                h: () => Number(format_Obj.convertPM_AM(true)),
                hh: () => {
                    let _newHr = Number(format_Obj.convertPM_AM(true).toString().split(':')[0]);
                    return (_newHr < 10 ? '0' + _newHr : _newHr);
                },
                H: () => Number(format_Obj.convertPM_AM(false).toString().split(':')[0]),
                HH: () => {
                    let _newHr = Number(format_Obj.convertPM_AM(false).toString().split(':')[0]);
                    return (_newHr < 10 ? '0' + _newHr : _newHr)
                },
                m: () => _dtObj.minute,
                mm: () => (_dtObj.minute < 10 ? '0' + _dtObj.minute : _dtObj.minute),
                s: () => _dtObj.second,
                ss: () => (_dtObj.second < 10 ? '0' + _dtObj.second : _dtObj.second),
                f: () => Math.round(_dtObj.milisecond / 100),
                ff: () => Math.round(_dtObj.milisecond / 10),
                fff: () => _dtObj.milisecond,
                t: () => format_Obj.isPM ? 'P' : 'A',
                tt: () => format_Obj.isPM ? 'PM' : 'AM'

            }
            //tarih formatında kullanılabilecek seperatorlerden ayir
            format_.split(/[-_./ :]+/).map(item => {
                let _funct = format_Obj[item];
                if (_funct !== undefined) {
                    format_ = format_.replace(item, _funct());
                }
            })
            return format_;
        }
    }
    return null;
}

export const wgfTimeOnlyToHour = (time) => {
    if (time) {
        var [hour, minute, second] = time.split(':');
        return `${hour}:${minute}`;
    }
    return '';
}

/**
 * 
 * @param {*} e : change olayanın gerçekleştiği element
 * @param {*} props 
 * @param {*} setValue : eğer hazır veri varsa onu yaz, e parametresini okumaz.
 * @param {*} isTypeNumber : dönüş değeri numara ise convert eder 
 */
export const wgfOnChangeEvent = (props, e = null, setValue = null, isTypeNumber = false) => {
    if (props) {
        let _val = setValue;
        if (e) {
            _val = e.target.value;
        }
        if (isTypeNumber === true) {
            _val = Number(_val);
        }
        if (props.propstate) {
            let _propName = props.propstate[2];
            if (_propName) {
                let _state = props.propstate[0];
                let _setState = props.propstate[1];
                wgfChangeState(_val, _propName, _state, _setState);
            }
            else {
                //propState ile de object olmayan yapı gönderilebilecek (state / setSatete ikilisi de gönderilebilecek)
                let _setState = props.propstate[1];
                _setState(_val);
            }
        }
        else if (props.setState) {
            props.setState(_val)
        }
    }
}
/**
 * Object icersisindeki field'leri URL icin parametreye cevirir
 * @param {*} obj 
 * @param {*} url 
 * @param {*} defaults 
 * @returns 
 */
export const wgfObjectFieldsAddUrlParams = (obj, url, defaults, isWithHoursForDate) => {
    let _params = defaults ?? [];
    for (const [key, value] of Object.entries(obj)) {
        if (value) {
            if (value instanceof Date) {
                _params.push(`${key}=${wgfDateTimeFormat(value, (isWithHoursForDate ? 'yyyy-MM-dd HH:dd' : 'yyyy-MM-dd'))}`);
            }
            else {
                _params.push(`${key}=${value}`);
            }

        }
    }
    if (_params.length > 0) {
        url += '?' + _params.join('&');
    }
    return url;
}
const loadingObjects = {
    loadingArea: null,
    isShow: false
}
export const wgfRoleControl = (roleNames) => {
    let _isNext = false;
    if (roleNames && appObjects.values.loginInfo) {
        for (let i = 0; i < roleNames.length; i++) {
            if (appObjects.values.loginInfo.roleName === roleNames[i]) {
                _isNext = true;
                break;
            }
        }
    }
    return _isNext;//(roleName ? (appObjects.values.loginInfo ? (appObjects.values.loginInfo.roleName === roleName) : false) : false);
}
export const wgfAdminRoleControl = () => {
    return (appObjects.values.loginInfo && (appObjects.values.loginInfo.roleName === 'Admin' || appObjects.values.loginInfo.roleName === 'SuperAdmin'));
}
export const wgfExport = (dataTableName, dataIdList) => {
    if (dataTableName && dataIdList) {

    }
}
export const wgfLoading = (isShow) => {
    loadingObjects.isShow = isShow;
    /*
     2 saniye beklesin, hala isShow=true ise o zaman loading icon'u gosterilsin. Yoksa her istekte gosteriyordu
    */
    /*
    setTimeout(() => {
        if (loadingObjects.isShow) {
            loadingObjects.loadingArea = document.getElementById('ldng-anm-container');
            if (isShow === true && !loadingObjects.loadingArea) {
                loadingObjects.loadingArea = document.createElement('div');
                loadingObjects.loadingArea.id = 'ldng-anm-container';
                let _load = document.createElement('div');
                _load.classList.add('ldng-anm-load');
                loadingObjects.loadingArea.appendChild(_load);
                for (let i = 0; i < 4; i++) {
                    _load.appendChild(document.createElement('div'));
                }
                document.body.appendChild(loadingObjects.loadingArea);
            }
            else if (loadingObjects.loadingArea) {
                loadingObjects.loadingArea.remove();
            }
            setTimeout(() => {
                if (loadingObjects.loadingArea) {
                    loadingObjects.loadingArea.remove();
                }
            }, 1000 * 5)
        }
    }, 2000)
    */
}
/**
 * 
 * @param {*} e 
 * @param {*} propstate = [state,setState,statePropsName(optional)]
 * @param {*} callback = return(e). (optional)
 */
export const wgfPropsStateChange = (e, props) => {
    if (e && props && props.propstate) {
        let _val = e.target.value;
        if (props.type && props.type === 'number') {
            if (_val) {
                _val = Number(_val);
            }
        }
        if (props.propstate.length > 2) {
            wgfChangeState(_val, props.propstate[2], props.propstate[0], props.propstate[1]);
        }
        else {
            props.propstate[1](_val);
        }
    }
}
/**
 * propstate verisini yazdırır
 * @param {*} props 
 * @returns 
 */
export const wgfGetPropStateValue = (props, defaultValue) => {
    if (props) {
        if (props.value) {
            return props.value; //eğer propstate haricinde value değeri varsa onu yazdır
        }
        else if (props.propstate) {
            if (props.propstate.length > 2) {
                return props.propstate[0][props.propstate[2]];
            }
            else {
                return props.propstate[0];
            }
        }
    }

    return defaultValue ?? null;
}
/**
 * 
 * @param {*} value : Bu değer null/undefined ise default değeri atar
 * @param {*} defaultValue 
 */
export const wgfSetDefaultValue = (value, defaultValue) => {
    if (value === undefined || value === null) {
        return defaultValue;
    }
    return value;
}
export const wgfPageRefresh = () => {
    window.location.reload();
}
export const wgfLogout = () => {
    localStorage.removeItem('auth-token');
    wgfRemoveLocalStorage("auth-token");
    wgfRemoveLocalStorage("login-info");
    // appObjects.goTo('/login');
    if (window.location.pathname !== '/') {
        window.location.href = "/";
    }
}

export const wgfLoginControl = (callback) => {
    if (appObjects.values.loginInfo) {

        let _isLogout = true;
        let _dt = new Date()
        if (appObjects.values.loginInfo.expiration && new Date(appObjects.values.loginInfo.expiration) > _dt) {
            _isLogout = false;
        }
        else {
            lawFetchGet('login/login-control', null, null, null, null, null, false, (data) => {
                if (data && data.success === false) {
                    appObjects.snackbar(data.message);
                    _isLogout = false;
                }
                else {
                    if (window.location.href.includes("/login") || window.location.href === appObjects.values.domain || window.location.href === appObjects.values.domain + '/') {
                        appObjects.goTo('/index'); // window.location.href = "/index";
                    }
                }
            })
        }
        if (_isLogout === true) {
            wgfLogout();
        }
        else if (window.location.href.includes("/login") || window.location.pathname === '/') {
            appObjects.goTo('/index');// window.location.href = "/index"
        }
    }
    else {
        wgfGetLocalStorage("login-info", (data) => {
            if (data) {
                appObjects.values.loginInfo = JSON.parse(data);
                if (appObjects.values.loginInfo) {
                    wgfSetRoleGroups();
                    wgfLoginControl();
                }
                else {
                    wgfLogout();
                }
            }
            else {
                wgfLogout();
            }
        })
    }
}

export const wgfSetRoleGroups = () => {
    appObjects.values.roleGroup.lawyer = wgfRoleControl([lawRoles.lawyer, lawRoles.admin, lawRoles.superAdmin]);
    appObjects.values.roleGroup.lawyerAssistant = wgfRoleControl([lawRoles.lawyer, lawRoles.lawyerAssistant, lawRoles.admin, lawRoles.superAdmin]);

    appObjects.values.roleGroup.accountant = wgfRoleControl([lawRoles.accountant, lawRoles.admin, lawRoles.superAdmin]);
    appObjects.values.roleGroup.accountantAssistant = wgfRoleControl([lawRoles.accountant, lawRoles.accountantAssistant, lawRoles.admin, lawRoles.superAdmin]);

    appObjects.values.roleGroup.accountantLawyer = appObjects.values.roleGroup.accountant || appObjects.values.roleGroup.lawyer;

}

/**
 * Text metnini keser ve sonuna üç nokta ekler
 * @param {*} text 
 */
export const wgfTextCropAndDots = (text, maxLength) => {
    if (text && text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
}
export const wfgScrollToBottom = (elementRef) => {
    if (elementRef && elementRef.current) {
        elementRef.current.scrollTo({
            top: elementRef.current.scrollHeight - elementRef.current.clientHeight,
            behavior: "smooth"
        });
    }
}
/**
 * 
 * @param {*} params : Array olarak parametreleri gönderebilirsin
 * @returns 
 */
export const wgfIsNotNull = (params, isShowToastMessage = false) => {
    let _resp = true;
    params.map(item => {
        if (!item ||
            item === null ||
            item === undefined ||
            item === "" ||
            item.length === 0) {
            _resp = false;
        }
    })
    if (isShowToastMessage === true & _resp === false) {
        appObjects.snackbar('Zorunlu alanları doldurunuz !!!', 'info');
    }
    return _resp;
}
export const wgfIsNotNullWithCustomMessage = (params, message, toastVariant = 'info') => {
    let _resp = wgfIsNotNull(params);
    if (_resp === false) {
        appObjects.snackbar(message, toastVariant);
    }
    return _resp;
}
export const wgfImgToBase64 = (imgIdSelector) => {
    let img = document.getElementById(imgIdSelector);
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL;
    //aşağıdaki ile ham halini döndürür, dosya tipi falan yazmayan.
    //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}
/**
 * Int tipindeki zaman değerini saat/dakika olacak şekilde hesaplar ve döndürür
 * @param {*} tt_ 
 * @returns 
 */
export const wgfTimeToText = (tt_) => {
    let hours = Math.floor(tt_ / 3600);
    let minutes = 0;
    if (hours > 0) {
        tt_ %= 3600;
        minutes = Math.floor(tt_ / 60);
        return `${hours} saat ${minutes} dakika`;
    }
    minutes = Math.floor(tt_ / 60);
    return `${minutes} dakika`;
}
/**
 * Kullanıcının konum bilgisini alır
 * @param {*} setState 
 * @param {*} callback 
 */
export const wgfGetCurrentPosition = (setState, callback) => {
    navigator.geolocation.getCurrentPosition((position) => {
        let _pos = { lat: position.coords.lat, lng: position.coords.lng };
        if (callback) {
            callback(_pos);
        }
        if (setState) {
            setState(_pos);
        }
    });
}