import CreateInvoice from "../../components/data/customer/CreateInvoice";
import Card from "../../components/ui/Card";

const CustomerCreateInvoiceView = () => {
    return (
        <Card>
            <div className="jf-center mt-5 mb-5">
                <div style={{width:'96%', maxWidth:'500px'}}>
                    <CreateInvoice />
                </div>
            </div>
        </Card>
    )
}
export default CustomerCreateInvoiceView;