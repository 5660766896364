import { UploadFile } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { useState } from "react"
import LawModal from "../../ui/LawModal";
import CreateInvoice from "./CreateInvoice";

const NavbarCreateInvoice = () => {
    const [show, setShow] = useState(false);
    const handleCreateCallback=()=>{
        setShow(false);
    }
    return (
        <>
            <div style={{ position: 'fixed', bottom: '30px', right: '30px', zIndex: 9999 }}>
                <Fab onClick={() => setShow(true)} color="primary" aria-label="add">
                    <UploadFile />
                </Fab>
            </div>
            {
                (show) && (
                    <LawModal state={show} setState={setShow}>
                        <CreateInvoice callback={handleCreateCallback} />
                    </LawModal>
                )
            }
        </>
    )
}
export default NavbarCreateInvoice;