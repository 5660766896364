import { Box, ButtonGroup } from "@mui/material";

const LawButtonGroup = (props) => {
    return (
        <Box sx={{marginBottom:'20px'}}>
            <ButtonGroup variant="text">
                {props.children}
            </ButtonGroup>
        </Box>
    )

}
export default LawButtonGroup;