import Card from "../../components/ui/Card";
import { wgfGetQueryStringValue } from "../../components/functions/lawFunctions";
import { useState } from "react";
import CompanyPage from "../../components/data/accountant_customer/company/CompanyPage";
const CompanyView = () => {
    const [companyId] = useState(wgfGetQueryStringValue('companyId'))
    return (
        <>
            <Card style={{padding:0, overflow:'hidden'}}>
                {
                    (companyId)&&(<CompanyPage companyId={companyId}/>)
                }
            </Card>
        </>
    )
}
export default CompanyView;