import { TextField } from "@mui/material";
import { wgfPropsStateChange } from "../functions/lawFunctions";

const LawTextarea = ({ propstate, label, rows }) => {
    const handleChange = (e) => {
        wgfPropsStateChange(e, { propstate });
    }
    return (
        <TextField
            label={label}
            multiline
            fullWidth={true}
            sx={{ mb: '10px', mt:'10px' , display: 'block', width: '100%' }}
            onChange={handleChange}
            rows={rows ?? 4}
            value={(propstate.length > 2 ? propstate[propstate[2]] : propstate[0])}
        />
    )
}
export default LawTextarea;