import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import EmployeeFormBase from "./EmployeeFormBase";
import BoxCenter from "../../../ui/BoxCenter";
import { Button, Grid2 } from "@mui/material";
import { wgfIsNotNull } from "../../../functions/lawFunctions";

const EmployeeEdit = ({ employeeId, callback }) => {
    const [employee, setEmployee] = useState(null);
    useEffect(() => {
        if (employeeId) {
            lawFetchGet('CompanyEmployees/get?id=' + employeeId, null, setEmployee, null, null, null, false, null);
        }
    }, [])
    const handleSave = () => {
        if (wgfIsNotNull([employee['name'], employee['sureName']], true) === true) {
            lawFetchPost('CompanyEmployees/edit', employee, null, null, null, () => {
                if (callback) {
                    callback();
                }
            }, null, true, null);
        }
    }
    return (
        (employee) && (
            <>
                <Grid2 container={true} spacing={2}>
                    <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
                        <EmployeeFormBase employeeState={employee} setEmployeeState={setEmployee} />
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
                    </Grid2>
                </Grid2>
                <BoxCenter>
                    <Button variant="contained" onClick={handleSave}>Opslaan</Button>
                </BoxCenter>
            </>
        )
    )
}
export default EmployeeEdit;