import { appObjects } from "../../App";
import { lawRoles } from "../../components/lawConstants";
import AccountantViewForIndex from "../../components/data/accountant/AccountantViewForIndex";
import AdminViewForIndex from "../../components/data/admin/AdminViewForIndex";
import CustomerViewForIndex from "../../components/data/customer/CustomerViewForIndex";
import { wgfAdminRoleControl } from "../../components/functions/lawFunctions";
const IndexView = () => {
    let view = null;
    if (appObjects.values.loginInfo) {
        if (appObjects.values.loginInfo.roleName === lawRoles.accountant) {
            view = <AccountantViewForIndex />;
        }
        else if (appObjects.values.loginInfo.roleName === lawRoles.customer) {
            view = <CustomerViewForIndex />;
        }
        else if (appObjects.values.loginInfo.roleName === lawRoles.lawyer) {

        }
        else if (wgfAdminRoleControl()) {
            view = <AdminViewForIndex />;
        }
    }
    return (
        <>
            {view}
        </>
    )
}
export default IndexView;