import { Box, IconButton, Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const LawModal = ({ children, state, setState, style, header, closeCallback }) => {
    const modalHide = () => {
        if(closeCallback){
            closeCallback();
        }
        if (setState) {
            setState(false);
        }

    }
    return (
        <>
            <Modal
                keepMounted
                open={state}
                onClose={() => setState(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box className="law-modal" style={{ ...style }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'top', alignItems: 'top', justifyContent: 'space-between' }}>
                        {
                            (header) ? (
                                <Typography sx={{ fontSize: '18px' }}>{header}</Typography>
                            ) : (
                                <Box></Box>
                            )
                        }
                        <Box>
                            <IconButton aria-label="delete" onClick={modalHide}>
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                    {children}
                </Box>
            </Modal >
        </>
    )
}
export default LawModal;