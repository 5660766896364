import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
/*
Base64 gibi diger ornekler icin
https://react-pdf-viewer.dev/examples/
*/
const PdfViewer = ({ url, height }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    //let pdfUrl = "https://pdfobject.com/pdf/sample.pdf";
    return (
        <div style={{ height: height ?? '750px' }}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <Viewer
                    fileUrl={url}
                    plugins={[defaultLayoutPluginInstance]}
                />
            </Worker>
        </div>
    )
}
export default PdfViewer;