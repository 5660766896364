import { Add, CalendarMonth, Delete, StickyNote2 } from "@mui/icons-material";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import { wgfChangeState, wgfDateTimeFormat, wgfObjectClone } from "../../../functions/lawFunctions";
import LawDrawer from "../../../ui/LawDrawer";
import { appObjects, ContxAppData } from "../../../../App";
const _compValues = {
    noteInitial: {
        id: '',
        note: '',
        date: ''
    }
}
const LawNavbarStickyNotes = () => {
    const { appDatas, setAppDatas } = useContext(ContxAppData);
    const [show, setShow] = useState(false);
    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState(null)
    const [selectedNote, setSelectedNote] = useState(_compValues.noteInitial)
    const getAll = () => {
        lawFetchGet('stickynote/all', null, setNotes, [], null, null, false, null);
    }
    useEffect(() => {
        if (show) {
            getAll();
        }
        else {
            setNotes([]);
        }
    }, [show])

    const handleTextareaBlur = () => {
        if (selectedNote && selectedNote.id) {
            edit(selectedNote);
        }
    }
    const handleTextareaBlurForNew = () => {
        if (note === '') {
            setNote(null);
        }
        else {
            edit({ id: '', note });
        }
    }
    const handleDelete = (id) => {
        if (id) {
            lawFetchGet('stickynote/delete?id=' + id, null, null, null, () => {
                getAll();
                if (appDatas) {
                    let datas = wgfObjectClone(appDatas);
                    if (datas && datas['navbarCountStickyNotes']) {
                        datas['navbarCountStickyNotes'] = (datas['navbarCountStickyNotes'] - 1 > 0 ? datas['navbarCountStickyNotes'] - 1 : 0);
                        setAppDatas(datas);
                    }
                }
            }, null, true, null);
        }
    }
    const edit = (noteParams) => {
        lawFetchPost('stickynote/edit', noteParams, null, null, null, () => {
            getAll();
            setNote(null);
            if (noteParams && !noteParams.id && appDatas) {
                let datas = wgfObjectClone(appDatas);
                if (datas && datas['navbarCountStickyNotes']) {
                    datas['navbarCountStickyNotes'] = datas['navbarCountStickyNotes'] + 1;
                    setAppDatas(datas);
                }
            }
        }, null, true, null);
    }
    appObjects.stickyNote.showDrawer = (isShow) => {
        setShow(isShow ?? false);
    }
    return (
        <>
            <Tooltip title="Calendar">
                <IconButton
                    onClick={() => setShow(true)}
                    sx={{ color: '#0b0b55' }}
                    size="small"
                >
                    {
                        (appDatas && appDatas.navbarCountStickyNotes > 0) ? (
                            <Badge badgeContent={appDatas.navbarCountStickyNotes} color="secondary">
                                <StickyNote2 sx={{ color: '#0b0b55' }} />
                            </Badge>
                        ) : (
                            <StickyNote2 />
                        )
                    }

                </IconButton>
            </Tooltip>
            {
                (show) && (
                    <>
                        <LawDrawer showState={show} setShowState={setShow} header="Plaknotities">
                            <div style={{ width: '100%', padding: '40px 5px' }}>

                                {
                                    (note !== null) ? (
                                        <div style={{ width: '96%', borderRadius: '0px 0px 20px 5px', margin: 'auto', marginBottom: '20px', background: '#feff9c', position: 'relative', height: 'auto', padding: '5px' }}>
                                            <textarea onBlur={handleTextareaBlurForNew} value={note} onChange={(e) => setNote(e.target.value)} style={{ width: '100%', minHeight: '150px', height: 'auto', margin: 0, background: '#feff9c', border: 'none' }}>
                                            </textarea>
                                        </div>
                                    ) : (
                                        <button onClick={() => setNote('')} style={{ width: '200px', height: '120px', background: 'transparent', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', borderRadius: '10px', margin: 'auto', marginBottom: '20px', border: '7px solid rgba(255,255,255,0.3)' }}>
                                            <Add sx={{ fontSize: '70px', color: 'rgba(255,255,255,0.3)' }} />
                                        </button>
                                    )
                                }
                                {
                                    notes.map((item, index) => {
                                        return (
                                            <div key={('note-' + index)} style={{ width: '96%', borderRadius: '0px 0px 20px 5px', margin: 'auto', marginBottom: '20px', background: '#feff9c', position: 'relative', height: 'auto', padding: '5px' }}>
                                                <IconButton
                                                    color="error"
                                                    size="small"
                                                    onClick={() => handleDelete(item.id)}
                                                    style={{ position: 'absolute', top: '-10px', right: '-10px', width: '30px', height: '30px', borderRadius: '30px', background: 'red', color: '#fff' }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                                <textarea value={((selectedNote && selectedNote.id === item.id) ? selectedNote.note : item.note)} onChange={(e) => wgfChangeState(e.target.value, 'note', selectedNote, setSelectedNote)} onFocus={() => setSelectedNote(item)} onBlur={(e) => handleTextareaBlur(e, item)} style={{ width: '100%', minHeight: '150px', height: 'auto', margin: 0, background: '#feff9c', border: 'none' }}>
                                                </textarea>
                                                <small className="text-muted" style={{ margin: 0, display: 'block', width: '100%', background: '#feff9c' }}><CalendarMonth sx={{ fontSize: '18px' }} /> {wgfDateTimeFormat(item.date, 'dd.MM.yyyy HH:mm')}</small>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </LawDrawer>
                    </>
                )
            }
        </>
    )
}
export default LawNavbarStickyNotes;