import { Box, Tab, Tabs } from "@mui/material";
import Card from "../../components/ui/Card";
import { useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import EmailInfoes from "../../components/data/app/settings/EmailInfoes";
import SidebarItems from "../../components/data/app/settings/SidebarItems";
import TableFaqs from "../../components/data/app/faqs/TableFaqs";


const SettingsView = () => {
    const [tabValue, setTabValue] = useState("1");

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Card>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example"
                        >
                            <Tab value="1" label="Email" />
                            <Tab value="2" label="Sidebar Items" />
                            <Tab value="3" label="Faqs" />
                        </Tabs>
                    </Box>
                    <TabPanel value="1">
                        <Box sx={{ maxWidth: 400 }}>
                            <EmailInfoes />
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <SidebarItems />
                    </TabPanel>
                    <TabPanel value="3">
                        <TableFaqs />
                    </TabPanel>
                </TabContext>
            </Card>
        </>
    )
}
export default SettingsView;