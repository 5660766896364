import { useState } from "react";
import ReportDaily from "../../components/data/customer/ReportDaily";
import ReportInvoiceTypes from "../../components/data/customer/ReportInvoiceTypes";
import ReportYearly from "../../components/data/customer/ReportYearly";
import Card from "../../components/ui/Card"
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid2, Tab } from "@mui/material";
import { Calculate, CalendarMonth, History, Percent } from "@mui/icons-material";

const CustomerReportView = () => {
    const [tabValue, setTabValue] = useState("1");
    const handleTabChange = (e, value) => {
        setTabValue(value);
    }
    return (
        <>
            <Card style={{ minHeight: '70vh', padding: 0, overflow:'hidden' }}>
                <div className="notebook-grid pt-5 pb-5">
                    <div className="m-auto" style={{ maxWidth: '1200px' }}>
                        <Grid2 spacing={2} container={true}>
                            <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 3 }}>
                                <div className="jf-center mb-2">
                                    <CalendarMonth sx={{ fontSize: '50px', color: 'green' }} />
                                </div>
                                <h5 className="text-center">This Month</h5>
                                <ul>
                                    <li>Gelir: €12345</li>
                                    <li>Gider: €12345</li>
                                    <li>Gider Fatura Adeti: 25</li>
                                    <li>Gelir Fatura Adeti: 25</li>
                                </ul>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 3 }}>
                                <div className="jf-center mb-2">
                                    <History sx={{ fontSize: '50px', color: 'green' }} />
                                </div>
                                <h5 className="text-center">Last Month</h5>
                                <ul>
                                    <li>Gelir: €12345</li>
                                    <li>Gider: €12345</li>
                                    <li>Gider Fatura Adeti: 25</li>
                                    <li>Gelir Fatura Adeti: 25</li>
                                </ul>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 3 }}>
                                <div className="jf-center mb-2">
                                    <Percent sx={{ fontSize: '50px', color: 'green' }} />
                                </div>
                                <h5 className="text-center">Average</h5>
                                <ul>
                                    <li>Gelir: €12345</li>
                                    <li>Gider: €12345</li>
                                    <li>Gider Fatura Adeti: 25</li>
                                    <li>Gelir Fatura Adeti: 25</li>
                                </ul>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 3 }}>
                                <div className="jf-center mb-2">
                                    <Calculate sx={{ fontSize: '50px', color: 'green' }} />
                                </div>
                                <h5 className="text-center">Total</h5>
                                <ul>
                                    <li>Gelir: €12345</li>
                                    <li>Gider: €12345</li>
                                    <li>Gider Fatura Adeti: 25</li>
                                    <li>Gelir Fatura Adeti: 25</li>
                                </ul>
                            </Grid2>
                        </Grid2>
                    </div>
                </div>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                            <Tab label="Daily" value="1" />
                            <Tab label="Yearly" value="2" />
                            <Tab label="Invoice Types" value="3" />
                            <Tab label="Sellers" value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <h5>Daily</h5>
                        <ReportDaily />
                    </TabPanel>
                    <TabPanel value="2">
                        <h5>Yearly</h5>
                        <ReportYearly />
                    </TabPanel>
                    <TabPanel value="3">
                        <h5>Invoice Types</h5>
                        <ReportInvoiceTypes />
                    </TabPanel>
                    <TabPanel value="4">
                        <h5>Sellers</h5>
                    </TabPanel>
                </TabContext>
            </Card>
        </>
    )
}
export default CustomerReportView;