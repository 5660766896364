import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { lawFetchGet } from "../../components/functions/lawFetch";
import CustomerCreate from "../../components/data/accountant/CustomerCreate";
import { AlternateEmail, ChevronRight, Delete, MailOutline, Phone } from "@mui/icons-material";
import LawModal from "../../components/ui/LawModal";
import { appObjects } from "../../App";
import Card from "../../components/ui/Card";
import LawDataTable from "../../components/ui/LawDataTable";
import LawStatus from "../../components/ui/LawStatus";
import MessageBody from "../../components/data/app/messages/MessageBody";

const _compValues = {
    selectedCustomer: null
}
const CustomersView = () => {
    const [customers, setCustomers] = useState([]);
    const [showModalMessage, setShowModalMessage] = useState(false);
    useEffect(() => {
        getAll();
    }, [])
    const getAll = () => {
        lawFetchGet('customer/all', null, setCustomers, [], null, null, false, null);
    }

    const handleSelectForMessage = (item) => {
        _compValues.selectedCustomer = item;
        setShowModalMessage(true);
    }
    const handleDelete = (id) => {
        if (id) {
            appObjects.dialog.delete(() => {
                lawFetchGet('customer/delete?id=' + id, null, null, null, getAll, null, true, null);
            }, null);
        }
    }
    let columns = [
        {
            name: 'Naam',
            selector: (row) => row.name + ' ' + row.surname,
            sortable: true,
        },
        {
            name: 'Telefoon',
            selector: (row) => row.phone,
            sortable: true,
        },
        {
            name: 'E-mail',
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'ActivePassiveDeleted'} />
            )
        },
        {
            name: 'Action',
            width: '220px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <IconButton onClick={(e) => { window.location.href = ("tel:" + row.phone); e.preventDefault(); }}>
                        <Phone color="warning"/>
                    </IconButton>
                    <IconButton onClick={(e) => { window.location.href = ('mailto:' + row.email); e.preventDefault(); }}>
                        <AlternateEmail color="success"/>
                    </IconButton>
                    <IconButton onClick={() => handleSelectForMessage(row)}>
                        <MailOutline color="success"/>
                    </IconButton>
                    <IconButton onClick={() => handleDelete(row.id)}>
                        <Delete color="error"/>
                    </IconButton>
                    <IconButton onClick={() => { appObjects.goTo('/customers/customer?customerId=' + row.id) }}>
                        <ChevronRight color="primary"/>
                    </IconButton>
                </div>
            ),
        }
    ];

    return (
        <Card>
            <div>
                <CustomerCreate callback={getAll} />
            </div>
            <LawDataTable columns={columns} dataState={customers} setDataState={setCustomers} searchFields={['name', 'surname', 'email', 'phone']} />
            {
                (showModalMessage && _compValues.selectedCustomer) && (
                    <LawModal state={showModalMessage} setState={setShowModalMessage} style={{ width: '90%', maxWidth: '800px', padding: '0px' }}>
                        <MessageBody isForMessagePage={false} userId={_compValues.selectedCustomer.id} />
                    </LawModal>
                )
            }

        </Card>
    )
}
export default CustomersView;
