import { Avatar, Box, Button, IconButton, Tab } from "@mui/material";
import Card from "../../components/ui/Card";
import { Logout } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { lawLanguages, lawStatusActivePassive } from "../../components/lawConstants";
import { useEffect, useState } from "react";
import LawInput from "../../components/ui/LawInput";
import ChangePassword from "../../components/data/admin/users/ChangePassword";
import { lawFetchGet, lawFetchPost } from "../../components/functions/lawFetch";
import LawRoleSelect from "../../components/ui/LawRoleSelect";
import LawSelect from "../../components/ui/LawSelect";
import userIcon from '../../img/9187604_72.png'
import { wgfAdminRoleControl, wgfGetQueryStringValue, wgfLogout } from "../../components/functions/lawFunctions";
import { appObjects } from "../../App";
import LawInputPhone from "../../components/ui/LawInputPhone";
import LawInputEmail from "../../components/ui/LawInputEmail";

const UserView = () => {
    const [userId]=useState(wgfGetQueryStringValue('id'));
    const [user, setUser] = useState({});
    const [value, setValue] = useState('1');
    useEffect(() => {
        if (userId) {
            lawFetchGet('account/get?userId=' + userId, null, setUser, {}, null, null, false, null);
        }
    }, [userId])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const save = () => {
        lawFetchPost('account/edit', user, null, null, null, null, null, true, null);
    }
    const handleUserLogOut = () => {
        if (user && user.userId) {
            lawFetchGet('login/logout-for-user?id=' + user.userId);
        }
    }

    return (
        <Card>
            <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between', mb: 5 }}>
                <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                    <Avatar
                        alt="Remy Sharp"
                        src={userIcon}
                        sx={{ width: 56, height: 56, mr: 3 }}
                    />
                    <Box>
                        <Box>
                            <h3 style={{ margin: 0 }}>{user.name} {user.surname}</h3>
                            <small>{user.roleName}</small>
                        </Box>
                        {
                            /*
                               <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<Cloud />}
                            >
                                Chang Image
                                <input style={{
                                    clip: 'rect(0 0 0 0)',
                                    clipPath: 'inset(50%)',
                                    height: 1,
                                    overflow: 'hidden',
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    whiteSpace: 'nowrap',
                                    width: 1,
                                }}
                                    type="file"
                                    onChange={(event) => console.log(event.target.files)}
                                    multiple
                                />
                            </Button>
                            */
                        }
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                    {
                        (appObjects.values.loginInfo && wgfAdminRoleControl()===true) ? (
                            <IconButton onClick={handleUserLogOut}>
                                <Logout />
                            </IconButton>
                        ) : (
                            <IconButton onClick={wgfLogout}>
                                <Logout />
                            </IconButton>
                        )
                    }
                </Box>
            </Box>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Data" value="1" />
                        <Tab label="Password" value="2" />
                        <Tab label="Two Factor" value="3" />
                        <Tab label="Login Log" value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    {
                        (user) && (
                            <Box sx={{ maxWidth: '440px' }}>
                                <LawInput propstate={[user, setUser, 'name']} label={"Name"} />
                                <LawInput propstate={[user, setUser, 'surname']} label={"Surname"} />
                                <LawInputEmail state={user} setState={setUser} fieldName={'email'}/>
                                <LawInputPhone state={user} setState={setUser} fieldName={'phone'}/>
                                <LawSelect label={"Taal"} state={user} setState={setUser} fieldName={'language'} defaultValue={'nl'} items={lawLanguages} />
                                <LawRoleSelect state={user} setState={setUser} fieldName={'roleName'} defaultValue={user['roleName']} />
                                <LawSelect label={"Status"} state={user} setState={setUser} fieldName={'status'} defaultValue={1} items={lawStatusActivePassive} />

                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button onClick={save} variant="contained">Update</Button>
                                </Box>
                            </Box>
                        )
                    }
                </TabPanel>
                <TabPanel value="2">
                    {
                        (user) && (
                            <ChangePassword userId={user.userId} isMyPassword={false} />
                        )
                    }
                </TabPanel>
                <TabPanel value="3">
                    <h4>Google Two Factor</h4>
                </TabPanel>
                <TabPanel value="4">
                    <h4>Login logs</h4>
                </TabPanel>
            </TabContext>
        </Card>
    )
}
export default UserView;