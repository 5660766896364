import { ChevronRight } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import LawStatus from "../../../ui/LawStatus";
import LawDataTable from "../../../ui/LawDataTable";
import { lawFetchGet } from "../../../functions/lawFetch";
import EmployeeWorkMontlyCreate from "./EmployeeWorkMontlyCreate";

const EmployeeWorkMontlyTable = ({ employeeId }) => {
    const [works, setWorks] = useState([]);
    const getAll = () => {
        if (employeeId) {
            lawFetchGet('EmployeeWorkMontly/all', null, setWorks, [], null, null, false, null);
        }
    }
    useEffect(getAll,[])
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Surname',
            selector: (row) => row.sureName,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'ActivePassiveDeleted'} />
            )
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <IconButton onClick={() => { }}>
                        <ChevronRight/>
                    </IconButton>
                </div>
            ),
        }
    ];
    return (
        <>
        <EmployeeWorkMontlyCreate />
            <LawDataTable dataState={works} setDataState={setWorks} columns={columns} />
        </>
    )
}
export default EmployeeWorkMontlyTable;