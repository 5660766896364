import Card from "../../components/ui/Card";

const CustomerPaymentsView = () => {
    return (
        <Card>
            <ul>
                <li>Bu ay : €254.652 vergi ödemeniz var</li>
                <li>Geçmiş ödemeler</li>
                <li>Sabit ödemelerde tarihi yakın olandan uzağa doğru sıralı göster (bağlantı linki de ekle, tıklayınca sabit giderler sayfası açılsın)</li>
            </ul>
        </Card>
    )
}
export default CustomerPaymentsView;