import { Box, Button } from "@mui/material";
import { useState } from "react";
import { AccountCircle, Add } from "@mui/icons-material";
import BoxCenter from "../../../ui/BoxCenter";
import { lawFetchPost } from "../../../functions/lawFetch";
import UserBaseElements from "./UserBaseElements";
import LawRoleSelect from "../../../ui/LawRoleSelect";
import LawModal from "../../../ui/LawModal";
import LawInputPassword from "../../../ui/LawInputPassword";
const UserCreate = ({ callback }) => {
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState({ Language: 'nl', RoleName: 'Admin' });
    const create = () => {
        lawFetchPost('/account/create', user, null, null, null, () => {
            setOpen(false);
            setUser({ Language: 'nl', RoleName: 'Admin' });
            if (callback) {
                callback();
            }
        }, null, true, null)
    }
    return (
        <>
            <Button onClick={() => setOpen(true)}><Add /> Create User</Button>
            <LawModal state={open} setState={setOpen}>
                <div className="jf-center mb-3">
                    <AccountCircle style={{ width: '90px' }} />
                </div>
                <UserBaseElements user={user} setUser={setUser} />
                <LawInputPassword state={user} setState={setUser} fieldName={'NewPassword'} isWithReEnter={true} />
                <LawRoleSelect state={user} setState={setUser} fieldName={'RoleName'} defaultValue={'Manager'} />
                <BoxCenter>
                    <Box>
                        <Button onClick={create} variant="contained" sx={{ width: '100%', mb: 2, mt: 5 }}>Ekle</Button>
                    </Box>
                </BoxCenter>
            </LawModal>
        </>
    )
}
export default UserCreate;