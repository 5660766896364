import CompaniesTable from "../../components/data/accountant_customer/company/CompaniesTable";
import Card from "../../components/ui/Card";

const CustomerCompaniesView = () => {
    return (
        <Card>
            <CompaniesTable />
        </Card>
    )
}
export default CustomerCompaniesView;