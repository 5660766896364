import { Grid2 } from "@mui/material";
import LawSelect from "../../ui/LawSelect";
import { useState } from "react";
import { BarChart } from "@mui/x-charts";
import { getYearsArray } from "../../functions/lawFunctions";

const ReportYearly = () => {
    const [year, setYear] = useState((new Date()).getFullYear());
    return (
        <Grid2 container={true} spacing={2} sx={{mb:'30px'}}>
            <Grid2 size={12}>
                <div className="d-flex justify-content-end">
                    <div>
                        <LawSelect state={year} setState={setYear} items={getYearsArray(-3,0)} />
                    </div>
                </div>
            </Grid2>
            <Grid2 size={12}>
                <BarChart
                    xAxis={[{
                        scaleType: 'band', data: ["Jan", "Feb", "Mrt", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"]
                    }]}
                    series={[{
                        data: [4, 3, 5],
                        label: 'Gelir'
                    },
                    {
                        data: [1, 6, 3],
                        label: 'Gider'
                    }
                    ]}
                    height={400}
                />
            </Grid2>
        </Grid2>
    )
}
export default ReportYearly;