import {  AdminPanelSettings, Calculate, Gavel, Person } from "@mui/icons-material";
export const WgControlsStyle = {
    marginBottom: '10px',
    width: '100%'
}

export const lawLanguages = [{ value: 'nl', text: 'Dutch' }, { value: 'en', text: 'English' }];
export const lawStatusActivePassive = [{ value: 1, text: 'Actief' }, { value: 0, text: 'Passief' }];

export const lawGenders = [{ value: 1, text: 'Man' }, { value: 2, text: 'Vrouw' }];
export const wgGenders = [
    { value: 1, text: 'Man' },
    { value: 2, text: 'Woman' }
]

//Create olan StepType sadece backend kısmından dinamik eklenecek
export const lawTicketStepTypes = [{ value: 2, text: 'comment' }, { value: 100, text: 'end' }];
export const lawCountries = [{ value: 'nl', text: 'Netherlands' }, { value: 'be', text: 'Belgium' }];


export const wgYesNo = [
    { value: 1, text: 'Ja' },
    { value: 0, text: 'Nee' }
]

export const wgWorkTypes = [
    { value: 1, text: 'Full time' },
    { value: 2, text: 'Part time' }
]

export const wgVatTypes = [
    { value: 21, text: '21%' },
    { value: 9, text: '9%' },
    { value: 0, text: '0%' },
]
export const wgInvoiceTypes = [
    { value: 1, text: 'Sell' },
    { value: 2, text: 'Buy' }
]

/*İndirim orani turleri */
export const wgfDiscountTypes = [
    { value: 1, text: '%' },
    { value: 2, text: '€' }
]
export const wgPortalNames = {
    admin: 'Admin',
    accountant: 'Accountant',
    lawyer: 'Lawyer',
    customer: 'Customer'
}

export const wgPortalTypes = [
    {
        icon: <AdminPanelSettings />,
        text: 'Admin Portal',
        value: wgPortalNames.admin,
        showChangePortal: true
    },
    {
        icon: <Calculate />,
        text: 'Accountant',
        value: wgPortalNames.accountant,
        showChangePortal: true
    },
    {
        icon: <Gavel />,
        text: 'Lawyer',
        value: wgPortalNames.lawyer,
        showChangePortal: true
    },
    {
        icon: <Person />,
        text: 'Customer',
        value: wgPortalNames.customer,
        showChangePortal: false //Portal degisim modalinda gorunmesin. Customer icin login bilgileri gerekli
    }
]

export const wgSnackbarTypes = {
    default: 'default',
    error: 'error',
    success: 'success',
    warning: 'warning',
    info: 'info'
}
export const wgStaticInputTypes = {
    text: 'text',
    number: 'number',
    tel: 'tel',
    search: 'search',
    email: 'email',
    url: 'url',
    date: 'date',
    datetime: 'datetime'
}

export const lawRoles = {
    admin: 'Admin',
    customer: 'Customer',
    superAdmin: 'SuperAdmin',
    lawyer: 'Lawyer',
    lawyerAssistant: 'LawyerAssistant',
    accountant: 'Accountant',
    accountantAssistant: 'AccountantAssistant'
}
export const lawRolesList = () => {
    let _roles = [];
    for (var prop in lawRoles) {
        if (!lawRoles.hasOwnProperty(prop)) continue;
        _roles.push({ value: lawRoles[prop], text: lawRoles[prop], selected: false, show: true });
    }
    return _roles;
}
export const lawRolesListWithLines = () => {
    let _roles = [];
    for (var prop in lawRoles) {
        if (!lawRoles.hasOwnProperty(prop)) continue;
        _roles.push({ value: `-${lawRoles[prop]}-`, text: lawRoles[prop], selected: false, show: true });
    }
    return _roles;
}



