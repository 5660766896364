import { useState } from "react";
import { wgfIsNotNull } from "../../../functions/lawFunctions";
import { lawFetchPost } from "../../../functions/lawFetch";
import CompanyFormBaseElements from "./CompanyFormBaseElements";
import LawCreateDataModal from "../../../ui/LawCreateDataModal";
const CompanyCreate = ({ customerId, callback }) => {
    const [company, setCompany] = useState({ customerId: customerId });
    const create = () => {
        if (wgfIsNotNull([company['customerId'], company['name'], company['phone'], company['email']], true) === true) {
            lawFetchPost('CustomerCompany/create', company, null, null, null, () => {
                setCompany({ customerId: customerId });
                callback();
            }, null, false, null);
        }
    }
    return (
        <>
            <LawCreateDataModal createEvent={create}>
                <CompanyFormBaseElements company={company} setCompany={setCompany} />
            </LawCreateDataModal>
        </>
    )
}
export default CompanyCreate;