import FixedExpensesTable from "../../components/data/accountant_customer/fixed_expense/FixedExpensesTable";
import Card from "../../components/ui/Card";

const FixedExpensesView = () => {
    return (
        <>
            <Card>
                <FixedExpensesTable />
            </Card>
        </>
    )
}
export default FixedExpensesView;