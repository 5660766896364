import { useEffect, useState } from "react"
import LawModal from "../../../ui/LawModal";
import { Box, Button } from "@mui/material";
import BoxCenter from "../../../ui/BoxCenter";
import LawInput from "../../../ui/LawInput";
import { wgfChangeState, wgfDateTimeFormat, wgfIsNotNull, wgfObjectClone } from "../../../functions/lawFunctions";
import LawSelectUsersOrRoles from "../share/LawSelectUsersOrRoles";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import LawTimePicker from "../../../ui/LawTimePicker";
import { appObjects } from "../../../../App";
import LawButtonGroup from "../../../ui/LawButtonGroup";


const _calendarInitial = {
    id: '',
    title: '',
    description: '',
    start: '',
    end: '',
    allDay: true,
    shareUsersId: '',
    createByUserName: ''
}


const LawCalendarEdit = ({ date, setDate, eventId, setEventId, editCallback }) => {

    const [show, setShow] = useState(false);
    const [event, setEvent] = useState(null);
    const [description, setDescription] = useState('');

    const [time, setTime] = useState(null);
    useEffect(() => {
        if (eventId) {
            lawFetchGet('calendar/get?id=' + eventId, null, setEvent, null, null, null, false, (resp) => {
                if (resp && resp.data) {
                    setTime(wgfDateTimeFormat(resp.data.start, 'HH:mm'));
                    setDescription(resp.data.description);
                    setEvent(resp.data);
                    setShow(true);
                }
            });
        }
        else if (date) {
            let _time = wgfDateTimeFormat(new Date(), 'HH:mm');
            setTime(_time);
            let _dt = date.split('-');
            _calendarInitial['start'] = `${_dt[0]}-${_dt[1]}-${_dt[2]}T${_time}:00` // "2024-12-05T00:00:00"
            setEvent(_calendarInitial);
            setDescription('');
            setShow(true);
        }
    }, [date, eventId])

    const handleSave = () => {
        if (wgfIsNotNull([event['title']], true) === true) {
            let _params = wgfObjectClone(event);
            let _dt = date.split('-');
            _params['start'] = `${_dt[0]}-${_dt[1]}-${_dt[2]}T${time}:00` // "2024-12-05T00:00:00"
            lawFetchPost('calendar/edit', _params, null, null, null, () => {
                setDescription('');
                setEvent(null);
                setTime(wgfDateTimeFormat(new Date(), 'HH:mm'));
                // getAll();
                if (editCallback) {
                    editCallback();
                }
            }, null, true, null)

        }
    }

    const selectedUsersRoles = (resp) => {
        let _events = '';
        if (resp) {
            _events = resp.map(item => item.value ?? item.id).join(',');
        }
        wgfChangeState(_events, 'shareUsersId', event, setEvent);
    }
    const handleModalClose = () => {
        setDate(null);
        setEvent(null);
        if (setDate) {
            setDate(null);
        }
        if (setEventId) {
            setEventId(null);
        }
    }
    const handleDelete = () => {
        if (event && event.id) {
            lawFetchGet('calendar/delete?id=' + event.id, null, null, null, () => {
                setDescription('');
                setEvent(null);
                setTime(wgfDateTimeFormat(new Date(), 'HH:mm'));
                if (editCallback) {
                    editCallback();
                }
                if (setEventId) {
                    setEventId(null);
                }
            }, null, true, null);
        }
    }

    return (
        (show && date) && (
            <LawModal state={show} setState={setShow} closeCallback={handleModalClose}>
                <h5 className="text-center">{wgfDateTimeFormat((new Date(date)), 'dd.MM.yyyy')}</h5>
                {
                    (event && ((event.id === '' || appObjects.values.loginInfo.id === event.createdByUserId) ? (
                        <Box sx={{ maxWidth: '420px', m: 'auto' }}>
                            <LawInput label={"title"} propstate={[event, setEvent, 'title']} />
                            <textarea className="d-block form-control border-none" rows={2} value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description"></textarea>
                            <LawSelectUsersOrRoles renderState={event} isAddUser={true} isAddRoles={true} selectedIdString={event['shareUsersId']} responseRunFunction={selectedUsersRoles} />
                            <BoxCenter>
                                <LawTimePicker time={time} setTime={setTime} />
                            </BoxCenter>
                            <LawButtonGroup>
                                <Button onClick={handleSave} variant="contained">Opslaan</Button>
                                {
                                    (event.id) && (<Button color="error" onClick={handleDelete} variant="contained">Delete</Button>)
                                }
                            </LawButtonGroup>
                        </Box>
                    ) : (
                        <>
                            <h4>{event.title}</h4>
                            <p>{event.description}</p>
                            <span>{wgfDateTimeFormat(event.start, 'dd.MM.yyyy HH:mm')}</span>
                            <hr />
                            <small className="d-block">Create: {wgfDateTimeFormat(event.createDate, 'dd.MM.yyyy HH:mm')} - {event.createdByUserName}</small>
                            {
                                (event.updateDate && event.updatedByUserName) && (<small className="d-block">Update: {wgfDateTimeFormat(event.updateDate, 'dd.MM.yyyy HH:mm')} - {event.updatedByUserName}</small>)
                            }
                        </>
                    )))
                }
            </LawModal>
        )

    )
}
export default LawCalendarEdit;