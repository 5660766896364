import { useEffect, useState } from "react"
import { lawFetchGet } from "../functions/lawFetch"
import LawSelect from "./LawSelect";

const LawCitiesSelect=({state,setState,fieldName})=>{
    const [cities,setCities]=useState([]);
    useEffect(()=>{
        lawFetchGet('app/cities',null,setCities,[],null,null,false,null);
    },[])
  return(
    <LawSelect items={cities} label={"City"} state={state} setState={setState} fieldName={fieldName} />
  )
}
export default LawCitiesSelect;