import { Abc, Person } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { wgfChangeState } from "../functions/lawFunctions";
const _compValue = {
    regex: /^[A-Za-z. ]+$/,
    valid: false
}
/*
NL +31999999999
*/
const LawInputNameSurname = ({ state, setState, fieldName, label }) => {
    const handleChange = (e) => {

        wgfChangeState(e.target.value, fieldName, state, setState);
    }
    let tempVal = (fieldName ? state[fieldName] : state);
    _compValue.valid = false;
    if (tempVal) {
        _compValue.valid = _compValue.regex.test(tempVal) && tempVal.length > 1;
    }
    return (
        <TextField
            onChange={handleChange} className='mb-3' value={tempVal}
            label={label ?? ''}
            fullWidth
            slotProps={{
                input: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <Abc color={_compValue.valid ? 'warning' : 'error'} />
                        </InputAdornment>
                    ),
                },
            }}
            size='small'
        />
    )
}
export default LawInputNameSurname;