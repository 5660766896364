import { useState } from "react"
import FixedExpenseBaseForm from "./FixedExpenseBaseForm";
import { lawFetchPost } from "../../../functions/lawFetch";
import { wgfGetQueryStringValue } from "../../../functions/lawFunctions";
import LawCreateDataModal from "../../../ui/LawCreateDataModal";

const FixedExpenseCreate = ({ callback, companyId }) => {
    const [invoice, setInvoice] = useState({ companyId: companyId ?? wgfGetQueryStringValue('companyId') });
    const handleCreate = () => {
        lawFetchPost('fixedExpense/create', invoice, null, null, null, () => {
            setInvoice({ companyId: companyId ?? wgfGetQueryStringValue('companyId') });
            if (callback) {
                callback();
            }
        }, null, true, null)
    }
    return (
        <>
            {(invoice['companyId']) && (
                <LawCreateDataModal createEvent={handleCreate}>
                    <FixedExpenseBaseForm invoiceState={invoice} setInvoiceState={setInvoice} />
                </LawCreateDataModal>
            )}

        </>
    )
}
export default FixedExpenseCreate;
