import { Box, Tab } from "@mui/material";
import InvoicesTable from "../../components/data/accountant/InvoicesTable"
import Card from "../../components/ui/Card";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import OpenInvoicesTable from "../../components/data/accountant/OpenInvoicesTable";
import { useState } from "react";

const InvoicesView = () => {
    const [tabValue, setTabValue] = useState('1');
    const handleChangeTab = (e, value) => {
        setTabValue(value);
    }
    return (
        <Card>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                            <Tab label="Invoices" value="1" />
                            <Tab label="Open Invoices" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <InvoicesTable/>
                    </TabPanel>
                    <TabPanel value="2">
                        <OpenInvoicesTable/>
                    </TabPanel>
                </TabContext>
            </Box>
        </Card>
    )
}
export default InvoicesView;