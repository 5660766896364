import { getMonthsArray } from "../../../functions/lawFunctions";
import LawDatePicker from "../../../ui/LawDatePicker";
import LawDatePickerFromAndTo from "../../../ui/LawDatePickerFromAndTo";
import LawInput from "../../../ui/LawInput";
import LawInputPrice from "../../../ui/LawInputPrice";
import LawSelect from "../../../ui/LawSelect";

const FixedExpenseBaseForm = ({ invoiceState, setInvoiceState }) => {
  return (
    <>
      <LawSelect itemsUrl={'fixedExpense/get-fixed-expense-types'} label={"Fixed Expense Type"} state={invoiceState} setState={setInvoiceState} fieldName={"fixedExpenseTypeId"} />
      <LawInput propstate={[invoiceState, setInvoiceState, 'description']} label={"Description"} />
      <LawInput propstate={[invoiceState, setInvoiceState, 'title']} label={"Title"} />
      <LawSelect itemsUrl={'invoice/get-invoice-types'} label={"Invoice Types"} state={invoiceState} setState={setInvoiceState} fieldName={"InvoiceGroupId"} />
      <LawInputPrice state={invoiceState} setState={setInvoiceState} fieldName={'amount'} label="Amount" />
      <LawDatePicker state={invoiceState} setState={setInvoiceState} fieldName={'invoiceDate'} isSetAutoDate={true} label={"Invoice Date"} />
      <LawDatePickerFromAndTo fromState={invoiceState} setFromState={setInvoiceState} fromFieldName={'fromDate'} fromLabel={"Contract From Date"} toState={invoiceState} setToState={setInvoiceState} toFieldName={'toDate'} toLabel={'Contract End Date'} />
      <LawSelect items={getMonthsArray()} state={invoiceState} setState={setInvoiceState} fieldName={"paymentPeriot"} label={"Payment Periot"} />
    </>
  )
}
export default FixedExpenseBaseForm;