import { FormControl, MenuItem, Select } from "@mui/material";
import { wgfCreateRandomID, wgfObjectClone } from "../functions/lawFunctions";
import { useEffect, useState } from "react";
import { lawFetchGet } from "../functions/lawFetch";

const LawSelect = ({ state, setState, disabled, fieldName, items, itemsUrl, label, defaultValue, isResponseFullData }) => {
    let _id = wgfCreateRandomID();
    const [selectItems, setSelectItems] = useState(items);
    const [compKey, setCompKey] = useState(0);
    useEffect(() => {
        if (itemsUrl && !selectItems) {
            lawFetchGet(itemsUrl, null, setSelectItems, [], null, null, false, null);
        }
        setCompKey(compKey);
    }, [selectItems, state])
    useEffect(() => {
        setSelectItems(items);
    }, [items])
    const changeHandle = (e) => {
        if (isResponseFullData === true) {
            let _data = selectItems.find(f => f.value === e.target.value);
            if (_data) {
                setState(_data);
            }
        }
        else {
            if (fieldName) {
                let _t = wgfObjectClone(state);
                _t[fieldName] = e.target.value;
                setState(_t);
            }
            else {
                setState(e.target.value);
            }
        }
    }
    //isRespFullData=true ise state'ten value degerini almalisin
    let defVal = defaultValue ?? '';
    if(state){
        if (fieldName) {
            if(state[fieldName]){
                defVal = (isResponseFullData === true ? state[fieldName]['value'] : state[fieldName]);
            }
        }
        else {
            defVal = (isResponseFullData === true ? state['value'] : state);
        }
    }
    return (
        <FormControl key={compKey} fullWidth style={{ marginBottom: '10px' }}>
            {
                (label) && (
                    <span variant="standard" style={{ fontSize: 12, color: '#6c757d' }}>{label}</span>
                )
            }
            <Select
                onChange={changeHandle}
                size="small"
                disabled={disabled ?? false}
                value={defVal ?? ''}
                inputProps={{
                    id: _id
                }}
            >
                <MenuItem value="">Kiezen</MenuItem>
                {
                    (selectItems) && (selectItems.map((item, index) => {
                        return (<MenuItem key={`${item.value}-`+index} value={item.value ?? ''}>{item.text}</MenuItem>)
                    }))
                }
            </Select>
        </FormControl>
    )
}
export default LawSelect;