import { TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { wgfChangeState } from "../functions/lawFunctions";
import { WgControlsStyle } from "../lawConstants";
/*
inputAdornments = Input'a [€ ₺ $] eklenecek olan karakterler icin
*/
const LawInputPrice = ({ state, setState, fieldName, min, max, label }) => {

    const handleChange = (e) => {
        let _value = e.target.value;
        _value = _value ? parseFloat(_value.replace(/[€$,]/g, "")) : 0; //NumericFormat nesnesinde €123,50 formatında değer alınıyordu
        wgfChangeState(_value, fieldName, state, setState);
    }

    return (
        <NumericFormat
            value={(state && (fieldName ? state[fieldName] : state))}
            onChange={handleChange}
            customInput={TextField}
            thousandSeparator
            min={min ?? 0}
            max={max ?? 9999999999}
            size="small"
            style={WgControlsStyle}
            valueIsNumericString
            prefix="€"
            variant="outlined"
            label={label}
        />
    )
}
export default LawInputPrice;