import { Calculate, CalendarMonth, DirectionsCar, Edit, Euro, History, Percent, Person } from "@mui/icons-material";
import { Box, Button, Grid2, IconButton, Tab } from "@mui/material";
import Card from "../../../ui/Card";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ReportDaily from "../../customer/ReportDaily";
import ReportYearly from "../../customer/ReportYearly";
import ReportInvoiceTypes from "../../customer/ReportInvoiceTypes";
import { useEffect, useState } from "react";
import { wgfAdminRoleControl, wgfGetCityName, wgfGetQueryStringValue, wgfIsNotNull, wgfObjectClone } from "../../../functions/lawFunctions";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import DataNotFound from "../../../ui/DataNotFound";
import defaultLogo from "../../../../img/logo.png"
import LawModal from "../../../ui/LawModal";
import CompanyFormBaseElements from "./CompanyFormBaseElements";
import LawSelect from "../../../ui/LawSelect";
import { lawStatusActivePassive } from "../../../lawConstants";

const CompanyPage = ({ companyId }) => {
    const [companyIdNo] = useState(companyId ?? wgfGetQueryStringValue('companyId'));
    const [company, setCompany] = useState(null);
    const [editModalShow, setEditModalShow] = useState(false);
    const [tabValue, setTabValue] = useState("1");
    const [sector, setSector] = useState(null);
    const getComp = () => {
        if (companyId) {
            lawFetchGet('CustomerCompany/get?id=' + companyId, null, null, null, null, null, false, (resp) => {
                if (resp && resp.data) {
                    if (resp.data.sectorId) {
                        lawFetchGet('app/get-sector?id=' + resp.data.sectorId, null, setSector, null, null, null, false, null);
                    }
                    setCompany(resp.data);
                }
            });
        }
    }
    useEffect(() => {
        getComp();
    }, [companyIdNo]);
    const save = () => {
        let _cmp = wgfObjectClone(company);
        _cmp['companyId'] = companyId;
        if (wgfIsNotNull([_cmp['companyId'], _cmp['name'], _cmp['phone'], _cmp['email']], true) === true) {
            lawFetchPost('CustomerCompany/edit', _cmp, null, null, null, () => {
                getComp();
                setEditModalShow(false);
            }, null, true, null);
        }
    }
    useEffect(() => {
        if (companyIdNo) {
            lawFetchGet('customerCompany/get?id=' + companyIdNo, null, setCompany, null, null, null, false, null);
        }
    }, [companyIdNo])
    const handleTabChange = (e, value) => {
        setTabValue(value);
    }
    const handleEditShow = () => {
        setEditModalShow(true);
    }

    return (
        (company) ? (
            <>

                <div class="cmpny-container">
                    <div class="cmpny-header" style={{ backgroundImage: `linear-gradient(180deg, rgba(192, 192, 192, 0.1), rgba(0, 0, 0, 0.9)), url('${sector ? sector.backgroundImage : 'https://cdn.pixabay.com/photo/2018/08/02/16/34/sunrise-3579931_1280.jpg'}')` }}>
                        {
                            (wgfAdminRoleControl()) && (
                                <div class="cmpny-btn-edit">
                                    <IconButton onClick={handleEditShow}>
                                        <Edit />
                                    </IconButton>
                                </div>
                            )
                        }
                        <div class="cmpny-logo">
                            <img src={company.logo ?? defaultLogo} />
                        </div>
                        <span>Tax Nu:{company.taxNumber}</span>
                        <h3>{company.name}</h3>
                        <span>{sector && sector.name}</span>
                        <span>{company.postcode} {company.housenumber} {company.buildingNumber} {company.buildingSuffix} {company.address} {wgfGetCityName(company.cityId)}</span>

                        <div class="cmpny-props">
                            <div class="item">
                                <div>
                                    <Person />
                                </div>
                                <strong>125</strong>
                                <span>Text</span>
                            </div>
                            <div class="item">
                                <div>
                                    <DirectionsCar />
                                </div>
                                <strong>125</strong>
                                <span>Text</span>
                            </div>
                            <div class="item">
                                <div>
                                    <Euro />
                                </div>
                                <strong>125</strong>
                                <span>Text</span>
                            </div>
                            <div class="item">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                    </svg>
                                </div>
                                <strong>125</strong>
                                <span>Text</span>
                            </div>
                            <div class="item">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                    </svg>
                                </div>
                                <strong>125</strong>
                                <span>Text</span>
                            </div>
                        </div>
                    </div>

                </div>
                <Card style={{ minHeight: '70vh', padding: 0, overflow: 'hidden' }}>
                    <div className="notebook-grid pt-5 pb-5">
                        <div className="m-auto" style={{ maxWidth: '1200px' }}>
                            <Grid2 spacing={2} container={true}>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 3 }}>
                                    <div className="jf-center mb-2">
                                        <CalendarMonth sx={{ width: '40px !important', color: 'green' }} />
                                    </div>
                                    <h5 className="text-center">This Month</h5>
                                    <ul>
                                        <li>Gelir: €12345</li>
                                        <li>Gider: €12345</li>
                                        <li>Gider Fatura Adeti: 25</li>
                                        <li>Gelir Fatura Adeti: 25</li>
                                    </ul>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 3 }}>
                                    <div className="jf-center mb-2">
                                        <History sx={{ width: '40px !important', color: 'green' }} />
                                    </div>
                                    <h5 className="text-center">Last Month</h5>
                                    <ul>
                                        <li>Gelir: €12345</li>
                                        <li>Gider: €12345</li>
                                        <li>Gider Fatura Adeti: 25</li>
                                        <li>Gelir Fatura Adeti: 25</li>
                                    </ul>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 3 }}>
                                    <div className="jf-center mb-2">
                                        <Percent sx={{ width: '40px !important', color: 'green' }} />
                                    </div>
                                    <h5 className="text-center">Average</h5>
                                    <ul>
                                        <li>Gelir: €12345</li>
                                        <li>Gider: €12345</li>
                                        <li>Gider Fatura Adeti: 25</li>
                                        <li>Gelir Fatura Adeti: 25</li>
                                    </ul>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 3 }}>
                                    <div className="jf-center mb-2">
                                        <Calculate sx={{ width: '40px !important', color: 'green' }} />
                                    </div>
                                    <h5 className="text-center">Total</h5>
                                    <ul>
                                        <li>Gelir: €12345</li>
                                        <li>Gider: €12345</li>
                                        <li>Gider Fatura Adeti: 25</li>
                                        <li>Gelir Fatura Adeti: 25</li>
                                    </ul>
                                </Grid2>
                            </Grid2>
                        </div>
                    </div>
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                <Tab label="Daily" value="1" />
                                <Tab label="Yearly" value="2" />
                                <Tab label="Invoice Types" value="3" />
                                <Tab label="Sellers" value="4" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <h5>Daily</h5>
                            <ReportDaily />
                        </TabPanel>
                        <TabPanel value="2">
                            <h5>Yearly</h5>
                            <ReportYearly />
                        </TabPanel>
                        <TabPanel value="3">
                            <h5>Invoice Types</h5>
                            <ReportInvoiceTypes />
                        </TabPanel>
                        <TabPanel value="4">
                            <h5>Sellers</h5>
                        </TabPanel>
                    </TabContext>
                </Card>
                {
                    (editModalShow && company) && (
                        <LawModal state={editModalShow} setState={setEditModalShow}>
                            <Box sx={{ maxWidth: '400px' }}>
                                <CompanyFormBaseElements company={company} setCompany={setCompany} />
                                <LawSelect items={lawStatusActivePassive} label={"Status"} state={company} setState={setCompany} fieldName={'status'} />
                                <div className="jf-center mt-3 mb-5">
                                    <Button onClick={save} variant="contained" sx={{ width: '100%', mb: 2, mt: 5 }}>Opslaan</Button>
                                </div>
                            </Box>
                        </LawModal>
                    )
                }
            </>
        ) : (
            <DataNotFound />
        )
    )
}
export default CompanyPage;