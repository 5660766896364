import Grid from "@mui/material/Grid2";
import Card from "../../components/ui/Card";
import { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts";
import CustomerCard from "../../components/data/accountant/CustomerCard";
import { lawFetchGet } from "../../components/functions/lawFetch";
import CompaniesTable from "../../components/data/accountant_customer/company/CompaniesTable";
import { wgfGetQueryStringValue } from "../../components/functions/lawFunctions";
import bannerImage from '../../img/accountant-banner.jpg'
const CustomerView = () => {
    const [customerId] = useState(wgfGetQueryStringValue("customerId"));
    const [customer, setCustomer] = useState({});
    const getCustomer = () => {
        if (customerId) {
            lawFetchGet('customer/get?customerId=' + customerId, null, setCustomer, {}, null, null, false, null);
        }
    }
    useEffect(() => {
        getCustomer();
    }, [customerId])

    return (
        <Card style={{ padding: '0', overflow: 'hidden' }}>
            <CustomerCard editCallback={getCustomer} isAddEditButton={true} customer={customer} />
            {
                /*
                <div style={{ background: '#ddd', backgroundSize:'cover',backgroundPosition:'bottom', backgroundImage:`url(${bannerImage})`,borderBottom:'3px solid #ddd' }}>
                <Card style={{background:'linear-gradient(90deg, rgba(255,255,255,1) 18%, rgba(255,255,255,0) 100%)', backdropFilter:'blur(5px)'}}>
                    <div style={{ width: 'fit-content' }}>
                        <CustomerCard editCallback={getCustomer} isAddEditButton={true} customer={customer} />
                    </div>
                </Card>
            </div>
                */
            }
            <Card>
                <Grid container spacing={0}>
                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6 }} sx={{ p: 3 }}>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        { id: 0, value: 10, label: 'Staus A', color: '#E9EED9' },
                                        { id: 1, value: 15, label: 'Staus B', color: '#CBD2A4' },
                                        { id: 2, value: 20, label: 'Staus C', color: '#9A7E6F' },
                                        { id: 3, value: 20, label: 'Staus D', color: '#54473F' }
                                    ],
                                    innerRadius: 20,
                                    outerRadius: 70,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    cx: 70,
                                    cy: 80,
                                },
                            ]}
                            width={270}
                            height={160}
                        />
                        <h5 className="d-block">Tickets</h5>
                        <span className="d-block">Ticket Count:0</span>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6 }} sx={{ p: 3 }}>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        { id: 0, value: 10, label: 'Staus A', color: '#C3E2C2' },
                                        { id: 1, value: 15, label: 'Staus B', color: '#EAECCC' },
                                        { id: 2, value: 20, label: 'Staus C', color: '#DBCC95' },
                                        { id: 3, value: 20, label: 'Staus D', color: '#CD8D7A' },
                                    ],
                                    innerRadius: 20,
                                    outerRadius: 70,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    cx: 70,
                                    cy: 80,
                                },
                            ]}
                            width={270}
                            height={160}
                        />
                        <h5 className="d-block">Accountant Registers</h5>
                        <span className="d-block">Register count:0</span>
                    </Grid>
                </Grid>
                {
                    (customer && customer.id) && (<CompaniesTable customerId={customer.id} />)
                }
            </Card>
        </Card>
    )
}
export default CustomerView;