import { useEffect, useState } from "react";
import { lawFetchGet } from "../../../functions/lawFetch";
import FaqCreateEdit from "./FaqCreateEdit";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { wgfDateTimeFormat } from "../../../functions/lawFunctions";
import LawButtonGroup from "../../../ui/LawButtonGroup";
import LawDataTable from "../../../ui/LawDataTable";
import LawStatus from "../../../ui/LawStatus";
import LawDeleteButton from "../../../ui/LawDeleteButton";

const TableFaqs = () => {
    const [faqs, setFaqs] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const getAll = () => {
        lawFetchGet('faqs/all', null, setFaqs, [], null, null, false, null);
    }
    useEffect(getAll, [])
    const handleSelect = (id) => {
        setSelectedId(id);
    }
    const handleDelete = (id) => {
        lawFetchGet('faqs/delete?id=' + id, null, null, null, getAll, null, true, null);
    }

    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Question',
            selector: (row) => row.question,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'ActivePassiveDeleted'} />
            )
        },
        {
            name: 'Create',
            selector: (row) => wgfDateTimeFormat(row.createDate, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Update',
            selector: (row) => wgfDateTimeFormat(row.updateDate, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Action',
            width: '120px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <IconButton onClick={() => { handleSelect(row.id) }}>
                        <Edit/>
                    </IconButton>
                    <LawDeleteButton callback={() => { handleDelete(row.id) }} status={row.status} />
                </div>
            ),
        }
    ];

    return (
        <>
            <LawButtonGroup>
                <FaqCreateEdit id={selectedId} successCallback={getAll} setId={setSelectedId} />
            </LawButtonGroup>

            <LawDataTable dataState={faqs} setDataState={setFaqs} columns={columns} searchFields={['id', 'question']} />

        </>
    )
}
export default TableFaqs;