
import { ZoomIn, ZoomOut, ZoomOutMap } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import {
    TransformWrapper,
    TransformComponent,
    useControls,
} from "react-zoom-pan-pinch";

const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
        <div className="tools">
            <IconButton size="small" onClick={() => zoomIn()}>
                <ZoomIn sx={{ color: '#fff' }} />
            </IconButton>
            <IconButton size="small" onClick={() => zoomOut()}>
                <ZoomOut sx={{ color: '#fff' }} />
            </IconButton>
            <IconButton size="small" onClick={() => resetTransform()}>
                <ZoomOutMap sx={{ color: '#fff' }} />
            </IconButton>
        </div>
    );
};

const ImageZoom = ({ src, height }) => {
    return (
        <TransformWrapper
            initialScale={1}
            initialPositionX={200}
            initialPositionY={100}
        >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                    <Controls />
                    <TransformComponent wrapperStyle={{ height:height??'800px', width:'100%'}}>
                        <img src={src} alt="test" style={{ maxWidth: '100%', width: '100%' }} />
                    </TransformComponent>
                </>
            )}
        </TransformWrapper>
    )
}
export default ImageZoom;