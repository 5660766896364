import { Add } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import BoxCenter from "../../../ui/BoxCenter";
import LawModal from "../../../ui/LawModal";
import LawInput from "../../../ui/LawInput";
import { lawFetchPost } from "../../../functions/lawFetch";
import { wgfIsNotNull, wgfObjectClone } from "../../../functions/lawFunctions";

const TicketAddStep = ({ ticketId, callback }) => {
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState({});
    const create = () => {
        let _stp = wgfObjectClone(step);
        console.log(_stp['header'],ticketId);
        if (wgfIsNotNull([_stp['header'],ticketId], true)) {
            _stp['TicketId'] = ticketId;
            lawFetchPost('ticket/add-step', _stp, null, null, null, () => {
                setOpen(false);
                setStep({});
                if (callback) {
                    callback();
                }
            }, null, true, null);
        }
    }
    return (
        <>
            <Button variant="contained" onClick={() => setOpen(true)}><Add /> Status toevoegen</Button>
            {
                (open) && (
                    <LawModal state={open} setState={setOpen}>
                        <LawInput label={"Header*"} propstate={[step, setStep, 'header']} />
                        <LawInput label={"Comment"} propstate={[step, setStep, 'comment']} />
                        {
                            /*<LawSelect label={"Step"} items={lawTicketStepTypes} state={step} setState={setStep} fieldName={'stepType'} /> */
                        }
                        <BoxCenter>
                            <Box>
                                <Button variant="contained" onClick={create} sx={{ width: '100%', mb: 2, mt: 5 }}>Add</Button>
                            </Box>
                        </BoxCenter>
                    </LawModal>
                )
            }
        </>
    )
}
export default TicketAddStep;