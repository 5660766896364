import { Avatar, Box, Button, Grid2 } from "@mui/material"
import BoxCenter from "../../ui/BoxCenter";
import LawUploadFile from "../../ui/LawUploadFile";
import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../functions/lawFetch";
import LawInput from "../../ui/LawInput";
import { lawStatusActivePassive } from "../../lawConstants";
import LawSelect from "../../ui/LawSelect";
import LawCitiesSelect from "../../ui/LawCitiesSelect";
import userIcon from '../../../img/9187604_72.png'
import UserBaseElements from "../admin/users/UserBaseElements";

const CustomerEdit = ({ id, callback }) => {
    const [customer, setCustomer] = useState({ id: id });
    useEffect(() => {
        lawFetchGet('customer/get?customerId=' + id, null, setCustomer, {}, null, null, false, null);
    }, [])
    const updateCustomer = () => {
        lawFetchPost('customer/update', customer, null, null, null, () => {
            if (callback) {
                callback();
            }
        }, null, true, null);
    }
    return (
        <>
            <Grid2 spacing={{ xs: 2 }} container={true}>
                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                    <BoxCenter>
                        <Avatar
                            alt="Remy Sharp"
                            src={userIcon}
                            sx={{ width: 96, height: 96 }}
                        />
                    </BoxCenter>
                    <BoxCenter style={{ marginBottom: '30px', marginTop: '20px' }}>
                        <LawUploadFile />
                    </BoxCenter>
                    <UserBaseElements user={customer} setUser={setCustomer} />
                    <LawInput label={"Postcode"} propstate={[customer, setCustomer, 'postcode']} />
                    <LawInput label={"BuildingNumber"} propstate={[customer, setCustomer, 'buildingNumber']} />
                    <LawInput label={"BuildingSuffix"} propstate={[customer, setCustomer, 'buildingSuffix']} />
                    <LawCitiesSelect state={customer} setState={setCustomer} fieldName={'cityId'} />
                    <LawInput label={"Address"} propstate={[customer, setCustomer, 'address']} />
                    <LawSelect label={"Status"} items={lawStatusActivePassive} state={customer} setState={setCustomer} fieldName={'status'} />
                    <BoxCenter style={{ marginTop: '30px', marginBottom: '30px' }}>
                        <Button onClick={updateCustomer} variant="contained">Kaydet</Button>
                    </BoxCenter>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                    <LawSelect itemsUrl={"app/get-jobs-for-select"} state={customer} setState={setCustomer} fieldName={'jobId'} />
                </Grid2>
            </Grid2>

        </>
    )
}
export default CustomerEdit;