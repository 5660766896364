import { Email } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { wgfChangeState } from "../functions/lawFunctions";
const _compValue = {
    emailRegex: /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:\\[\x00-\x7F]|[\x01-\x09\x0B\x0C\x0E-\x7F])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}|(?:\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z-]*[a-zA-Z]:[^\s]*)\]))$/,
    emailValid: false
}
/*
NL +31999999999
*/
const LawInputEmail = ({ state, setState, fieldName, label }) => {
    const handleChange = (e) => {

        wgfChangeState(e.target.value, fieldName, state, setState);
    }
    let tempVal = (fieldName ? state[fieldName] : state);
    _compValue.emailValid = false;
    if (tempVal) {
        _compValue.emailValid = _compValue.emailRegex.test(tempVal);
    }
    return (
        <TextField
            onChange={handleChange} className='mb-3' value={tempVal}
            label={label ?? 'E-mail'}
            placeholder="____@__._"
            fullWidth
            slotProps={{
                input: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <Email color={(_compValue.emailValid === false ? 'error' : 'warning')} />
                        </InputAdornment>
                    ),
                },
            }}
            size='small'
        />
    )
}
export default LawInputEmail;