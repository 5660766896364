// HideOnScrollNavbar.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Slide, IconButton, useScrollTrigger, Menu, MenuItem, Box, ListItemIcon, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { ArrowBack, Home, Refresh } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { appObjects } from '../../App';
import userIcon from '../../img/9187604_72.png'
import { wgfLogout, wgfRoleControl } from '../functions/lawFunctions';
import { lawRoles } from '../lawConstants';
import NavbarCreateInvoice from '../data/customer/NavbarCreateInvoice';
import NavbarMessages from '../data/app/messages/NavbarMessages';
import NavbarNotifications from '../data/app/notifications/NavbarNotifications';
import LawNavbarCalendar from '../data/app/calendar/LawNavbarCalendar';
import LawNavbarStickyNotes from '../data/app/sticky/LawNavbarStickyNotes';
function HideOnScroll(props) {
  const { children, window } = props;
  // Scroll tetikleyici; sayfa yukarı çıktığında göster, aşağı indiğinde gizle
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 30,  // 30 piksel kayınca devreye girer
  });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const constants = {
  menuPaper: {
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1.5,
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  }
}

const HideOnScrollNavbar = (props) => {
  const navigate = useNavigate();
  const [accountMenu, setAccountMenu] = useState(null);
  const openAccountMenu = Boolean(accountMenu);

  // const openNotificationsMenu = Boolean(notificationsMenu);
  const handleClick = (event, target) => {
    setAccountMenu(event.currentTarget);
  };
  const handleClose = () => {
    setAccountMenu(null);
  }

  const goToBack = () => {
    navigate(-1)
  }

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar
          position="fixed"
          sx={{
            padding: '0px 0px',
            boxShadow: 'none',
            m: 0,
            background: 'transparent',
            width: { lg: `calc(100% - ${props.drawerWidth}px)` },
            ml: { lg: `${props.drawerWidth}px` },
          }}
        >
          <Toolbar sx={{ display: 'block', pt: '10px', width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px 10px', width: '98%', maxWidth: '1500px', borderRadius: '50px', margin: 'auto', backgroundColor: '#ebebeb', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '7px', alignContent: 'center', alignItems: 'center' }}>
                <IconButton
                  color="primary"
                  onClick={goToBack}
                  sx={{ display: { sm: 'none', xs: 'none', md: 'flex' } }}
                >
                  <ArrowBack sx={{ color: '#0b0b55' }} />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => window.location.href = window.location.href}
                  sx={{ display: { sm: 'none', xs: 'none', md: 'flex' } }}
                >
                  <Refresh sx={{ color: '#0b0b55' }} />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => { props.setMobileOpen(!props.mobileOpen) }}
                  sx={{ display: { lg: 'none' }, ml:'5px' }}
                >
                  <MenuIcon sx={{ color: '#0b0b55' }} />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  component={Link}
                  to="/index"
                >
                  <Home sx={{ color: '#0b0b55' }} />
                </IconButton>


              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '7px', alignContent: 'center', alignItems: 'center' }}>

                <LawNavbarStickyNotes />
                <LawNavbarCalendar />
                <NavbarMessages />
                <NavbarNotifications />
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={(e) => handleClick(e, 'accountMenu')}
                    size="small"
                    aria-controls={openAccountMenu ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openAccountMenu ? 'true' : undefined}
                  >
                    <Avatar alt="User Name" src={userIcon} sx={{ width: 32, height: 32 }} />
                  </IconButton>
                </Tooltip>
              </div>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Menu
        anchorEl={accountMenu}
        id="account-menu"
        open={openAccountMenu}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: constants.menuPaper,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {
          (appObjects.values.loginInfo) && (
            <MenuItem onClick={handleClose} component={Link} to="/my-account">
              <Avatar /> {appObjects.values.loginInfo.name} {appObjects.values.loginInfo.surname}
            </MenuItem>
          )
        }
        <Divider />
        <MenuItem onClick={wgfLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      {
        (wgfRoleControl([lawRoles.customer])) && (

          <NavbarCreateInvoice />
        )
      }
    </>
  );
};

export default HideOnScrollNavbar;
