import { useEffect, useState } from "react";
import { lawFetchGet } from "../../../functions/lawFetch";
import { wgfDateTimeFormat, wgfGetQueryStringValue, wgfValueToPriceText } from "../../../functions/lawFunctions";
import { IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import FixedExpenseCreate from "./FixedExpenseCreate";
import LawDataTable from "../../../ui/LawDataTable";
import LawStatus from "../../../ui/LawStatus";

const FixedExpensesTable = ({ companyId }) => {
    const [invoices, setInvoices] = useState([]);
    const [companyIdNo, SetCompanyIdNo] = useState(companyId ?? wgfGetQueryStringValue('companyId'));
    const getAll = () => {
        if (companyIdNo) {
            lawFetchGet('fixedExpense/all?companyId=' + companyIdNo, null, setInvoices, [], null, null, false, null);
        }
    }
    useEffect(getAll, [])
    const handleSelectInvoice = (id) => {
    }
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Title',
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: 'Amount',
            cell: (row) => (
                <span>{wgfValueToPriceText(row.amount)}</span>
            ),
            sortable: true,
        },
        {
            name: 'Periot',
            cell: (row) => (
                <span>{row.paymentPeriot} month</span>
            ),
            sortable: true,
        },
        {
            name: 'Company',
            selector: (row) => row.companyName,
            sortable: true,
        },
        {
            name: 'Date',
            cell: (row) => (
                <span>{wgfDateTimeFormat(row.invoiceDate, 'dd.MM.yyyy')}</span>
            )
        },
        {
            name: 'From-To Date',
            cell: (row) => (
                <span>{(row.fromDate ? wgfDateTimeFormat(row.fromDate, 'dd.MM.yyyy') : '----') + ' / ' + (row.toDate ? wgfDateTimeFormat(row.toDate, 'dd.MM.yyyy') : '----')}</span>
            )
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'ActivePassiveDeleted'} />
            )
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <IconButton onClick={() => handleSelectInvoice(row.id)}>
                        <ChevronRight/>
                    </IconButton>
                </div>
            ),
        }
    ];
    return (
        <>
            {
                (companyIdNo) && (
                    <>
                        <div>
                            <FixedExpenseCreate callback={getAll} companyId={companyIdNo} />
                        </div>
                        <LawDataTable dataState={invoices} setDataState={setInvoices} columns={columns} searchFields={['id', 'title', 'companyName']} />
                    </>
                )
            }
        </>
    )
}
export default FixedExpensesTable;