import { Box, Button } from "@mui/material";
import Card from "../../components/ui/Card";
import BoxCenter from "../../components/ui/BoxCenter";
import { LockReset } from "@mui/icons-material";
import Link2 from "../../components/ui/Link2";
import { useState } from "react";
import LawInput from "../../components/ui/LawInput";
import { wgfIsNotNull } from "../../components/functions/lawFunctions";
import { lawFetchPost } from "../../components/functions/lawFetch";
import { appObjects } from "../../App";

const ForgotPasswordView = () => {
    const [email, setEmail] = useState(null);
    const resetPassword = () => {
        if (wgfIsNotNull([email], true) === true) {
            lawFetchPost('account/forgot-password', { email }, null, null, null, () => {
                setEmail('')
                setTimeout(()=>{
                    appObjects.goTo('/login');
                },3000)
            }, null, true, null);
        }
    }
    return (
        <BoxCenter style={{ height: '100vh', width: '100vw' }}>
            <Box sx={{ maxWidth: '360px', width: '100%' }}>
                <Card style={{ paddingBottom: '40px' }}>
                    <BoxCenter>
                        <LockReset sx={{ fontSize: '80px', color: '#ccc' }} />
                    </BoxCenter>
                    <LawInput label={"E-mail"} propstate={[email, setEmail]} />
                    <BoxCenter>
                        <Button onClick={resetPassword} variant="contained">Wachtwoord Opnieuw Instellen</Button>
                    </BoxCenter>
                    <Link2 to={"/login"} text={"Log in"} style={{ marginTop: '20px' }} />
                </Card>
            </Box>
        </BoxCenter>
    )
}
export default ForgotPasswordView;