import { useEffect, useState } from "react";
import CompanyVehicleCreate from "./CompanyVehicleCreate";
import { lawFetchGet } from "../../../functions/lawFetch";
import { wdfDynamicImportUrlParameter, wgfDateTimeFormat, wgfGetQueryStringValue } from "../../../functions/lawFunctions";
import LawDataTable from "../../../ui/LawDataTable";
import { ChevronRight } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import LawStatus from "../../../ui/LawStatus";
import LawModal from "../../../ui/LawModal";
import CompanyVehicleDetails from "./CompanyVehicleDetails";

const CompanyVehicleTable = ({ customerId, companyId }) => {
    const [companyIdNo,setCompanyIdNo]=useState(companyId??wgfGetQueryStringValue('companyId'));
    const [vehicles, setVehicles] = useState([]);
    const [editModalShow, setEditModalShow] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);

    const getAll = () => {
        let url = wdfDynamicImportUrlParameter(
            'CompanyVehicle/all',
            [
                { default: customerId, key: 'customerId' },
                { default: companyId, key: 'companyId' }
            ]);
        lawFetchGet(url, null, setVehicles, [], null, null, false, null);
    }
    useEffect(getAll, [])
    const handleSelect = (id) => {
        setSelectedVehicle(id);
        setEditModalShow(true);
    }
    const handleEditCallback = () => {
        getAll();
        setEditModalShow(false);
        setSelectedVehicle(null);
    }
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Code',
            selector: (row) => row.code,
            sortable: true,
        },
        {
            name: 'BrandName',
            selector: (row) => row.brandName,
            sortable: true,
        },
        {
            name: 'ModelName',
            selector: (row) => `${row.modelName} / ${row.modelYear ?? '----'}`,
            sortable: true,
        },
        {
            name: 'Km/Hour',
            cell: (row) => (
                <span>{(row.currentHour ? row.currentHour : row.currentKm)}</span>
            )
        }
        ,
        {
            name: 'Create',
            selector: (row) => wgfDateTimeFormat(row.createDate, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => <LawStatus status={row.status} statusGroup={"ActivePassiveDeleted"} />,
            sortable: true,
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <IconButton onClick={() => { handleSelect(row.id) }}>
                        <ChevronRight />
                    </IconButton>
                </div>
            )
        }
    ];

    return (
        <>
            {
                (companyIdNo) && (<CompanyVehicleCreate callback={getAll} companyId={companyIdNo} />)
            }
            <LawDataTable dataState={vehicles} setDataState={setVehicles} columns={columns} searchFields={['code', 'modelName', 'brandName']} />
            {
                (selectedVehicle && editModalShow) && (
                    <LawModal style={{ maxWidth: '1000px' }} state={editModalShow} setState={setEditModalShow}>
                        <CompanyVehicleDetails editCallback={handleEditCallback} vehicleId={selectedVehicle} />
                    </LawModal>
                )
            }
        </>
    )
}
export default CompanyVehicleTable;