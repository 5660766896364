import { Delete } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Slide from '@mui/material/Slide';
import { forwardRef } from "react";
import { appObjects } from "../../App";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const _dialogInitial = {
    title: '',
    description: '',
    yesCallback: () => { },
    noCallback: () => { },
    forDelete:true
}

export const LawDialogYesNo = ({ title, description, yesCallback, noCallback }) => {
    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            TransitionComponent={Transition}
            keepMounted
            onClose={noCallback}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle><Delete /> {title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={yesCallback} color="primary">Ja</Button>
                <Button onClick={noCallback} color="error">Nee</Button>
            </DialogActions>
        </Dialog>
    )
}
export const LawDialogDelete = ({ yesCallback, noCallback }) => {
    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            TransitionComponent={Transition}
            keepMounted
            onClose={noCallback}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle><Delete />De registratie zal worden verwijderd.</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    De door u gekozen registratie zal worden verwijderd. Wilt u doorgaan?
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={yesCallback} color="primary">Ja</Button>
                <Button onClick={noCallback} color="error">Nee</Button>
            </DialogActions>
        </Dialog>
    )
}
export const LawDialog = ({ dialogState, setDialogState }) => {
    if (!appObjects.dialog.yesNo) {
        appObjects.dialog.yesNo = (title, description, yesCallback, noCallback) => {
            setDialogState({
                title: title,
                description: description,
                yesCallback: () => {
                    if (yesCallback) {
                        yesCallback();
                    }
                    setDialogState(null);
                },
                noCallback: () => {
                    if (noCallback) {
                        noCallback();
                    }
                    setDialogState(null);
                },
                forDelete: false
            })
        }
    }
    if (!appObjects.dialog.delete) {
        appObjects.dialog.delete = (yesCallback, noCallback) => {
            setDialogState({
                yesCallback: () => {
                    if (yesCallback) {
                        yesCallback();
                    }
                    setDialogState(null);
                },
                noCallback: () => {
                    if (noCallback) {
                        noCallback();
                    }
                    setDialogState(null);
                },
                forDelete: true
            })
        }
    }
    return (
        (dialogState) && (
            (dialogState.forDelete) ? (
                <LawDialogDelete noCallback={dialogState.noCallback} yesCallback={dialogState.yesCallback} />
            ) : (
                <LawDialogYesNo description={dialogState.description} noCallback={dialogState.noCallback} yesCallback={dialogState.yesCallback} title={dialogState.title} />
            )
        )
    )
}
export default LawDialog;