import { useState } from "react";
import LawInput from "../../../ui/LawInput";
import LawTimePicker from "../../../ui/LawTimePicker";
import { lawFetchPost } from "../../../functions/lawFetch";
import { wgfIsNotNull } from "../../../functions/lawFunctions";
import LawCheckbox from "../../../ui/LawCheckbox";
import LawDatePickerFromAndTo from "../../../ui/LawDatePickerFromAndTo";
import LawCreateDataModal from "../../../ui/LawCreateDataModal";

const CompanyVehicleUsageCreate = ({ vehicleId, callback }) => {
    const [usage, setUsage] = useState({ vehicleId });
    const handleCreate = () => {
        if (wgfIsNotNull([usage['vehicleId']], true) === true) {
            lawFetchPost('CompanyVehicleUsage/create', usage, null, null, null, () => {
                setUsage({ vehicleId });
                if (callback) {
                    callback();
                }
            }, null, true, null);
        }
    }
    return (
        <>
            <LawCreateDataModal createEvent={handleCreate}>
                <LawDatePickerFromAndTo fromState={usage} setFromState={setUsage} fromFieldName={'fromDate'} fromLabel={'From Date'} toState={usage} setToState={setUsage} toFieldName={'toDate'} toLabel={'To Date'} />
                <div className="d-flex align-items-center align-content-center">
                    <div className="p-2">
                        <LawInput propstate={[usage, setUsage, 'usageKm']} label={'Usage Km'} type={'number'} />
                    </div>
                    <div className="p-2">
                        <LawTimePicker time={usage} setTime={setUsage} fieldName={'usageHour'} label={'Usage Hour'} />
                    </div>
                </div>
                <LawCheckbox propstate={[usage, setUsage, 'forBusiness']} label={'For Business'} />
            </LawCreateDataModal>
        </>
    )
}
export default CompanyVehicleUsageCreate;