import { Avatar, Box, Button, IconButton, Tab } from "@mui/material";
import Card from "../../components/ui/Card";
import { Logout } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../components/functions/lawFetch";
import ChangePassword from "../../components/data/admin/users/ChangePassword";
import UserBaseElements from "../../components/data/admin/users/UserBaseElements";
import userIcon from '../../img/9187604_72.png'
import { wgfLogout } from "../../components/functions/lawFunctions";

const MyAccountView = () => {
    const [value, setValue] = useState('1');
    const [myData, setMyData] = useState({});
    useEffect(() => {
        lawFetchGet('/account/my-data', null, setMyData, {}, null, null, false, null);
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const save = () => {
        lawFetchPost('/account/my-data', myData, null, null, null, null, null, true, null);
    }

    return (
        <>
            <Card>
                <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between', mb: 5 }}>
                    <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <Avatar
                            alt="Remy Sharp"
                            src={userIcon}
                            sx={{ width: 56, height: 56, mr: 3 }}
                        />
                        <Box>
                            <Box>
                                <h3 style={{ margin: 0 }}>{myData.name} {myData.surname}</h3>
                                <small>{myData.roleName}</small>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <IconButton onClick={wgfLogout}>
                            <Logout />
                        </IconButton>
                    </Box>
                </Box>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Data" value="1" />
                            <Tab label="Password" value="2" />
                            <Tab label="Two Factor" value="3" />
                            <Tab label="Login Log" value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Box sx={{ maxWidth: '440px' }}>
                            <UserBaseElements user={myData} setUser={setMyData} />
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={save} variant="contained">Change Data</Button>
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <ChangePassword userId={null} isMyPassword={true} />
                    </TabPanel>
                    <TabPanel value="3">
                        <h4>Google Two Factor</h4>
                    </TabPanel>
                    <TabPanel value="4">
                        <h4>Login logs</h4>
                    </TabPanel>
                </TabContext>
            </Card>
        </>
    )
}
export default MyAccountView;