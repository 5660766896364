import { Search } from "@mui/icons-material";
import BoxCenter from "./BoxCenter";
import { Box, Typography } from "@mui/material";

const DataNotFound = ({ color }) => {
    return (
        <>
            <Box className="law-data-not-found" sx={{ marginBottom: 10, marginTop: '40px' }}>
                <BoxCenter>
                    <Search style={{ width:'50px', color: (color ?? '#999999') }} />
                </BoxCenter>
                <Typography sx={{ textAlign: 'center', color: (color ?? '#999999') }} fontSize={22}>Gegevens niet gevonden</Typography>
            </Box>
        </>
    )
}
export default DataNotFound;
