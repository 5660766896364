import { IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { lawFetchGet } from "../../../functions/lawFetch";
import { ChevronRight } from "@mui/icons-material";
import { wgfDateTimeFormat, wgfTextCropAndDots } from "../../../functions/lawFunctions";
import LawModal from "../../../ui/LawModal";
import Ticket from "./Ticket";
import TicketCreate from "./TicketCreate";
import LawButtonGroup from "../../../ui/LawButtonGroup";
import LawDataTable from "../../../ui/LawDataTable";
import LawStatus from "../../../ui/LawStatus";

const TableTickets = () => {
    const [tickets, setTickets] = useState([]);
    const [ticketModalShow, setTicketModalShow] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const handleSelectTicket = (id) => {
        setSelectedTicket(id);
        setTicketModalShow(true);
    }
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Created By',
            selector: (row) => row.createdByUserName,
            sortable: true,
        },
        {
            name: 'Target',
            cell: (row) => (
                <Tooltip title={row.targetUsers}>
                    <span>{wgfTextCropAndDots(row.targetUsers, 24)}</span>
                </Tooltip>
            ),
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'ActivePassiveDeleted'} />
            )
        },
        {
            name: 'Create',
            selector: (row) => wgfDateTimeFormat(row.data, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <IconButton onClick={() => { handleSelectTicket(row.id) }}>
                        <ChevronRight/>
                    </IconButton>
                </div>
            ),
        }
    ];

    const getAll = () => {
        lawFetchGet('ticket/all-for-table', null, setTickets, [], null, null, null, null);
    }

    useEffect(getAll, [])
    return (
        <>
            <LawButtonGroup>
                <TicketCreate callback={getAll} />
            </LawButtonGroup>
            <LawDataTable columns={columns} dataState={tickets} setDataState={setTickets} searchFields={['id', 'name', 'createdByUserName', 'targetUsers']} />
            {
                (selectedTicket && ticketModalShow) && (
                    <LawModal style={{ maxWidth: '1300px' }} state={ticketModalShow} setState={setTicketModalShow} >
                        <Ticket id={selectedTicket} removeCallback={getAll} />
                    </LawModal>
                )
            }
        </>
    )
}
export default TableTickets;

