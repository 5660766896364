import { Check, CheckCircle, Edit, EditNote, EventNote, FilterAlt } from "@mui/icons-material";
import { Avatar, Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import { wgfDateTimeFormat, wgfObjectClone, wgfObjectFieldsAddUrlParams } from "../../../functions/lawFunctions";
import LawModal from "../../../ui/LawModal";
import LawInput from "../../../ui/LawInput";
import BoxCenter from "../../../ui/BoxCenter";
import LawCheckbox from "../../../ui/LawCheckbox";
import _default from "@emotion/styled";
import LawDatePickerFromAndTo from "../../../ui/LawDatePickerFromAndTo";
const _todoListValues = {
    skip: 0
}
const TodoList = () => {
    const [todoList, setTodoList] = useState([]);
    const [users, setUsers] = useState([]);
    const [newTodoModalShow, setNewTodoModalShow] = useState(false);
    const [filterTodoModalShow, setFilterTodoModalShow] = useState(false);
    const [filter, setFilter] = useState({ isOnlyUnfinished: false });
    const [moreButtonShow, setMoreButtonShow] = useState(true);
    const [newTodo, setNewTodo] = useState({});
    const [isShare, setIsShare] = useState(false);
    const [successTodos, setSuccessTodos] = useState([]);
    useEffect(() => {
        getAll();
    }, [])
    useEffect(() => {
        if (isShare) {
            lawFetchGet('account/getall-for-select-list', null, setUsers, [], null, null, false, null);
        }
    }, [isShare])
    const getAll = () => {
        setFilterTodoModalShow(false);
        let _url = wgfObjectFieldsAddUrlParams(filter, `TodoList/all`, [`skip=${_todoListValues.skip}`]);
        lawFetchGet(_url, null, null, null, null, null, false, (resp) => {
            if (resp && resp.data && resp.data.length > 0) {
                let _list = wgfObjectClone(todoList);
                _list.push(...resp.data);
                setTodoList(_list);
            }
            else {
                setMoreButtonShow(false);
            }
        });
    }
    const handleComplete = (id) => {
        lawFetchGet('TodoList/complete?id=' + id, null, null, null, () => {
            setTodoList(todoList.map(item => {
                if (item.id === id) {
                    item.status = 100;
                }
                return item;
            }))
        }, null, true, null)
    }
    const handleCreate = () => {
        let _params = wgfObjectClone(newTodo);
        _params['ShareUsersId'] = users.filter(c => c.selected === true).map(item => item.id).join(',');
        lawFetchPost('TodoList/create', _params, null, null, null, null, null, true, (resp) => {
            setMoreButtonShow(true);
            setNewTodoModalShow(false);
            setNewTodo({});
            if (resp && resp.data) {
                let _t = wgfObjectClone(todoList);
                _t.unshift(resp.data);
                setTodoList(_t);
            }
        })
    }
    useEffect(() => {
        if (todoList) {
            let _groups = todoList.filter(f => f.status === 100).reduce(function (r, a) {
                let _dt = wgfDateTimeFormat(a.date, 'dd.MM.yyyy');
                r[_dt] = r[_dt] || [];
                r[_dt].push(a);
                return r;
            }, {});
            setSuccessTodos(Object.keys(_groups).map(date => ({ date, items: _groups[date] })));
        }
    }, [todoList])
    const handleChangeUser = (e, id) => {
        let _data = wgfObjectClone(users);
        _data = _data.map(item => {
            if (item.id === id) {
                item.selected = e.target.checked
            }
            return item;
        })
        setUsers(_data);
    };
    const handleMore = () => {
        _todoListValues.skip++;
        getAll();
    }
    const handleFilter = () => {
        setTodoList([]);
        setMoreButtonShow(true);
        _todoListValues.skip = 0;
        getAll();
    }
    return (
        <>
            <Box sx={{ display: 'flex', width: '100%', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <h5>Todo-lijst</h5>
                </Box>
                <Box>
                    <IconButton aria-label="delete" onClick={() => setFilterTodoModalShow(true)}>
                        <FilterAlt />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => setNewTodoModalShow(true)}>
                        <Edit />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ maxHeight: '540px', overflow: 'auto' }}>
                {
                    (todoList && todoList.length > 0) ? (
                        <>
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                {
                                    todoList.filter(f => f.status === 1).map((item) => {
                                        return (
                                            <ListItem
                                                key={item.id}
                                                secondaryAction={
                                                    <IconButton onClick={() => handleComplete(item.id)} edge="end" aria-label="delete">
                                                        <CheckCircle />
                                                    </IconButton>
                                                }
                                                sx={{ alignContent: 'start', alignItems: 'start' }}
                                            >
                                                <ListItemAvatar component={IconButton} sx={{ cursor: 'pointer', alignContent: 'start', alignItems: 'start' }}>
                                                    <Avatar>
                                                        <EventNote />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={item.header}
                                                    secondary={(item.comment + ' | ' + wgfDateTimeFormat(item.date, 'dd.MM.yyy'))}
                                                />
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                            {
                                (successTodos && successTodos.length > 0) && (
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                        {
                                            (
                                                successTodos.map((item, index) => {
                                                    return (
                                                        <div key={('todos-success-'+index)}>
                                                             <Divider textAlign="right">{item.date}</Divider>
                                                            {
                                                                item.items.map((itemi) => {
                                                                    return (
                                                                        <ListItem
                                                                            key={itemi.id}
                                                                        >
                                                                            <ListItemAvatar component={IconButton} sx={{ cursor: 'pointer', alignContent: 'start', alignItems: 'start' }}>
                                                                                <Avatar>
                                                                                    <Check />
                                                                                </Avatar>
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                primary={itemi.header}
                                                                                secondary={(itemi.comment + ' | ' + wgfDateTimeFormat(itemi.date, 'dd.MM.yyy'))}
                                                                            />
                                                                        </ListItem>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </List>
                                )
                            }


                        </>
                    ) : (
                        <BoxCenter style={{ minHeight: '500px' }}>
                            <Box>
                                <BoxCenter>
                                    <EditNote className="text-muted" sx={{ fontSize: '70px' }} />
                                </BoxCenter>
                                <h5 className="text-center text-muted">U kunt een takenlijst maken en deze delen met andere gebruikers.</h5>
                                <BoxCenter>
                                    <Button variant="outlined" onClick={() => setNewTodoModalShow(true)}>
                                        Creëren
                                    </Button>
                                </BoxCenter>
                            </Box>
                        </BoxCenter>
                    )
                }
            </Box>
            {
                (moreButtonShow) && (
                    <BoxCenter style={{ marginTop: '20px' }}>
                        <Button variant="outlined" onClick={handleMore}>Meer</Button>
                    </BoxCenter>
                )
            }
            {
                (newTodoModalShow) && (
                    <LawModal state={newTodoModalShow} setState={setNewTodoModalShow}>
                        <LawInput label={"Hader"} propstate={[newTodo, setNewTodo, 'Header']} />
                        <LawInput label={"Comment"} propstate={[newTodo, setNewTodo, 'Comment']} />
                        <FormGroup>
                            <FormControlLabel control={<Checkbox onChange={(e) => setIsShare(e.target.checked)} />} label="Share" />
                        </FormGroup>
                        {
                            (isShare) && (
                                <Box sx={{ maxHeight: '200px', overflow: 'auto', paddingLeft: '20px' }}>
                                    <FormGroup>
                                        {
                                            (users && users.length > 0) && (users.map((item, index) => {
                                                return (<FormControlLabel key={index} control={<Checkbox onChange={(e) => { handleChangeUser(e, item.id) }} />} label={item.name} />)
                                            }))
                                        }
                                    </FormGroup>
                                </Box>
                            )
                        }
                        <BoxCenter>
                            <Button onClick={handleCreate} variant="contained">Create</Button>
                        </BoxCenter>
                    </LawModal>
                )
            }
            {
                (filterTodoModalShow) && (
                    <LawModal state={filterTodoModalShow} setState={setFilterTodoModalShow}>
                        <LawDatePickerFromAndTo fromState={filter} setFromState={setFilter} fromFieldName={'startDate'} fromLabel={'Startdatum'} toState={filter} setToState={setFilter} toFieldName={'endDate'} toLabel={'Startdatum'}/>
                        <LawCheckbox label={"Voltooide"} propstate={[filter, setFilter, 'isOnlyUnfinished']} />
                        <BoxCenter>
                            <Button onClick={handleFilter} variant="contained">Filter</Button>
                        </BoxCenter>
                    </LawModal>
                )
            }
        </>
    )
}
export default TodoList;