import { useState } from "react";
import CompanyVehicleFormBase from "./CompanyVehicleFormBase";
import { wgfIsNotNull } from "../../../functions/lawFunctions";
import { lawFetchPost } from "../../../functions/lawFetch";
import LawCreateDataModal from "../../../ui/LawCreateDataModal";

const CompanyVehicleCreate = ({ companyId, callback }) => {
    const [vehicle, setVehicle] = useState({ companyId });
    const handleNew = () => {
        setVehicle({ companyId });
    }
    const handleCreate = () => {
        if (wgfIsNotNull([vehicle['code'], vehicle['companyId']], true) === true) {
            lawFetchPost('companyVehicle/create', vehicle, null, null, null, () => {
                setVehicle({ companyId });
                if (callback) {
                    callback();
                }
            }, null, true, null)
        }
    }
    return (
        <>
            <LawCreateDataModal createEvent={handleCreate} modalShowEvent={handleNew}>
                <CompanyVehicleFormBase vehicleState={vehicle} setVehicleState={setVehicle} />
            </LawCreateDataModal>
        </>
    )
}
export default CompanyVehicleCreate;