import LawDatePicker from "./LawDatePicker"

const LawDatePickerFromAndTo = ({ fromState, setFromState, fromFieldName, fromLabel, toState, setToState, isSetAutoDate, toFieldName, toLabel }) => {
    return (
        <div className="d-flex justify-content-end">
            <div className="pe-2"  style={{maxWidth:'200px'}}>
                <LawDatePicker state={fromState} setState={setFromState} isSetAutoDate={isSetAutoDate ?? false} fieldName={fromFieldName} label={fromLabel} />
            </div>
            <div className="ps-2" style={{maxWidth:'200px'}}>
                <LawDatePicker state={toState} setState={setToState} isSetAutoDate={isSetAutoDate ?? false} fieldName={toFieldName} label={toLabel} />
            </div>
        </div>
    )
}
export default LawDatePickerFromAndTo;