import { useEffect, useLayoutEffect, useState } from "react";
import { lawFetchGet } from "../../functions/lawFetch";
import { Article, Save } from "@mui/icons-material";
import { wdfDynamicImportUrlParameter, wgfDateTimeFormat } from "../../functions/lawFunctions";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import LawModal from "../../ui/LawModal";
import CreateInvoice from "./CreateInvoice";
import ImageZoom from "../../ui/ImageZoom";
import { appObjects } from "../../../App";
import PdfViewer from "../../ui/PdfViewer";
import LawDataTable from "../../ui/LawDataTable";
import LawStatus from "../../ui/LawStatus";

const _compValues = {
    viewerType: null
}
const OpenInvoicesTable = ({ customerId, companyId }) => {
    const [invoices, setInvoices] = useState([]);
    const [invoice, setInvoice] = useState(null);
    const [createModalShow, setCreateModalShow] = useState(false);
    const [width, setWidth] = useState(0);
    useLayoutEffect(() => {

        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        handleResize(); // Initialize width
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const getAll = () => {
        let url = wdfDynamicImportUrlParameter(
            'CustomerPostInvoices/all',
            [
                { default: customerId, key: 'customerId' },
                { default: companyId, key: 'companyId' }
            ]);
        lawFetchGet(url, null, setInvoices, [], null, null, false, null)
    };
    useEffect(getAll, [])
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Priority',
            selector: (row) => row.priority,
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: (row) => row.customerName,
            sortable: true,
        },
        {
            name: 'Company Name',
            selector: (row) => row.companyName,
            sortable: true,
        },
        {
            name: 'Create',
            selector: (row) => wgfDateTimeFormat(row.createDate, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => <LawStatus status={row.status} statusGroup={"CustomerPostInvoiceStatus"} />,
            sortable: true,
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <IconButton onClick={() => { handleSelectInvoice(row.id) }}>
                        <Save />
                    </IconButton>
                </div>
            )
        }
    ];

    const handleSelectInvoice = (id) => {
        //Secilen fatura baska kullancilardan hemen silinmeli
        if (id) {
            lawFetchGet('CustomerPostInvoices/get?id=' + id, null, null, null, null, null, false, (resp) => {
                if (resp && resp.data) {

                    if (resp.data['fileName']) {
                        if (resp.data['fileName'].includes('.pdf')) {
                            _compValues.viewerType = 'pdf';
                        } else if (resp.data['fileName'].includes('.docx')) {
                            _compValues.viewerType = 'word';
                        }
                        else {
                            _compValues.viewerType = 'image';
                        }
                    }
                    setInvoice(resp.data);

                }
            });
            setCreateModalShow(true);
        }
    }

    const handleClose = () => {
        //Kayit yapmadan cikis yaparsa ilgili Invoice tekrar baskalarina gosterilecek sekilde duzenle
        if (invoice && invoice.id) {
            lawFetchGet('customerPostInvoices/close?id=' + invoice.id, null, null, null, null, null, false, null);
        }
        setInvoice(null);
    }
    const handleCreateInvoiceCallback = () => {
        getAll();
        setInvoice(null);
        setCreateModalShow(false);
    }

    return (
        <>
            <LawDataTable isAddCheckbox={true} dataState={invoices} setDataState={setInvoices} filterController={'CustomerPostInvoices'} columns={columns} searchFields={['id', 'customerName', 'companyName']} />
            {
                (createModalShow && invoice) && (
                    <LawModal style={{ maxWidth: '100%', width: '100%', maxHeight: '100vh' }} state={createModalShow} setState={setCreateModalShow} closeCallback={handleClose}>
                        <div className="grd2-container">
                            <div className="item-1">
                                {
                                    (invoice.description) && (
                                        <div style={{ display: 'block', width: '96%', margin: 'auto', padding: '10px', margin: '30px auto' }}>
                                            <Alert severity="warning">
                                                <AlertTitle>
                                                    {invoice.description}
                                                </AlertTitle>
                                                {wgfDateTimeFormat(invoice.createDate, 'dd.MM.yyyy HH:mm')}
                                            </Alert>
                                        </div>
                                    )
                                }
                                {
                                    (_compValues.viewerType === 'pdf' ? (
                                        <PdfViewer height={(width > appObjects.values.createInvoiceBreakWidth ? 'calc(100vh - 120px)' : '30vh')} url={appObjects.values.domain + '/' + invoice.filePath + '/' + invoice.fileName} />
                                    ) : (_compValues.viewerType === 'word' ? (
                                        <div>Word Viewer</div>
                                    ) : (
                                        <ImageZoom height={(width > appObjects.values.createInvoiceBreakWidth ? 'calc(100vh - 120px)' : '30vh')} src={appObjects.values.domain + '/' + invoice.filePath + '/' + invoice.fileName} />
                                    )))
                                }
                                {
                                    /*FileType controlu yaptir */
                                }
                            </div>
                            <div className="item-2">
                                <CreateInvoice postInvoiceId={invoice.id} callback={handleCreateInvoiceCallback} />
                            </div>
                        </div>
                    </LawModal>
                )
            }
        </>
    )
}
export default OpenInvoicesTable;