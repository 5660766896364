import { Box, Button} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { CheckCircleOutline } from "@mui/icons-material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { wgfAdminRoleControl, wgfIsNotNull, wgfLogout, wgfSetLocalStorage, wgfSetRoleGroups } from "../../components/functions/lawFunctions";
import { urlJoinDomain } from "../../components/functions/lawFetch";
import { appObjects } from "../../App";
import image1 from "../../img/image-1_600.png";
import { wgSnackbarTypes } from "../../components/lawConstants";
import ChangePortal from "../../components/ui/ChangePortal";
import LawInputEmail from "../../components/ui/LawInputEmail";
import LawInputPassword from "../../components/ui/LawInputPassword";

const LoginView = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [chnagePortalShow, setChnagePortalShow] = useState(false);
    const login = () => {
        if (wgfIsNotNull([email, password]) === true) {
            let _config = {
                method: "POST",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                },
                timeout: 10000,
                body: JSON.stringify({ email: email, password: password, rememberMe: false })
            };
            fetch(urlJoinDomain('login/login'), _config).then(resp => resp.json()).then(data => {
                setPassword('');
                if (data && data.data && data.success === true) {
                    wgfSetLocalStorage("auth-token", data.data.loginToken); //bu kullanılmıyorsa sil
                    wgfSetLocalStorage("login-info", JSON.stringify(data.data));
                    wgfSetRoleGroups();
                    //AAA setEmail('');
                    //lawFetchGet('app/get-sidebar-items-for-routes', null, setSidebarItems, [], null, null, false, null);
                    appObjects.values.loginInfo = data.data;
                    appObjects.snackbar("Welcome", 'info');
                    if (wgfAdminRoleControl()) {
                        setChnagePortalShow(true);
                    }
                    else {
                        appObjects.getAppDatas();
                        //AAA appObjects.goTo('/index');
                    }
                }
                else {
                    appObjects.snackbar('Inloggen lukte niet, controleer uw gegevens!', wgSnackbarTypes.error);
                }
            })
        }
        else {
            appObjects.snackbar('U moet uw gegevens invoeren om in te loggen.', wgSnackbarTypes.error);
        }
    }
    return (
        <>
            <Grid container sx={{ minHeight: '100vh', width: '100vw', p: 0, m: 0 }} spacing={0}>
                {
                    /*sx={{ p: 0, m: 0, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: 'url(https://img.freepik.com/free-vector/gradient-ui-ux-background-illustrated_23-2149050183.jpg?t=st=1730814609~exp=1730818209~hmac=41eb99f144a8fcf15aeb625f916709dfec37459f945c5a7db53c2824ad4dfcf1&w=1380)' }} */
                }
                <Grid size={{ xs: 12, sm: 6, md: 8, lg: 9 }} >
                    <Box sx={{ display: 'flex', minHeight: '50vh', padding: '70px 20px', alignContent: 'center', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <div className="props-list-container">
                            <div className="d-flex justify-content-center">
                                <img className="jf-mobile-hide" src={image1} alt="" />
                            </div>
                            <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                            <ul>
                                <li><CheckCircleOutline sx={{ fontSize: '24px', color: 'orange' }} /> <span>Property text </span></li>
                                <li><CheckCircleOutline sx={{ fontSize: '24px', color: 'orange' }} /> <span>Property text </span></li>
                                <li><CheckCircleOutline sx={{ fontSize: '24px', color: 'orange' }} /> <span>Property text </span></li>
                            </ul>
                        </div>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} sx={{ m: 0, background: '#fff', p: 0 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 7 }}>
                        <Box sx={{ maxWidth: '460px', width: '100%' }}>
                            <h1 style={{ textAlign: 'center' }}>Logo</h1>
                            <h4 style={{ textAlign: 'center' }}>Welkom</h4>
                            <Box sx={{ pl: 3, pr: 3, pt:7 }}>
                                 <LawInputEmail state={email} setState={setEmail} label={'E-mail*'}/>   
                                 <LawInputPassword state={password} setState={setPassword} label={'Wachtwoord*'}/>
                            </Box>
                            <Link to="/forgot-password" style={{ display: 'block', textAlign: 'center', width: '100%', marginBottom: '20px' }}>Forgot password</Link>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
                                <Button variant="contained" onClick={login}>Log in</Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <ChangePortal showState={chnagePortalShow} setShowState={setChnagePortalShow} closeCallback={wgfLogout} />
        </>
    )
}
export default LoginView;