import { useState } from "react";
import { appObjects } from "../../../App";
import LawSelect from "../../ui/LawSelect";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { CloudUpload } from "@mui/icons-material";
import { wgfDateTimeFormat, wgfGetQueryStringValue, wgfIsNotNull, wgfObjectClone } from "../../functions/lawFunctions";
import Resizer from "react-image-file-resizer";
import { lawFetchPost } from "../../functions/lawFetch";
import LawTextarea from "../../ui/LawTextarea";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            2000,
            2000,
            "JPEG",
            80,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });
const CreateInvoice = ({ callback }) => {
    const [invoice, setInvoice] = useState({companyId:wgfGetQueryStringValue('companyId')});
    const handlePost = () => {
        if (wgfIsNotNull([invoice['companyId'], invoice['fileBase64']], true) === true) {
            lawFetchPost('CustomerPostInvoices/create', invoice, null, null, null, () => {
                setInvoice({});
                if (callback) {
                    callback();
                }

            }, null, true, null)
        }
    }
    const imageControl = (fileName) => {
        if (fileName) {
            return fileName.includes('.png') || fileName.includes('.jpg') || fileName.includes('.bmp')
        }
        return false;
    }
    const handleSelectFile = async (e) => {
        let file = e.target.files ? e.target.files[0] : null;
        if (file) {
            let _invoice = wgfObjectClone(invoice);
            _invoice['fileName'] = file.name;
            _invoice['fileDate'] = file.lastModifiedDate ? wgfDateTimeFormat(file.lastModifiedDate, 'yyyy-MM-dd HH:mm') : '';
            if (imageControl(file.name)) {
                const image = await resizeFile(file);
                _invoice['fileBase64'] = image;
            }
            else {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => _invoice['fileBase64'] = reader.result;
                reader.onerror = (error) => console.error('Base64 dönüşüm hatası:', error);
            }
            setInvoice(_invoice);
        }
    }

    return (
        <>

            <LawSelect label={"Company"} itemsUrl={'customerCompany/get-for-select?customerId=' + appObjects.values.loginInfo.id} fieldName={'companyId'} state={invoice} setState={setInvoice} />
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
            >
                Factuur uploaden
                <VisuallyHiddenInput
                    type="file"
                    onChange={handleSelectFile}
                />
            </Button>
            {
                (invoice && invoice['fileBase64']) && (
                    <>
                        {
                            (imageControl(invoice['fileName'])) && (
                                <img src={invoice['fileBase64']} className="d-bloc w-100" />
                            )
                        }
                        <LawTextarea label={'Description'} propstate={[invoice, setInvoice, 'description']} />
                        <div className="jf-center mt-5 mb-5">
                            <Button onClick={handlePost} variant="contained">Opslaan</Button>
                        </div>
                    </>

                )
            }
        </>
    )
}
export default CreateInvoice;