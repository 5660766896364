import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { appObjects } from '../../App';
const LayoutHome = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();
     //useEffect(wgfLoginControl, [])
    appObjects.goTo = (url, callback) => {
        navigate(url);
        if (callback) {
            callback();
        }
        if (mobileOpen === true) {
            setMobileOpen(false);
        }
        /*
        >Eğer function kullanmayacaksan sunlari da kullanabilirsin
        <Button component={Link} to="/messages">Messages</Button>
        <Link to="/..."></Link>
        > ortak function calistirmak icin bu yontemi kullandim
        onClick={() => appObjects.goTo('/messages')}
        */
    }
    return (
        <>
            <Outlet />
        </>
    );
};

export default LayoutHome;
