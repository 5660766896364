import UsersView from "./admin/UsersView";
import UserView from "./admin/UserView";
import SettingsView from "./admin/SettingsView";
import InvoicesView from "./accountant/InvoicesView";
import CreateInvoiceView from "./accountant/CreateInvoiceView";
import CompanyView from "./accountant/CompanyView";
import EmployeesView from "./accountant/EmployeesView";
import CustomersView from "./accountant/CustomersView";
import CustomerView from "./accountant/CustomerView";
import { appObjects, ContxAppData } from "../App";
import { Route, Routes } from "react-router-dom";
import LayoutHome from "./layouts/LayoutHome";
import LoginView from "./home/LoginView";
import ForgotPasswordView from "./home/ForgotPasswordView";
import LayoutAccount from "./layouts/LayoutAccount";
import IndexView from "./app/IndexView";
import TicketsView from "./app/TicketsView";
import MyAccountView from "./app/MyAccountView";
import MessagesView from "./app/MessagesView";
import NotificationsView from "./app/NotificationsView";
import SupportView from "./app/SopportView";
import PageNotFoundView from "./app/PageNotFoundView";
import { useContext, useEffect, useState } from "react";
import CustomerReportView from "./customer/CustomerReportView";
import CustomerInvoicesView from "./customer/CustomerInvoicesView";
import CustomerCreateInvoiceView from "./customer/CustomerCreateInvoiceView";
import CustomerEmployeesView from "./customer/CustomerEmployeesView";
import CustomerCompaniesView from "./customer/CustomerCompaniesView";
import CustomerCompanyView from "./customer/CustomerCompanyView";
import CompanyVehiclesView from "./customer/CompanyVehiclesView";
import CustomerPaymentsView from "./customer/CustomerPaymentsView";
import CustomerFixedExpensesView from "./customer/CustomerFixedExpensesView";
import FixedExpensesView from "./accountant/FixedExpensesView";
/*
NOT: Eger farklio portallardaki View'lere ayni URL'den erisilecekse Identity'leri ayni olmali  
*/
export const portalRoutes = {
    Admin: [
        { path: 'users', identity: 'UsersView', title: 'Users', isCopyParentParams: false, element: <UsersView /> },
        { path: 'user', identity: 'UserView', title: 'User', isCopyParentParams: false, element: <UserView /> },
        { path: 'settings', identity: 'SettingsView', title: 'Settings', isCopyParentParams: false, element: <SettingsView /> }
    ],
    Accountant: [
        { path: 'customers', identity: 'CustomersView', title: 'Customers', isCopyParentParams: false, element: <CustomersView /> },
        { path: 'customer', identity: 'CustomerView', title: 'Customer', isCopyParentParams: false, element: <CustomerView /> },
        { path: 'company', identity: 'CompanyView', title: 'Company', isCopyParentParams: false, element: <CompanyView /> },
        { path: 'employees', identity: 'EmployeesView', title: 'Employees', isCopyParentParams: true, element: <EmployeesView /> },
        { path: 'create-invoice', identity: 'CreateInvoiceView', title: 'Create Invoice', isCopyParentParams: false, element: <CreateInvoiceView /> },
        { path: 'invoices', identity: 'InvoicesView', title: 'Invoices', isCopyParentParams: true, element: <InvoicesView /> },
        { path: 'vehicles', identity: 'VehiclesView', title: 'Vehicles', isCopyParentParams: true, element: <CompanyVehiclesView /> },
        { path: 'fixed-expenses', identity: 'FixedExpensesView', title: 'Fixed Exepenses', isCopyParentParams: true, element: <FixedExpensesView /> },
    ],
    Customer: [
        { path: 'invoices', identity: 'InvoicesView', title: 'Invoices', isCopyParentParams: true, element: <CustomerInvoicesView /> },
        { path: 'report', identity: 'ReportView', title: 'Report', isCopyParentParams: true, element: <CustomerReportView /> },
        { path: 'create-invoice', identity: 'CreateInvoiceView', title: 'Create Invoice', isCopyParentParams: false, element: <CustomerCreateInvoiceView /> },
        { path: 'employees', identity: 'EmployeesView', title: 'Employees', isCopyParentParams: true, element: <CustomerEmployeesView /> },
        { path: 'companies', identity: 'CompaniesView', title: 'Companies', isCopyParentParams: false, element: <CustomerCompaniesView /> },
        { path: 'company', identity: 'CompanyView', title: 'Company', isCopyParentParams: false, element: <CustomerCompanyView /> },
        { path: 'vehicles', identity: 'VehiclesView', title: 'Vehicles', isCopyParentParams: true, element: <CompanyVehiclesView /> },
        { path: 'payments', identity: 'PaymentsView', title: 'Payments', isCopyParentParams: true, element: <CustomerPaymentsView /> },
        { path: 'fixed-expenses', identity: 'FixedExpensesView', title: 'Fixed Expenses', isCopyParentParams: true, element: <CustomerFixedExpensesView /> }
    ]
};
export const portalRouteIsCopyParentParams = (identity, portal) => {
    if (identity && portal) {
        let f = portalRoutes[portal].find(f => f.identity === identity);
        if (f) {
            return f.isCopyParentParams;
        }
    }
    return false;
}
export const getPortalRoutes = (portal) => {
    return portal ? (portalRoutes[portal] ?? []) : [];
}
export const getPortalRoutesForSelect = (portal) => {
    return portalRoutes[portal] ? portalRoutes[portal].map(item => ({ value: item.identity, text: item.title })) : [];
}

const LawRoutes = () => {
    const { appDatas } = useContext(ContxAppData);
    const [reRender, setReRender] = useState(0);
    useEffect(() => {
        setReRender(reRender + 1);
    }, [appDatas])
    const createPortalRoutes = () => {
        if (appObjects.values.loginInfo && appDatas && appDatas.sidebarItems) {
            return appDatas.sidebarItems.filter(f => f.portal === appObjects.values.loginInfo.portal).map((item, index) => {
                let rt = portalRoutes[appObjects.values.loginInfo.portal].find(f => f.identity === item.viewIdentity);
                if (rt) {
                    return <Route key={'rt' + index} path={item.url} element={rt.element} />
                }
                return null;
            })
        }
        return null;
    }
    return (
        <Routes key={reRender}>
            <Route path='/' element={<LayoutHome />}>
                <Route exact path="/" element={<LoginView />} />
                <Route path="/login" element={<LoginView />} />
                <Route path="/forgot-password" element={<ForgotPasswordView />} />
            </Route>
            <Route path='/' element={<LayoutAccount />}>
                <Route path="/index" element={<IndexView />} />
                <Route path="/tickets" element={<TicketsView />} />
                <Route path="/my-account" element={<MyAccountView />} />
                <Route path="/messages" element={<MessagesView />} />
                <Route path="/notifications" element={<NotificationsView />} />
                <Route path="/support" element={<SupportView />} />
                {createPortalRoutes()}
            </Route>
            <Route path="*" element={<PageNotFoundView />} />
        </Routes>
    )
}
export default LawRoutes;