import CompanyVehicleTable from "../../components/data/accountant_customer/vehicle/CompanyVehicleTable";
import Card from "../../components/ui/Card";

const CompanyVehiclesView = () => {
    return (
        <Card>
            <CompanyVehicleTable />
        </Card>
    )
}
export default CompanyVehiclesView;