import { Button, List, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Card from "../../components/ui/Card";
import { QuestionMark } from "@mui/icons-material";
import BoxCenter from "../../components/ui/BoxCenter";
import messageImage from "../../img/message-icon.png"
import { useEffect, useRef, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../components/functions/lawFetch";
import { wgfAdminRoleControl, wgfIsNotNull } from "../../components/functions/lawFunctions";

const SupportView = () => {
    const [faqs, setFaqs] = useState([]);
    const [faq, setFaq] = useState(null);
    const faqContentRef = useRef(null);
    const faqQuestionRef = useRef(null);
    const [message, setMessage] = useState('');
    useEffect(() => {
        lawFetchGet('faqs/all', null, setFaqs, [], null, null, false, null);
    }, [])
    const selectedFaq = (id) => {
        if (id) {
            lawFetchGet('faqs/get?id=' + id, null, null, null, null, null, false, (resp) => {
                if (resp) {
                    setFaq(id);
                    faqQuestionRef.current.innerHTML = resp.data.question;
                    faqContentRef.current.innerHTML = resp.data.content;
                    window.location.href = '/support#law-faq-details';
                }
            })
        }
    }
    const sendMessage = () => {
        if (wgfIsNotNull([message], true) === true) {
            lawFetchPost('messages/create-support-message', { message }, null, null, null, () => {
                setMessage('');
            }, null, true, null)
        }
    }
    return (
        <>
            <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                    <Card>
                        <List component="nav" aria-label="main mailbox folders">
                            {
                                faqs.map((item, index) => {
                                    return (
                                        <ListItemButton
                                            key={index}
                                            selected={(faq !== null ? faq.id === item.id : false)}
                                            onClick={() => selectedFaq(item.id)}
                                        >
                                            <ListItemIcon>
                                                <QuestionMark />
                                            </ListItemIcon>
                                            <ListItemText primary={item.question} />
                                        </ListItemButton>
                                    )
                                })
                            }
                        </List>
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                    <Card style={{ paddingTop: '50px' }}>
                        <h4 id="law-faq-details" ref={faqQuestionRef}></h4>
                        <div ref={faqContentRef}></div>
                    </Card>
                </Grid>
            </Grid>
            <Card>
                <div className="m-auto mt-5 mb-5" style={{ maxWidth: '640px' }}>
                    <BoxCenter>
                        <img src={messageImage} alt="" />
                    </BoxCenter>
                    <TextField
                        label="Uw bericht"
                        multiline
                        value={message}
                        fullWidth
                        onChange={(e) => setMessage(e.target.value)}
                        rows={6}
                        maxRows={6}
                        sx={{ marginTop: '30px', marginBottom: '30px' }}
                    />
                    <BoxCenter style={{ marginBottom: '30px' }}>
                        {
                            (wgfAdminRoleControl())?(
                                <span className="text-muted">Mensen met beheerdersrechten kunnen geen berichten versturen!</span>
                            ):(
                                <Button onClick={sendMessage} variant="contained">Send</Button>
                            )
                        }
                        
                    </BoxCenter>
                </div>
            </Card>
        </>
    )
}
export default SupportView;