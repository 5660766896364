import { Button, IconButton, Typography } from "@mui/material"
import { wgPortalTypes } from "../lawConstants"
import LawModal from "./LawModal"
import { appObjects } from "../../App"
import { wgfSetLocalStorage } from "../functions/lawFunctions"

const ChangePortal = ({ showState, setShowState, closeCallback }) => {
    const handleChangePortal = (name) => {
        appObjects.values.loginInfo.portal = name;
        wgfSetLocalStorage("login-info", JSON.stringify(appObjects.values.loginInfo));
        appObjects.getAppDatas();
        window.location.href = `/index`;

    }
    return (
        (showState === null || showState === undefined) ? (
            <div style={{ display: 'flex', flexDirection:'row', justifyContent:'center', marginTop:'25px', marginBottom:'10px', gap: '5px', overflow: 'auto' }}>
                {
                    wgPortalTypes.filter(f => f.showChangePortal).map((item, index) => {
                        return (
                            <IconButton onClick={() => handleChangePortal(item.value)} key={'chc-prtl-' + index} sx={{ border: `1px solid ${appObjects.values.loginInfo.portal === item.value ? 'yellow' : '#fff'}`, color: (appObjects.values.loginInfo.portal === item.value ? 'yellow' : '#fff') }}>
                                {item.icon}
                            </IconButton>
                        )
                    })
                }
            </div>
        ) : (
            (showState === true) && (
                <LawModal state={showState} setState={setShowState} style={{ maxWidth: '600px' }} closeCallback={closeCallback}>
                    <div style={{ display: 'flex', overflow: 'auto', flexDirection: 'row', margin: 'auto', width: 'fit-content' }}>
                        {
                            wgPortalTypes.filter(f => f.showChangePortal).map((item, index) => {
                                return (
                                    <Button onClick={() => handleChangePortal(item.value)} key={'chc-prtl-' + index} variant={"outlined"} sx={{ width: '150px', minWidth: '150px', height: 110, margin: '7px' }}>
                                        <div>
                                            <div className='jf-center'>
                                                {item.icon}
                                            </div>
                                            <Typography sx={{ display: 'block', width: '100%', textAlign: 'center' }}>{item.text}</Typography>
                                        </div>
                                    </Button>
                                )
                            })
                        }
                    </div>
                </LawModal>
            )
        )

    )
}
export default ChangePortal;