import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const LawDeleteButton = ({ callback, status }) => {
    return (
        (status === -1) ? (
            <IconButton disabled={true} size="small">
                <Delete />
            </IconButton>
        ) : (
            <IconButton onClick={callback} size="small">
                <Delete />
            </IconButton>
        )
    )
}
export default LawDeleteButton;