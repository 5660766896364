import { ChevronRight, Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { wdfDynamicImportUrlParameter, wgfDateTimeFormat, wgfValueToPriceText } from "../../functions/lawFunctions";
import { lawFetchGet } from "../../functions/lawFetch";
import LawModal from "../../ui/LawModal";
import InvoiceTemplate from "./InvoiceTemplate";
import LawDataTable from "../../ui/LawDataTable";
import LawStatus from "../../ui/LawStatus";
import { appObjects } from "../../../App";

const InvoicesTable = ({ customerId, companyId }) => {
    const [invoiceModalShow, setInvoiceModalShow] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [invoices, setInvoices] = useState([]);
    const getAll = () => {
        let url = wdfDynamicImportUrlParameter('invoice/all', [
            { default: customerId, key: 'customerId' },
            { default: companyId, key: 'companyId' }
        ]);
        lawFetchGet(url, null, setInvoices, [], null, null, null, null);
    }
    useEffect(() => {
        getAll();
    }, [])
    const handleSelectInvoice = (id) => {
        setSelectedInvoice(id);
        setInvoiceModalShow(true);
    }
    const handleDeleteInvoice = (id) => {
        if (id) {
            appObjects.dialog.delete(() => {
                lawFetchGet('invoice/delete?id=' + id, null, null, null, () => {
                    setInvoiceModalShow(false);
                    setSelectedInvoice(null);
                    setInvoices(invoices.filter(f => f.id !== id));

                }, null, true, null);
            }, null)
        }
    }
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Company Name',
            selector: (row) => row.companyName,
            sortable: true,
        },
        {
            name: 'SellerCompanyName',
            selector: (row) => row.sellerCompanyName,
            sortable: true,
        },
        {
            name: 'Total',
            cell: (row, index) => (
                <span>{wgfValueToPriceText(row.totalWithVat)}</span>
            ),
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'InvoiceStatus'} />
            )
        },
        {
            name: 'Create',
            selector: (row) => wgfDateTimeFormat(row.date, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Action',
            width: '120px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <IconButton color="error" onClick={() => { handleDeleteInvoice(row.id) }}>
                        <Delete />
                    </IconButton>
                    <IconButton onClick={() => { handleSelectInvoice(row.id) }}>
                        <ChevronRight />
                    </IconButton>
                </div>
            ),
        }
    ];

    return (
        <>
            <LawDataTable dataState={invoices} setDataState={setInvoices} columns={columns} searchFields={['id', 'companyName', 'sellerCompanyName']} />
            {
                (invoiceModalShow && selectedInvoice) && (
                    <LawModal style={{ maxWidth: '1100px' }} state={invoiceModalShow} setState={setInvoiceModalShow}>
                        <InvoiceTemplate invoiceId={selectedInvoice} deleteEvent={() => handleDeleteInvoice(selectedInvoice)} />
                    </LawModal>
                )
            }
        </>
    )
}
export default InvoicesTable;
