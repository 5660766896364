import { useState } from "react";
import Card from "../../components/ui/Card"
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import CustomerInvoicesTable from "../../components/data/customer/CustomerInvoicesTable";
import FixedExpensesTable from "../../components/data/accountant_customer/fixed_expense/FixedExpensesTable";
import CustomerOpenInvoicesTable from "../../components/data/customer/CustomerOpenInvoicesTable";

const CustomerInvoicesView = () => {
    const [tabValue, setTabValue] = useState("1");
    const handleTabChange = (e, value) => {
        setTabValue(value);
    }
    return (
        <Card>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                        <Tab label="Invoices" value="1" />
                        <Tab label="Open Invoices" value="2" />
                        <Tab label="Fixed Expenses" value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <CustomerInvoicesTable />
                </TabPanel>
                <TabPanel value="2">
                    <CustomerOpenInvoicesTable />
                </TabPanel>
                <TabPanel value="3">
                    <FixedExpensesTable />
                </TabPanel>
            </TabContext>
        </Card>
    )
}
export default CustomerInvoicesView;