import Grid from "@mui/material/Grid2";
import Card from "../../ui/Card";
import TableTickets from "../app/ticket/TableTickets";
import TodoList from "../app/todolist/TodoList";

const AccountantViewForIndex = () => {
    return (
        <>
            <Card style={{ marginTop: '30px' }}>
                <Grid container spacing={{ xs: 2, md: 3 }} sx={{ mt: 3 }}>
                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 8 }}>
                        <TableTickets />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
                        <TodoList />
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}
export default AccountantViewForIndex;