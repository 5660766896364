import { Close, ExpandMore, FilterAlt } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Drawer, FormControlLabel, FormGroup, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LawDatePickerFromAndTo from "./LawDatePickerFromAndTo";
import { lawFetchGet, lawFetchPost } from "../functions/lawFetch";
import { wgfGetQueryStringValue, wgfObjectClone } from "../functions/lawFunctions";
const _compValues = {
    filterAreaMaxWidth: '340px !important'
}
const LawDataTableFilter = ({ dataState, setDataState, filterController, filterCallback }) => {
    const [filterProps, setFilterProps] = useState([]);
    const [filterModalShow, setFilterModalShow] = useState(false);
    const [filterDate, setFilterDate] = useState({ fromDate: null, toDate: null });
    const [filterSelecteds, setFilterSelecteds] = useState([]); // {name:'', values:[]}
    useEffect(() => {
        if (filterController && filterModalShow) {
            lawFetchGet(filterController + '/get-filter-props', null, null, null, null, null, false, (resp) => {
                if (resp && resp.data) {
                    let data = resp.data;
                    //setFilterProps
                    // []
                    let currentPageUrl = window.location.search;
                    if (currentPageUrl) {
                        if (currentPageUrl.includes('customerId')) {
                            data = data.filter(f => f.name !== 'TblCustomerDatas');
                        }
                        if (currentPageUrl.includes('companyId')) {
                            data = data.filter(f => f.name !== 'TblCustomerDatas' && f.name !== 'TblCustomerCompanies');
                        }
                    }
                    setFilterProps(data);
                }
            });
        }
    }, [filterModalShow])
    const handleFilterShowToggle = () => {
        setFilterModalShow(!filterModalShow);
    }
    const handleFilterCheckItem = (e, name, value) => {
        let selecteds = wgfObjectClone(filterSelecteds);
        if (selecteds && selecteds.length > 0) {
            if (!selecteds.find(f => f.name === name)) {
                selecteds.push({ name, values: [] });
            }
        }
        else {
            selecteds.push({ name, values: [] });
        }
        selecteds = selecteds.map(item => {
            if (item.name === name) {
                if (e.target.checked !== true) {
                    item.values = item.values.filter(f => f !== value);
                }
                else if (!item.values.includes(value)) {
                    item.values.push(value);
                }
            }
            return item;
        })
        selecteds = selecteds.filter(f => f.values.length > 0);
        setFilterSelecteds(selecteds);
    }
    const handleFilter = () => {
        let filterParams = {
            fromDate: filterDate['fromDate'],
            toDate: filterDate['toDate'],
            filterProps: filterSelecteds,
            queryStringCustomerId:wgfGetQueryStringValue('customerId'),
            queryStringCompanyId:wgfGetQueryStringValue('companyId'),
        }
        lawFetchPost(filterController + '/filter', filterParams, null, setDataState, [], () => {
            if (filterCallback) {
                filterCallback();
            }
        }, null, false, null);
    }
    const selectedControl = (name, value) => {
        if (filterSelecteds && filterSelecteds.length > 0) {
            return filterSelecteds.find(f => f.name === name && f.values.includes(value)) !== undefined;
        }
        return false;
    }
    return (
        <div className="law-dttble-filter">
            <IconButton onClick={handleFilterShowToggle}>
                <FilterAlt />
            </IconButton>
            {
                (filterModalShow && filterProps && filterProps.length > 0) && (
                    <Drawer
                        variant="temporary"
                        ModalProps={{
                            keepMounted: true, // Mobil performans için
                            hideBackdrop: true, // Siyah arka planı devre dışı bırakır
                            BackdropProps: {
                                style: { width: '100%', maxWidth: _compValues.filterAreaMaxWidth, boxShadow: '3px 3px 30px -20px #000' }
                            },
                        }}
                        sx={{
                            maxWidth: _compValues.filterAreaMaxWidth,
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', maxWidth: _compValues.filterAreaMaxWidth, border: 'none', boxShadow: '3px 3px 30px -20px #000', padding: '5px', background: '#fff' },
                        }}
                        open={filterModalShow} onClose={handleFilterShowToggle}>
                        <div className="d-flex justify-content-end">
                            <IconButton onClick={handleFilterShowToggle}>
                                <Close />
                            </IconButton>
                        </div>
                        <LawDatePickerFromAndTo fromState={filterDate} setFromState={setFilterDate} fromFieldName={'fromDate'} fromLabel={'Vanaf datum'} toState={filterDate} setToState={setFilterDate} toFieldName={'toDate'} toLabel={'Tot nu toe'} />
                        {
                            filterProps.map((prop, propI) => {
                                return (
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls={'filtr-prp-' + propI}
                                            id={'filtr-prp-' + propI}
                                        >
                                            <Typography component="span">{prop.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {
                                                (prop.items && prop.items.length > 0) ? (
                                                    prop.items.map(cItem => {
                                                        return (
                                                            <FormGroup>
                                                                <FormControlLabel control={<Checkbox checked={selectedControl(prop.name, cItem.value)} sx={{ p: '5px' }} onChange={(e) => handleFilterCheckItem(e, prop.name, cItem.value)} />} label={cItem.text} />
                                                            </FormGroup>
                                                        )
                                                    })
                                                ) : (
                                                    prop.itemsWithParent.map((cItem, cIndex) => {
                                                        return (
                                                            <div key={cIndex}>
                                                                <span className="d-block w-100">{cItem.parentName}</span>
                                                                <div className="ps-4">
                                                                    {
                                                                        cItem.items.map(cItemP => {
                                                                            return (
                                                                                <FormGroup>
                                                                                    <FormControlLabel control={<Checkbox checked={selectedControl(prop.name, cItemP.value)} sx={{ p: '5px' }} onChange={(e) => handleFilterCheckItem(e, prop.name, cItemP.value)} />} label={cItemP.text} />
                                                                                </FormGroup>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                )
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                        }
                        <div className="jf-center mt-3 mb-5">
                            <Button variant="contained" disabled={(!(filterSelecteds && filterSelecteds.length > 0) && !(filterDate['fromDate'] || filterDate['toDate']))} onClick={handleFilter}>Filter</Button>
                        </div>
                    </Drawer>
                )
            }
        </div>
    )
}
export default LawDataTableFilter;