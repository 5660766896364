import { useState } from "react";
import { Grid2 } from "@mui/material";
import { wgfGetQueryStringValue, wgfIsNotNull } from "../../../functions/lawFunctions";
import { lawFetchPost } from "../../../functions/lawFetch";
import EmployeeFormBase from "./EmployeeFormBase";
import LawSelect from "../../../ui/LawSelect";
import LawCreateDataModal from "../../../ui/LawCreateDataModal";

const EmployeeCreate = ({ companyId, callback }) => {
    const [companyIdNo,setCompanyId]=useState(companyId ?? wgfGetQueryStringValue('companyId'))
    const [employee, setEmployee] = useState({ companyId:companyIdNo });
    const handleCreate = () => {
        if (wgfIsNotNull([employee['name'], employee['sureName'], employee['companyId']], true) === true) {
            lawFetchPost('CompanyEmployees/create', employee, null, null, null, () => {
                setEmployee({ companyId:companyIdNo })
                if (callback) {
                    callback();
                }
            }, null, false, null);
        }
    }
    return (
        <>
            <LawCreateDataModal createEvent={handleCreate} style={{maxWidth:'900px'}}>
                <Grid2 container={true} spacing={2}>
                    <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
                        {
                            (!companyIdNo) && (
                                <LawSelect itemsUrl={'customerCompany/get-for-select'} state={employee} setState={setEmployee} fieldName={'companyId'} label={'Company'} />
                            )
                        }
                        <EmployeeFormBase employeeState={employee} setEmployeeState={setEmployee} />
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
                    </Grid2>
                </Grid2>
            </LawCreateDataModal>
        </>
    )
}
export default EmployeeCreate;