import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // Gün görünümü
import timeGridPlugin from "@fullcalendar/timegrid"; // Zaman görünümü
import interactionPlugin from "@fullcalendar/interaction"; // Tıklama ve sürükleme
import { useEffect, useState } from "react";
import LawCalendarEdit from "./LawCalendarEdit";
import { lawFetchGet } from "../../../functions/lawFetch";
import { wgfDateTimeFormat } from "../../../functions/lawFunctions";
/*
[
        { title: "Toplantı", start: "2024-12-29T10:00:00", end: "2024-12-29T12:00:00" },
        { title: "Doğum Günü Partisi", start: "2024-12-30", allDay: true },
        { title: "Proje Teslimi", start: "2024-12-31T15:00:00" },
    ]

*/
const LawCalendar = () => {
    const [events, setEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const getAll = () => {
        lawFetchGet('calendar/all-for-calendar', null, setEvents, [], null, null, false, null);
    }
    useEffect(getAll, []);
    const handleDateClick = (info) => {
        setSelectedDate(info.dateStr);
    };
    const editCallback = () => {
        setSelectedDate(null);
        getAll();
    }
    const handleEventClick = (e) => {
        if (e.event && e.event.id) {
            setSelectedEvent(e.event.id);
            setSelectedDate(wgfDateTimeFormat(e.event.start,'yyyy-MM-dd'));
        }
        else {
            setSelectedEvent(null);
        }
    }
    return (
        <>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth" // Varsayılan görünüm
                editable={false} // Sürükle bırak özelliği
                selectable={true} // Tıklanabilir alanlar
                events={events} // Etkinlik verileri
                dateClick={handleDateClick} // Tarih tıklama işlemi
                eventClick={handleEventClick}
                headerToolbar={{
                    left: "prev,next today",
                    center: "",
                    right: "title",
                }}
            />
            <LawCalendarEdit date={selectedDate} setDate={setSelectedDate} eventId={selectedEvent} setEventId={setSelectedEvent} editCallback={editCallback} />
        </>
    )
}
export default LawCalendar;
