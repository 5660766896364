import { Avatar, Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { AlternateEmail, ArrowForward, AssignmentInd, ChevronRight, Edit, MailOutline, Person, Phone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import BoxCenter from "../../ui/BoxCenter";
import CustomerEdit from "./CustomerEdit";
import LawModal from "../../ui/LawModal";
import { lawFetchGet } from "../../functions/lawFetch";
import userIcon from '../../../img/9187604_72.png'
import { wgfAdminRoleControl } from "../../functions/lawFunctions";
import { appObjects } from "../../../App";
import Card from "../../ui/Card";
import MessageBody from "../app/messages/MessageBody";
import LawStatus from "../../ui/LawStatus";

const CustomerCard = ({ customerId, isShowGoToCustomerButton, customer, editCallback }) => {
    const [showModalEditCustomer, setShowModalEditCustomer] = useState(false);
    const [showModalMessage, setShowModalMessage] = useState(false);
    const [showModalCustomerInfoes, setShowModalCustomerInfoes] = useState(false);
    const [customerInfo, setCustomerInfo] = useState(customer);
    const customerUpdateCallback = () => {
        setShowModalEditCustomer(false);
        if (editCallback) {
            editCallback();
        }
    }
    useEffect(() => {
        if (customer) {
            setCustomerInfo(customer);
        }
    }, [customer])
    if (!customerInfo && customerId) {
        lawFetchGet('customer/get?customerId=' + customerId, null, setCustomerInfo, {}, null, null, null, null)
    }
    return (
        <>
            {
                (customerInfo && customerInfo.id) && (
                    <>
                        <div class="usr-bnnr-container">
                            <div class="usr-bnnr-user">
                                <div class="usr-bnnr-profile">
                                    <Person sx={{width:'70px !important', color:'#999'}}/>
                                </div>
                                <span class="usr-bnnr-name">{customerInfo.name} {customerInfo.surname}</span>
                                <div className="jf-center">
                                    <LawStatus status={customerInfo.status} />
                                </div>
                            </div>
                            <div class="usr-bnnr-buttons">
                                {
                                    (appObjects.values.roleGroup.accountantLawyer) && (
                                        <>
                                            <div class="item">
                                                <IconButton component={Link} to="#" onClick={(e) => { window.location.href = ("tel:" + customerInfo.phone); e.preventDefault(); }}>
                                                    <Phone color="warning" />
                                                </IconButton>
                                            </div>
                                            <div class="item">
                                                <IconButton component={Link} to="#" onClick={(e) => { window.location.href = ('mailto:' + customerInfo.email); e.preventDefault(); }}>
                                                    <AlternateEmail color="success" />
                                                </IconButton>
                                            </div>
                                            <div class="item">
                                                <IconButton onClick={() => setShowModalMessage(true)}>
                                                    <MailOutline color="success" />
                                                </IconButton>
                                            </div>
                                            <div class="item">
                                                <IconButton onClick={() => setShowModalCustomerInfoes(true)}>
                                                    <AssignmentInd color="primary" />
                                                </IconButton>
                                            </div>
                                            {
                                                (wgfAdminRoleControl() === true) && (
                                                    <div class="item">
                                                        <IconButton onClick={() => setShowModalEditCustomer(true)}>
                                                            <Edit color="primary" />
                                                        </IconButton>
                                                    </div>
                                                )
                                            }

                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <Box sx={{ mt: 3 }}>
                            <BoxCenter>

                            </BoxCenter>
                            <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center', alignContent: 'start', alignItems: 'start' }}>

                            </Box>
                            <BoxCenter style={{ marginBottom: '15px', marginTop: '15px' }}>
                                {
                                    (isShowGoToCustomerButton) ? (
                                        <Button variant="text" component={Link} to={("/customer?id=" + customerInfo.id)}>{customerInfo.name} {customerInfo.surname}  <ArrowForward fontSize="small" /></Button>
                                    ) : (
                                        <h6></h6>
                                    )
                                }

                            </BoxCenter>
                        </Box>
                        {
                            (showModalEditCustomer) && (
                                <LawModal style={{ maxWidth: '1100px' }} state={showModalEditCustomer} setState={setShowModalEditCustomer} >
                                    <Card>
                                        <CustomerEdit id={customerInfo.id} callback={customerUpdateCallback} />
                                    </Card>
                                </LawModal>
                            )
                        }
                        {
                            (showModalMessage && customerInfo) && (
                                <LawModal state={showModalMessage} setState={setShowModalMessage} style={{ width: '90%', maxWidth: '800px', padding: '0px' }}>
                                    <MessageBody isForMessagePage={false} userId={customerInfo.id} />
                                </LawModal>
                            )
                        }
                        {
                            (showModalCustomerInfoes) && (
                                <LawModal state={showModalCustomerInfoes} setState={setShowModalCustomerInfoes}>
                                    <Box>
                                        <Stack direction="row" spacing={2}>
                                            <Box>
                                                <ChevronRight />
                                            </Box>
                                            <Box>
                                                <span className="d-block">{('Name:' + customerInfo.name)}</span>
                                            </Box>
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <Box>
                                                <ChevronRight />
                                            </Box>
                                            <Box>
                                                <span className="d-block">{('Surname:' + customerInfo.surname)}</span>
                                            </Box>
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <Box>
                                                <ChevronRight />
                                            </Box>
                                            <Box>
                                                <span className="d-block">{('E-mail:' + customerInfo.email)}</span>
                                            </Box>
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <Box>
                                                <ChevronRight />
                                            </Box>
                                            <Box>
                                                <span className="d-block">{('Telefoon:' + customerInfo.phone)}</span>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </LawModal>
                            )
                        }

                    </>
                )
            }
        </>
    )
}
export default CustomerCard;