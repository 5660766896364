import { useEffect, useState } from "react";
import LawInput from "../../../ui/LawInput";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import { wgfChangeState, wgfIsNotNull } from "../../../functions/lawFunctions";
import { Button } from "@mui/material";
import LawModal from "../../../ui/LawModal";
import { Add } from "@mui/icons-material";
import LawHtmlEditor from '../../../ui/LawHtmlEditor';



const FaqCreateEdit = ({ id, setId, successCallback }) => {
    const [open, setOpen] = useState(false);
    const [faq, setFaq] = useState({ Id: id ?? '' });
    const [content, setContent] = useState('');
    useEffect(() => {
        if (id) {
            lawFetchGet("faqs/get?id=" + id, null, setFaq, {}, null, null, false, (resp) => {
                if (resp) {
                    setFaq(resp.data);
                    setContent(resp.data['content'])
                    setOpen(true);
                }
            });
        }
    }, [id])
    useEffect(() => {
        wgfChangeState(content, 'content', faq, setFaq);
    }, [content])
    useEffect(() => {
        if (open === false) {
            setFaq({ Id: id ?? '' });
            setContent('');
            setId(null);
        }
    }, [open])

    const edit = () => {
        if (wgfIsNotNull([faq['question']], true) === true) {
            lawFetchPost('faqs/edit', faq, null, null, null, () => {
                setFaq({ Id: id ?? '' });
                setOpen(false);
                setId(null);
                if (successCallback) {
                    successCallback();
                }
            }, null, true, null);
        }
    }

    return (
        <>
            <Button onClick={() => setOpen(true)}><Add /> Create Faq</Button>
            {
                (open) && (
                    <LawModal state={open} setState={setOpen} style={{ maxWidth: '800px' }}>
                        <LawInput label={"Question"} propstate={[faq, setFaq, 'question']} />
                        <div className="mt-3 mb-5">
                            <LawHtmlEditor style={{height:'60vh'}} state={content} setState={setContent} />
                        </div>
                        <Button variant="contained" onClick={edit}>Opslaan</Button>
                    </LawModal>
                )
            }
        </>
    )
}
export default FaqCreateEdit;