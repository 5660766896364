import { useEffect, useState } from "react"
import { lawFetchGet } from "../functions/lawFetch";
import DataNotFound from "./DataNotFound";
import DataTable from "react-data-table-component";
import LawListSearchBox from "./LawListSearchBox";
import { Checkbox, Chip, IconButton } from "@mui/material";
import { ChecklistRtl, Download, Refresh, Rule } from "@mui/icons-material";
import LawDataTableFilter from "./LawDataTableFilter";
const _compValues = {
    reRender: 0
}
const LawDataTable = ({ dataUrl, dataState, setDataState, columns, refreshCallback, searchFields, isAddCheckbox, filterController, filterCallback }) => {
    const [datas, setDatas] = useState(dataState ?? []);
    const [pagination, setPagination] = useState(1);

    useEffect(() => {
        if (dataState) {
            setDatas(dataState);
        }
    }, [dataState])
    useEffect(() => {
        if (dataUrl) {
            lawFetchGet(dataUrl, null, setDatas, [], null, null, false, null);
        }

    }, [])
    let tempData = datas.filter(f => f.show === undefined || f.show === null || f.show === true);
    let tempColumns = [
        {
            name: 'Indx',
            width: "60px",
            cell: (row, index) => (
                <span>{(index + 1)}</span>
            ),
        },
        ...columns
    ];
    const handleCheckData = (id, isChecked, isReverse) => {
        _compValues.reRender++;
        let isAll = (id === 'all' ? (datas.filter(f => f['checked'] === true)).length > 0 : false);
        setDatas(datas.map(item => {
            if (item.id === id || id === 'all') {
                item['checked'] = isReverse ? !(item['checked'] === true) : (id === 'all' ? !isAll : isChecked);
            }
            return item;
        }))

    }
    if (isAddCheckbox) {
        const index = tempColumns.length - 1;
        tempColumns.splice(index, 0, (
            {
                name: 'Check',
                width: '70px',
                cell: (row) => (
                    <Checkbox checked={row['checked'] === true} onChange={(e) => handleCheckData(row.id, e.target.checked)} />
                )
            }
        ));
    }
    tempColumns = tempColumns.map(item => {
        if (item.name === 'Id') {
            item['width'] = '120px'
        }
        else if (item.name === 'Status') {
            item['maxWidth'] = '150px'
        }
        return item;
    })
    const handleExportToExcel = () => {
        /*
          > Export edilecek kayitlarin ID numarasi Backend'e gonderilecek
          > Role kontrolu yapilip onaylanacak
          > Role gore Column'lar ayarlanacak
          > Log kayiti yapilacak
          > Kaydeddilen dosya bilgisi kullaniciya bildirim olarak gonderilecek. Bildirimdeki Download butonuna tiklayinca download edilecek
  
  
        */
    };
    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <div className="d-flex">
                        {
                            (filterController) && (
                                <LawDataTableFilter dataState={dataState} setDataState={setDataState} filterController={filterController} filterCallback={filterCallback} />
                            )
                        }
                        {
                            (isAddCheckbox) && (
                                <>
                                    <IconButton onClick={() => handleCheckData('all', true, false)}>
                                        <ChecklistRtl />
                                    </IconButton>
                                    <IconButton onClick={() => handleCheckData('all', false, true)}>
                                        <Rule />
                                    </IconButton>
                                </>
                            )
                        }
                        {
                            (refreshCallback) && (
                                <IconButton onClick={refreshCallback}>
                                    <Refresh />
                                </IconButton>
                            )
                        }
                        <IconButton onClick={handleExportToExcel}>
                            <Download />
                        </IconButton>
                        {
                            /*
                               <IconButton onClick={() => { }}>
                                <Print />
                            </IconButton>
                            <IconButton onClick={() => { }}>
                                <Mail />
                            </IconButton>
                            */
                        }
                    </div>
                </div>
                <div>
                    {
                        (searchFields) && (
                            <LawListSearchBox listState={datas} setListState={setDatas} searchFields={searchFields} />
                        )
                    }
                </div>
            </div>
            {
                (tempData && tempData.length > 0) ? (
                    <div className="w-100">
                        <DataTable
                            key={_compValues.reRender}
                            style={{ tableLayout: 'auto' }}
                            fixedHeader={true}
                            responsive={true}
                            columns={tempColumns}
                            data={tempData}
                            paginationDefaultPage={pagination}
                            pagination
                            onChangePage={(e) => setPagination(e)}

                        />
                        <div className="m-0 p-0">
                            {
                                (tempData) && (tempData.filter(f => f['checked'] === true).map(item => {
                                    return <Chip className="m-1" key={'slct-data-' + item.id} label={item.id} variant="outlined" onDelete={() => handleCheckData(item.id, false)} />
                                }))
                            }
                        </div>
                    </div>
                ) : (
                    <DataNotFound />
                )
            }

        </>
    )
}
export default LawDataTable;