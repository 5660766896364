import { Box, Button, Typography } from "@mui/material";
import Card from "../../components/ui/Card";
import BoxCenter from "../../components/ui/BoxCenter";
import { ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { appObjects } from "../../App";

const PageNotFoundView = () => {
    return (
        <Box sx={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
            <Card style={{ maxWidth: '580px', paddingBottom:'50px' }}>
                <Typography sx={{ fontSize: 90, textAlign: 'center', width: '100%', color: '#ccc', fontWeight: '700' }}>404</Typography>
                <Typography sx={{ fontSize: 40, textAlign: 'center', width: '100%', color: '#ccc', fontWeight: '700', mb: 5 }}>Page not Found</Typography>
                <BoxCenter>
                    <Button component={Link} to={( (appObjects.values.loginInfo && appObjects.values.loginInfo.isLogin) ?"/index":"/login")} variant="contained" endIcon={<ChevronRight />}>
                        Start pagina
                    </Button>
                </BoxCenter>
                
            </Card>
        </Box>
    )
}
export default PageNotFoundView;