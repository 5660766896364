import { Button } from "@mui/material"
import LawCheckbox from "../../../ui/LawCheckbox"
import LawInput from "../../../ui/LawInput"
import { useState } from "react";
import { lawFetchPost } from "../../../functions/lawFetch";
import LawInputEmail from "../../../ui/LawInputEmail";
import LawInputPassword from "../../../ui/LawInputPassword";

const EmailInfoes = () => {
    const [settings, setSettings] = useState({});
    const save = () => {
        lawFetchPost('app/edit-settings', settings, null, null, null, null, null, true, null);
    }
    return (
        <>
            <LawInputEmail state={settings} setState={setSettings} fieldName={'email'}/>
            <LawInput label={"E-mail Host"} type={"email"} propstate={[settings, setSettings, 'emailHost']} />
            <LawInputPassword label={"E-mail Password"} state={settings} setState={setSettings} fieldName={'emailPassword'}/>
            <LawInput label={"E-mail Port"} type={"email"} propstate={[settings, setSettings, 'emailPort']} />
            <LawCheckbox label={"E-mail SSL"} propstate={[settings, setSettings, 'emailRequiredSsl']} />
            <Button variant="contained" onClick={save}>Opslaan</Button>
        </>
    )
}
export default EmailInfoes;