import { wgfDateTimeFormat } from "../../functions/lawFunctions";
import { IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import LawDataTable from "../../ui/LawDataTable";
import LawStatus from "../../ui/LawStatus";
import { useEffect, useState } from "react";
import { lawFetchGet } from "../../functions/lawFetch";

const CustomerInvoicesTable = () => {
    const [invoices, setInvoices] = useState([]);
    useEffect(() => {
        lawFetchGet("invoice/all", null, setInvoices, [], null, null, false, null);
    }, [])
    const handleSelectInvoice = (id) => {
    }
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Title',
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: 'Date',
            cell: (row) => {
                <span>{wgfDateTimeFormat(row.invoiceDate, 'dd.MM.yyyy')}</span>
            }
        },
        {
            name: 'From-To Date',
            cell: (row) => {
                <span>{(row.fromDate ? wgfDateTimeFormat(row.fromDate, 'dd.MM.yyyy') : '----') | (row.toDate ? wgfDateTimeFormat(row.toDate, 'dd.MM.yyyy') : '----')}</span>
            }
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'InvoiceStatus'} />
            )
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <IconButton onClick={() => handleSelectInvoice(row.id)}>
                        <ChevronRight />
                    </IconButton>
                </div>
            ),
        }
    ];
    return (
        <>
            <LawDataTable dataState={invoices} setDataState={setInvoices} columns={columns} searchFields={['title', 'id']} />
        </>
    )
}
export default CustomerInvoicesTable;