import { TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { wgfChangeState } from "../functions/lawFunctions";
import { WgControlsStyle } from "../lawConstants";
/*
inputAdornments = Input'a [€ ₺ $] eklenecek olan karakterler icin
*/
const LawInputNumber = ({ state, setState, fieldName, min, max, label }) => {
    const handleChange = (e) => {
        wgfChangeState( e.target.value, fieldName, state, setState);
    }
    return (
        <NumericFormat
            value={(state && (fieldName ? state[fieldName] : state))}
            onChange={handleChange}
            customInput={TextField}
            min={min ?? 0}
            max={max ?? 9999999999}
            size="small"
            style={WgControlsStyle}
            valueIsNumericString
            variant="outlined"
            label={label}
        />
    )
}
export default LawInputNumber;