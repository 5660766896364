import { Box, Button } from "@mui/material";
import { useState } from "react";
import { lawFetchPost } from "../../../functions/lawFetch";
import LawInputPassword from "../../../ui/LawInputPassword";
const ChangePassword = ({ userId, isMyPassword }) => {
    const [password, setPassword] = useState({ currentPassword: '', userId: userId });
    const changePassword = () => {
        if (isMyPassword) {
            lawFetchPost('/account/change-password', password, null, null, null, passwordsEmpty, null, true, null);
        }
        else {
            lawFetchPost('/account/reset-password', password, null, null, null, passwordsEmpty, null, true, null);
        }
    }
    const passwordsEmpty = () => {
        if (isMyPassword) {
            setPassword({ currentPassword: '', userId: userId, newPassword: '' });
        }
        else {
            setPassword({ userId: userId, newPassword: ''});
        }
    }
    return (
        <>
            <Box sx={{ maxWidth: '440px' }}>
                {
                    (isMyPassword) && (<LawInputPassword state={password} setState={setPassword} fieldName={'currentPassword'} label={"Current Password"} />)
                }
                <LawInputPassword state={password} setState={setPassword} fieldName={'newPassword'} isWithReEnter={true} />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" onClick={changePassword}>Change Password</Button>
                </Box>
            </Box>
        </>
    )
}
export default ChangePassword;