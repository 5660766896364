import { getMonthsArray, getYearsArray } from "../../../functions/lawFunctions";
import LawInputNumber from "../../../ui/LawInputNumber";
import LawSelect from "../../../ui/LawSelect";

const EmployeeWorkMontlyBaseElements = ({ workState, setWorkState }) => {
    return (
        <>
            <div className="d-flex align-items-center alig-content-center">
                <div>
                    <span>Month</span>
                </div>
                <div>
                    <LawSelect items={getMonthsArray()} state={workState} setState={setWorkState} fieldName={"month"} />
                </div>
                <div className="ms-3">
                    <span>Year</span>
                </div>
                <div>
                    <LawSelect items={getYearsArray(-1, 0)} state={workState} setState={setWorkState} fieldName={"year"} />
                </div>
            </div>
            <div className="d-flex align-items-center alig-content-center">
                <div>
                    <span>Total Hour</span>
                </div>
                <div>
                    <LawInputNumber state={workState} setState={setWorkState} fieldName={'totalWorkingHours'} />
                </div>
            </div>
        </>
    )
}
export default EmployeeWorkMontlyBaseElements;