import './InvoiceTemplateStyle.css'
import { useEffect, useState } from "react";
import { lawFetchGet } from "../../functions/lawFetch";
import { wgfDateTimeFormat } from '../../functions/lawFunctions';
import { Button, ButtonGroup } from '@mui/material';
import { Cached, Check, Delete, Edit, Image, Print, Undo } from '@mui/icons-material';

const InvoiceTemplate = ({ invoiceId, deleteEvent }) => {
    const [invoice, setInvoice] = useState(null);
    const [unitTypes, setUnitTypes] = useState([]);
    useEffect(() => {
        lawFetchGet('invoice/unit-types-for-select', null, setUnitTypes, [], () => {
            lawFetchGet('invoice/get?id=' + invoiceId, null, setInvoice, null, null, null, false, null);
        }, null, false, null);

    }, [])
    const getUnitTypeName = (id) => {
        return unitTypes.find(f => f.value === id)?.text ?? '';
    }
    return (
        (invoice) && (
            <>
                <div style={{ background: '#f1f1e6', padding: '1rem', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', alignContent:'center' }}>
                    <div>
                        <ButtonGroup variant="text">
                            <Button>
                                <Print />
                            </Button>
                            <Button>
                                <Image />
                            </Button>
                            <Button>
                                <Undo /> Verzonden om te herzien
                            </Button>
                            <Button>
                                <Cached /> Opnieuw creëren
                            </Button>
                            {
                                (deleteEvent) && (
                                    <Button onClick={deleteEvent}>
                                        <Delete color='error' />
                                    </Button>
                                )
                            }
                        </ButtonGroup>
                    </div>
                    <div>
                        <ButtonGroup variant="text">
                            <Button>
                                <Check color='success' />
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
                <div class="invoice-template-container">
                    <div class="invoice-header">
                        <div class="invoice-logo">FACTUUR</div>
                        <div class="invoice-company-info">
                            <strong>{invoice.sellerCompanyName}</strong><br />
                            <strong>Email:</strong>{invoice.sellerEmail}<br />
                            <strong>Phone:</strong>{invoice.sellerPhone}<br />
                            <strong>Fax:</strong>{invoice.sellerFax}<br />
                            <strong>Address:</strong>{invoice.sellerAddress}
                        </div>
                    </div>

                    <div class="invoice-section">
                        <h3>Invoice Details</h3>
                        <p><strong>Invoice No:{invoice.invoiceNumber}</strong><br />
                            <strong>Date:</strong>  {wgfDateTimeFormat(invoice.invoiceDate, 'dd.MM.yyyy')}
                        </p>
                    </div>

                    <div class="invoice-section">
                        <h3>Billing To</h3>
                        <p>{invoice.clientName}<br />
                            {invoice.clientAddress}
                        </p>
                    </div>

                    <div class="invoice-section">
                        <table class="invoice-table">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th style={{ width: '180px' }}>Quantity</th>
                                    <th style={{ width: '100px' }}>VAT</th>
                                    <th style={{ width: '125px' }}>Unit Price</th>
                                    <th style={{ width: '200px' }}>Discount</th>
                                    <th style={{ width: '110px' }}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invoice.invoiceDetails.map((item, index) => (
                                        <tr key={'invc-tr-' + index}>
                                            <td>{item.detailItemName}</td>
                                            <td>{item.quantity} {getUnitTypeName(item.unitId)}</td>
                                            <td>%{item.vat}</td>
                                            <td>€{item.unitPrice}</td>
                                            <td>{`${item.discountType === 2 ? '€' : ''}${item.discountValue}${item.discountType === 1 ? '%' : ''}`}</td>
                                            <td>€{item.totalAmount}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="5" class="invoice-totals">Subtotal:</td>
                                    <td>€{invoice.totalPrice}</td>
                                </tr>
                                <tr>
                                    <td colspan="5" class="invoice-totals">VAT:</td>
                                    <td>€{invoice.totalVat}</td>
                                </tr>
                                <tr>
                                    <td colspan="5" class="invoice-totals">Total:</td>
                                    <td>€{invoice.totalWithVat}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div class="invoice-footer">
                        {invoice.id}
                    </div>
                </div>
            </>
        )
    )
}
export default InvoiceTemplate;