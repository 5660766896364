import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import EmployeeEdit from "./EmployeeEdit";
import EmployeeWorkMontlyTable from "./EmployeeWorkMontlyTable";

const EmployeeDetails = ({ employeeId,editCallback }) => {
    const [tabValue, setTabValue] = useState('1');
    const handleTabChange = (e, value) => {
        setTabValue(value);
    }
    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                            <Tab label="Infoes" value="1" />
                            <Tab label="Works" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <EmployeeEdit employeeId={employeeId} callback={editCallback}/>
                    </TabPanel>
                    <TabPanel value="2">
                        <EmployeeWorkMontlyTable employeeId={employeeId}/>
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    )
}
export default EmployeeDetails;