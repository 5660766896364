import { useState } from "react";
import EmployeeWorkMontlyBaseElements from "./EmployeeWorkMontlyBaseElements";
import { lawFetchPost } from "../../../functions/lawFetch";
import LawSelect from "../../../ui/LawSelect";
import { wdfDynamicImportUrlParameter } from "../../../functions/lawFunctions";
import LawCreateDataModal from "../../../ui/LawCreateDataModal";

const EmployeeWorkMontlyCreate = ({ companyId, createCallback }) => {
    const [work, setWork] = useState({});
    const handleCreate = () => {
        lawFetchPost('EmployeeWorkMontly/create', work, null, null, null, createCallback, null, true, null);
    }
    return (
        <>
            <LawCreateDataModal createEvent={handleCreate}>
                <LawSelect label={'Employee'} itemsUrl={wdfDynamicImportUrlParameter('companyEmployees/all-for-select', [{ default: companyId, key: 'companyId' }])} state={work} setState={setWork} fieldName={'employeeId'} />
                <EmployeeWorkMontlyBaseElements workState={work} setWorkState={setWork} />
            </LawCreateDataModal>
        </>
    )
}
export default EmployeeWorkMontlyCreate;