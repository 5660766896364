import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import { wgfIsNotNull } from "../../../functions/lawFunctions";
import CompanyVehicleFormBase from "./CompanyVehicleFormBase";
import { Button } from "@mui/material";
import LawSelect from "../../../ui/LawSelect";
import { lawStatusActivePassive } from "../../../lawConstants";
const CompanyVehicleEdit = ({ vehicleId, callback }) => {
    const [vehicle, setVehicle] = useState(null);
    useEffect(() => {
        if (vehicleId) {
            lawFetchGet('companyVehicle/get?id=' + vehicleId, null, setVehicle, null, null, null, false, null);
        }
    }, [])
    const handleSave = () => {
        if (vehicle && wgfIsNotNull([vehicle['id'], vehicle['code']], true) === true) {
            lawFetchPost('companyVehicle/edit', vehicle, null, null, null, () => {
                setVehicle(null);
                if (callback) {
                    callback();
                }
            }, null, true, null)
        }
    }
    return (
        (vehicle) && (
            <>

                <CompanyVehicleFormBase vehicleState={vehicle} setVehicleState={setVehicle} />
                <LawSelect items={lawStatusActivePassive} state={vehicle} setState={setVehicle} fieldName={'status'} label={'Status'}></LawSelect>
                <div className="jf-center">
                    <Button variant="contained" onClick={handleSave}>Opslaan</Button>
                </div>
            </>
        )
    )
}
export default CompanyVehicleEdit;