import { useState } from "react";
import LawModal from "../../ui/LawModal";
import { wgfIsNotNull } from "../../functions/lawFunctions";
import { lawFetchGet, lawFetchPost } from "../../functions/lawFetch";
import LawInput from "../../ui/LawInput";
import LawSelect from "../../ui/LawSelect";
import { lawStatusActivePassive } from "../../lawConstants";
import LawButtonGroup from "../../ui/LawButtonGroup";
import { Avatar, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Business, ChevronRight, Edit } from "@mui/icons-material";
import LawListSearchBox from "../../ui/LawListSearchBox";
import LawInputPhone from "../../ui/LawInputPhone";
import LawInputEmail from "../../ui/LawInputEmail";

const Seller = ({selectedSetState}) => {
    const [sellerShow, setSellerShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [sellers, setSellers] = useState([]);
    const [seller, setSeller] = useState(null);
    const [sellerStatus, setSellerStatus] = useState(1);
    const getAll = () => {
        lawFetchGet('sellers/all', null, setSellers, [], null, null, false, null);
    }
    const handleShow = () => {
        setSeller(null);
        getAll();
        setSellerShow(true);
    }
    const handleNew = () => {
        setEditShow(true);
        setSeller({ id: 0, registerId: '', status: 0 });
    }
    const handleEditSelect = (item) => {
        setSellerStatus(item.status ?? 0);
        setSeller(item);
        setEditShow(true);
    }
    const handleSelect = (item) => {
        setSellerShow(false);
        if(selectedSetState){
            selectedSetState(item)
        }
    }
    const handleSave = () => {
        if (wgfIsNotNull([seller['companyName']], true) === true) {
            lawFetchPost('sellers/edit', seller, null, null, null, () => {
                getAll();
                setSeller(null);
            }, null, true, null)
        }
    }


    return (
        <>
            <Button onClick={handleShow} variant="outlined">Companies</Button>
            {
                (sellerShow) && (
                    <LawModal state={sellerShow} setState={setSellerShow}>
                        <LawButtonGroup>
                            <Button onClick={handleNew} variant="outlined">Nieuw</Button>
                        </LawButtonGroup>
                        {
                            (seller && editShow) && (
                                <LawModal state={editShow} setState={setEditShow} closeCallback={() => setSeller(null)}>
                                    <LawInput propstate={[seller, setSeller, 'companyName']} label={'Company Name'} />
                                    <LawInputPhone state={seller} setState={setSeller} fieldName={'phone'}/>
                                    <LawInputEmail state={seller} setSeller={setSeller} fieldName={'email'}/>
                                    <LawInput propstate={[seller, setSeller, 'address']} label={'Address'} />
                                    <LawInput propstate={[seller, setSeller, 'fax']} label={'Fax'} />
                                    <LawSelect state={sellerStatus} setState={setSellerStatus} items={lawStatusActivePassive} label={'Status'} ></LawSelect>
                                    <div className="jf-center">
                                        <Button onClick={handleSave} variant="contained">Opslaan</Button>
                                    </div>
                                </LawModal>
                            )
                        }


                        <LawListSearchBox listState={sellers} setListState={setSellers} searchFields={['companyName', 'email', 'phone', 'address']} />
                        <List>
                            {
                                (sellers) && (sellers.filter(f => f.show === undefined || f.show === true).map((item, index) => {

                                    return (
                                        <ListItem
                                            key={'seller-' + index}
                                            secondaryAction={
                                                <>
                                                    <IconButton edge="end" onClick={() => handleEditSelect(item)}>
                                                        <Edit />
                                                    </IconButton>
                                                    <IconButton edge="end"  onClick={() => handleSelect(item)}>
                                                        <ChevronRight />
                                                    </IconButton>
                                                </>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <Business />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={item.companyName}
                                                secondary={''}
                                            />
                                        </ListItem>

                                    )
                                }))
                            }
                        </List>


                    </LawModal >
                )
            }
        </>
    )
}
export default Seller;