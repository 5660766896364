import { useEffect, useState } from "react"
import LawInput from "./LawInput"
import { wgfObjectClone } from "../functions/lawFunctions"

const LawListSearchBox = ({ listState, setListState, searchFields }) => {
    const [isControl, setIsControl] = useState(false);
    useEffect(() => {
        if (isControl === false && listState && listState.length > 0) {
            setIsControl(true);
            setListState(listState.map(item => {
                item['show'] = true;
                return item;
            }))
        }
    }, [listState])
    const search = (e) => {
        if (listState && setListState && searchFields) {
            let _searchVal = e.target.value.toLowerCase();
            let _tempList = wgfObjectClone(listState);
            _tempList = _tempList.map(item => {
                let _isShow = false;
                for (let i = 0; i < searchFields.length; i++) {
                    if (item[searchFields[i]]) {
                        if (item[searchFields[i]].toLowerCase().includes(_searchVal)) {
                            _isShow = true;
                            break;
                        }
                    }
                }
                item['show'] = _isShow;
                return item;
            })
            setListState(_tempList);
        }
    }
    return (
        <LawInput onKeyup={search} label={"Search..."} />
    )
}
export default LawListSearchBox;