import { ArrowBack, KeyboardArrowDown } from '@mui/icons-material';
import { Box, Drawer, Typography, MenuList, MenuItem, ListItemIcon, ListItemText, Divider, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemButton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BoxCenter from './BoxCenter';
import { appObjects, ContxAppData } from '../../App';
import ChangePortal from './ChangePortal';
import { wgfAdminRoleControl, wgfGetQueryStringValue } from '../functions/lawFunctions';
import { portalRouteIsCopyParentParams } from '../../pages/PortalRoutes';
import { lawFetchGet } from '../functions/lawFetch';
/*
isDivider: false,
icon: <Home sx={_iconStyles} />,
text: button text 
identity: butona ozel ID, rollere gore butonlari gostermek icin falan
url: url address
*/

export const styles = {
    menuItem: { borderRadius: '50px', color: '#fff', marginBottom: '3px', padding: '10px 15px' },
    menuItemActive: { borderRadius: '50px', color: '#fff', backgroundColor: '#3a3a77', marginBottom: '3px', padding: '10px 15px' },
    accordionMenuItems: { borderRadius: '50px', marginBottom: '3px', padding: '5px 15px' },
    accordionMenuItemsActive: { borderRadius: '50px', marginBottom: '3px', padding: '5px 15px', background: '#3a3a77' },
    accordionMenuItemsText: { padding: '5px 15px', fontWeight: 600, color: '#fff' },
    icon: { mr: 2, color: '#fff' }
}

const Sidebar = (props) => {
    const { appDatas } = useContext(ContxAppData);
    const [items, setItems] = useState([]);
    const [doneParent, setDoneParent] = useState(null);
    const location = useLocation(); // Mevcut sayfanın konumunu alır
    const handleDrawerToggle = () => {
        props.setMobileOpen(!props.mobileOpen);
    };
    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const findDoneParent = (url, search) => {
        if (appDatas && appDatas.sidebarItems) {
            url = url ?? window.location.pathname;
            /*
              >> url'i sidebarItems'lar icerisinden ara
            */
            /******************************************************/
            let view = appDatas.sidebarItems.find(f => f.url === url || '/' + f.url === url);
            if (view && view.parentRegisterId) {
                let parent = appDatas.sidebarItems.find(f => f.id === view.parentRegisterId);
                if (parent) {
                    let newUrl = (parent.url.startsWith('/') ? parent.url : '/' + parent.url);
                    if (parent.isShowSidebar === false) {
                        newUrl += window.location.search;
                    }
                    setDoneParent(newUrl);
                }
            }
            else {
                setDoneParent(null);
            }

        }
    }
    useEffect(() => {
        getAll();
        findDoneParent();
    }, [location, appDatas])
    const getAll = () => {
        if (appDatas && appDatas.sidebarItems) {

            let aa = appDatas.sidebarItems;
            if (appObjects.values.loginInfo && appObjects.values.loginInfo.portal) {
                aa = aa.filter(f => f.portal === appObjects.values.loginInfo.portal || !f.portal);
            }
            else {
                aa = aa.filter(f => !f.portal);
            }
            if (window.location.pathname !== '/' && window.location.pathname !== '/index') {
                let currentPage = appDatas.sidebarItems.find(f => f.url === window.location.pathname || '/' + f.url === window.location.pathname);
                let childs = appDatas.sidebarItems.filter(f => (currentPage && f.parentRegisterId === currentPage.id) && f.isShowSidebar === true);
                if (currentPage) {
                    if (childs.length > 0) {
                        aa = childs;
                    }
                    else if (currentPage.parentRegisterId) {
                        aa = appDatas.sidebarItems.filter(f => f.parentRegisterId === currentPage.parentRegisterId);
                    }
                    else {
                        aa = aa.filter(f => !f.parentRegisterId);
                    }
                }
                else {
                    aa = aa.filter(f => !f.parentRegisterId);
                }
            }
            else {
                aa = aa.filter(f => !f.parentRegisterId);
            }
            aa = aa.filter(f => f.isShowSidebar === true);
            if (aa && aa.length > 0) {
                aa = aa.map(item => {
                    item['newUrl'] = item.url;
                    if (portalRouteIsCopyParentParams(item.viewIdentity, appObjects.values.loginInfo.portal)) {
                        item['newUrl'] = item.url + window.location.search;
                    }
                    return item;
                })
                setItems(aa);
            }
        }
    }
    const handleButtonClick = (url) => {
        findDoneParent(url);
        appObjects.goTo(url);
    }
    const handleDoneParent = () => {
        if (doneParent) {
            let url = doneParent;
            let isGo = true;
            if (url.includes('/customer?')) {
                if (!url.includes('customerId=') && url.includes('companyId=')) {
                    isGo = false;
                    let tempCompanyId = wgfGetQueryStringValue('companyId');
                    if (tempCompanyId) {
                        lawFetchGet('customerCompany/get-customer-id?companyId=' + tempCompanyId, null, null, null, null, null, false, (resp) => {
                            if (resp && resp.data) {
                                url = url.split('?')[0];
                                if (url) {
                                    url = url + '?customerId=' + resp.data;
                                    findDoneParent(url);
                                    appObjects.goTo(url);
                                }
                            }
                        })
                    }
                }
            }
            if (isGo) {
                findDoneParent(url);
                appObjects.goTo(url);
            }
        }
    }

    const drawer = (
        <Box sx={{ p: 2 }}>
            <BoxCenter>
                <Typography variant="h4" component="div" sx={{ color: '#fff' }}>Logo</Typography>
            </BoxCenter>
            {
                (wgfAdminRoleControl()) && (
                    <ChangePortal />
                )
            }
            <Box mt={2}>
                <MenuList>
                    {
                        (doneParent) ? (
                            <MenuItem onClick={handleDoneParent} style={styles.menuItem} >
                                <ListItemIcon>
                                    <ArrowBack sx={{ color: '#fff' }} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography fontWeight={600}>Done</Typography>
                                </ListItemText>
                            </MenuItem>
                        ) : (
                            <MenuItem style={{ ...styles.menuItem, minHeight: '44px' }} >
                            </MenuItem>
                        )
                    }
                    {
                        (appObjects.values.loginInfo) && (
                            items.map((item, index) => {
                                return (
                                    (item.isAccordion) ? (
                                        <Accordion
                                            key={index}
                                            expanded={expanded === ('panel-' + index)} onChange={handleChange(('panel-' + index))}
                                            sx={{
                                                boxShadow: 'none',
                                                '&.Mui-expanded': { margin: 0 },
                                                '&::before': { display: 'none' },
                                                background: 'transparent'
                                            }}>
                                            <AccordionSummary
                                                expandIcon={<KeyboardArrowDown sx={{ color: '#fff' }} />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                {/*item.icon */}
                                                <Typography fontWeight={600} color='#fff'>{item.text}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ pr: 0, pt: 0, }}>
                                                <List sx={{ pl: 3 }}>
                                                    {
                                                        items.filter(f => f.parentRegisterId === item.id).map((itemp, indexp) => {
                                                            return (
                                                                <ListItem key={('c' - indexp)}
                                                                    disablePadding
                                                                    onClick={() => (handleButtonClick(item.newUrl))}
                                                                >
                                                                    <ListItemButton style={((window.location.pathname === itemp.url || window.location.pathname === '/' + itemp.url) ? styles.accordionMenuItemsActive : styles.accordionMenuItems)}>
                                                                        <Typography style={styles.accordionMenuItemsText}>{itemp.text}</Typography>
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                    ) : ((item.isDivider === true ? (
                                        <Divider key={index} sx={{ mb: 2, mt: 3, color: '#fff' }} />
                                    ) : (
                                        <MenuItem key={index}
                                            onClick={() => (handleButtonClick(item.newUrl))}
                                            style={((window.location.pathname === item.url || window.location.pathname === '/' + item.url) ? styles.menuItemActive : styles.menuItem)} >
                                            <ListItemIcon>
                                                {/*item.icon */}
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography fontWeight={600}>{item.text}</Typography>
                                            </ListItemText>
                                        </MenuItem>
                                    ))
                                    )
                                )
                            })
                        )
                    }
                </MenuList>
            </Box>
        </Box >
    );
    return (
        <Box
            component="nav"
            sx={{ width: { lg: props.drawerWidth }, flexShrink: { lg: 0 }, boxShadow: 'none', border: 'none' }}
            aria-label="sidebar folders"
        >
            {/* Mobil Sidebar için Drawer (#0b0b55) */}
            <Drawer
                variant="temporary"
                open={props.mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Mobil performans için
                }}
                sx={{
                    display: { xs: 'block', lg: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth, border: 'none', boxShadow: 'none', background: 'rgba(0,0,0,0.7)' },
                }}
            >
                {drawer}
            </Drawer>

            {/* Desktop Sidebar */}
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', lg: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth, border: 'none', boxShadow: 'none', background: 'transparent' },
                }}
                open={props.mobileOpen}
            >
                {drawer}
            </Drawer>
        </Box>
    )
}
export default Sidebar;
