import { Button } from "@mui/material";
import { useState } from "react";
import LawModal from "./LawModal";

const LawCreateDataModal = ({children,style,createEvent, modalShowEvent}) => {
    const [modalShow, setModalShow] = useState(false);
    const handleModalShow = () => {
        setModalShow(true);
        if(modalShowEvent){
            modalShowEvent();
        }
    }
    const handleCreate = () => {
        if (createEvent) {
            createEvent();
        }
        setModalShow(false);
    }
    return (
        <>
            <Button onClick={handleModalShow}>Nieuw</Button>
            {
                (modalShow) && (
                    <LawModal state={modalShow} style={style} setState={setModalShow}>
                        {children}
                        {
                            (createEvent) && (
                                <div className="d-flex">
                                    <Button variant="contained" onClick={handleCreate}>Opslaan</Button>
                                </div>
                            )
                        }
                    </LawModal>
                )
            }
        </>
    )
}
export default LawCreateDataModal;